import { render, staticRenderFns } from "./Channels.vue?vue&type=template&id=1a987158&scoped=true&lang=pug&"
import script from "./Channels.vue?vue&type=script&lang=js&"
export * from "./Channels.vue?vue&type=script&lang=js&"
import style0 from "./Channels.vue?vue&type=style&index=0&id=1a987158&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a987158",
  null
  
)

export default component.exports