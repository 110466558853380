var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.channel
    ? _c(
        "div",
        {
          staticClass: "main-header-info",
          attrs: { id: "channel-main-header-info" }
        },
        [
          _c("div", { staticClass: "header-text" }, [
            _c(
              "div",
              {
                staticClass: "header-user-block",
                attrs: { title: _vm.getTitle },
                on: {
                  click: _vm.doToggleOpenInfo,
                  mouseover: function($event) {
                    return _vm.headerMouseOver($event)
                  }
                }
              },
              [
                _c("icon-image", {
                  attrs: { src: _vm.photo, text: _vm.channel.name }
                }),
                _c("div", { staticClass: "channel-text-wrapper header-main" }, [
                  _c(
                    "div",
                    {
                      staticClass: "name",
                      style: _vm.channel.verified
                        ? "display: flex; align-items: center;"
                        : ""
                    },
                    [
                      _c("span", { staticClass: "check-trim channel-name" }, [
                        _vm._v(_vm._s(_vm.channel.name))
                      ]),
                      _vm.channel.verified
                        ? _c("div", { staticClass: "official" }, [
                            _c("i", { staticClass: "far fa-check" })
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c("div", { staticClass: "channel-info sub-text" }, [
                    _c("span", { staticClass: "channel-subname" }, [
                      _vm._v(
                        _vm._s(_vm.channel.info || _vm.$t("mainPage.channel"))
                      )
                    ])
                  ])
                ])
              ],
              1
            )
          ]),
          _c("div", { staticClass: "header-btns contact-buttons-block" }, [
            _c(
              "div",
              {
                staticClass: "circle-animation btn-custom",
                attrs: { title: _vm.$t("mainPage.new-post") },
                on: { click: _vm.newPubEditor }
              },
              [_c("i", { staticClass: "fas fa-file-plus" })]
            ),
            _c("div", {
              staticClass: "circle-animation header-channel-search btn-custom",
              attrs: { title: _vm.$t("search-in-channel") },
              on: { click: _vm.openSearch }
            }),
            _c(
              "div",
              {
                staticClass: "circle-animation btn-custom",
                attrs: { title: _vm.$t("info") },
                on: { click: _vm.doToggleOpenInfo }
              },
              [_c("i", { staticClass: "fal fa-window-maximize fa-rotate-90" })]
            ),
            _c(
              "div",
              {
                staticClass: "circle-animation btn-custom menu",
                attrs: { title: _vm.$t("mainPage.menu") },
                on: {
                  click: function($event) {
                    return _vm.doMenu($event, "bottom-left")
                  }
                }
              },
              [
                _c("svg", { attrs: { width: "10px", height: "24px" } }, [
                  _c("circle", { attrs: { cx: "5", cy: "5", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "12", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "19", r: "2" } })
                ])
              ]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }