import { get, set, update, createStore, keys, del } from "idb-keyval"
const customStore = createStore("roschat", "roschat-store")
import moment from 'moment'

export const getIDB = async (key) => { 
    let valueObject = await get(key, customStore)
    if (!valueObject) return false
    let { value } = valueObject
    await update(key, () => ({ value, access_date: moment().unix() }), customStore)
    return value
}
export const setIDB = (key, value) => {
    let valueObject = { value, access_date: moment().unix() }
    set(key, valueObject, customStore)
}
export const keysIDB = () => {
    return keys(customStore)
}
export const delIDB = (key) => {
    return del(key, customStore)
}