//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex"
import { CONTACTS, CHAT } from "../../../store/modulesNames"
import { GET_MERGED_CONTACT_BY_ID, GET_MERGED_CONTACTS_BY_IDS, GET_CONTACT_BY_ID } from "../../../store/gettersTypes"
import { ACT_CHAT_SHOW_REACTION_PANEL, ACT_ADD_CONTACT_CONNECTIONS } from "../../../store/actionsTypes"
import ContactListItem from '../../../components/listItems/ContactsListItem.vue'

export default {
    name: "Reactions",
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
        reactions: {
            type: Object,
        },
        panelStyle: {},
    },
    components: { ContactListItem },
    data() {
        return {
            isMouseOver: false,
            topReactionFilter: null,
            not_found_cids: [],
            _contacts: [],
            contacts: [],
            isAfterTopReactionClick: false
        }
    },
    async mounted() {
        let cids = this.reactions.reactions.map(r => r.cid)
        this.not_found_cids = (this[GET_MERGED_CONTACTS_BY_IDS](cids)).filter(c => c && c.notFound).map(c => c.cid)
        if (this.not_found_cids.length) {
            await this[ACT_ADD_CONTACT_CONNECTIONS](this.not_found_cids)
            this.formContacts()
        } else this.formContacts()
        this.topReactionFilter = ''
    },
    watch: {
        topReactionFilter(val) {
            if (!val) this.contacts = this._contacts
            else this.contacts = this._contacts.filter(c => c.reaction === val)
        },
    },
    computed: {
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID, GET_MERGED_CONTACTS_BY_IDS, GET_CONTACT_BY_ID]),
    },
    methods: {
        formContacts(){
            let cidsReaction = this.reactions.reactions.map(r => { return {cid: r.cid, reaction: r.reaction}})
            const cnts = []
            cidsReaction.forEach(cr => {
                let contact
                let isNotFound = this.not_found_cids.find(c => c === cr.cid) || false
                if (isNotFound) {
                    setTimeout(()=> {
                        contact = this[GET_CONTACT_BY_ID](cr.cid)
                        cnts.push({contact, reaction: cr.reaction})
                    }, 50)
                } else {
                    contact = this[GET_MERGED_CONTACT_BY_ID](cr.cid)
                    cnts.push({contact, reaction: cr.reaction})
                }
            })
            this._contacts = cnts
        },
        mousePanelOver(e) {
            e.stopPropagation()
        },
        mousePanelLeave(e) {
            if (e.toElement.hasOwnProperty('className') && e.toElement.className !== 'reactions-panel-wrapper' && !this.isAfterTopReactionClick) {
                this[ACT_CHAT_SHOW_REACTION_PANEL]({ isShow: false})
            }
        },
        topReactionClick(e, reaction = null) {
            e.preventDefault()
            e.stopPropagation()
            this.isAfterTopReactionClick = true
            reaction ? this.topReactionFilter = reaction : this.topReactionFilter = ''
            setTimeout(() => {
                this.isAfterTopReactionClick = false
            }, 1000)
        },
        ...mapActions(CHAT, [ACT_CHAT_SHOW_REACTION_PANEL]),
        ...mapActions(CONTACTS, [ACT_ADD_CONTACT_CONNECTIONS]),
    },

}
