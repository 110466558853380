//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SelectContactToPrivate from '../modal/SelectContactToPrivate.vue'
import CreateChat from '../modal/CreateChat.vue'
import ChatItem from '../listItems/ChatItem.vue'
import ChannelItem from '../listItems/ChannelItem.vue'
import SideNotificationBar from './SideNotificationBar.vue'

import { mapGetters } from 'vuex'
import { BOTS, CHATS, CHANNELS, CHAT, MODAL, CONTENT_MANAGER, CONTACTS } from '../../store/modulesNames'
import {
    ACT_MODAL_OPEN,
    ACT_WATCH_ALL_MESSAGES,
    ACT_OPEN_CHAT,
    ACT_SET_CHANNEL_MARKED,
    ACT_SET_CHAT_MARKED,
} from '../../store/actionsTypes'
import {
    GET_BOT_BY_ID,
    GET_SIDE_BAR_NOTIFICATION,
    GET_MY_CONTACT,
    GET_CHATS,
    GET_CHANNELS,
    GET_CHAT_ITEM_TEXT,
    IS_CHANNEL_PINNED,
    IS_CHAT_PINNED,
} from '../../store/gettersTypes'

import { i18n } from '../../../ext/i18n'
import {CONTACT_FIELD_TYPES} from '../../constants'

let locale = i18n.messages[i18n.locale]

const DRAG_OVER_TIME = 1000 

export default {
    name: 'chats',
    components: { ChatItem, ChannelItem, SideNotificationBar, },
    data () {
        return {
            openChatTimer: null,
            lastPromise: null,
            lastLeftChatCid: 0,
        }
    },
    computed: {
        _chats () { return this[GET_CHATS] },
        _channels () { return this[GET_CHANNELS] },
        chats () {
            let chats = []
            chats = chats.concat(this._chats, this._channels)
            let res = chats.sort(utils.chatAndChannelSortFn).map((item) => {
                if ('cid' in item) {
                    return getChatItem(item)
                } else if (item.chId) {
                    return getChannelItem(item)
                }
            })
            return res
        },
        side_notification() {
            return this[GET_SIDE_BAR_NOTIFICATION]() || false
        },
        myContactActions() {
            return this[GET_MY_CONTACT].actions || {}
        },
        isGroupChatsAllowed() {
            const actions = this.myContactActions
            const isProperty = actions.hasOwnProperty('create-chats')
            if (isProperty) return actions['create-chats']
            else return true
        },
        ...mapGetters(CHATS, [GET_CHATS]),
        ...mapGetters(CHANNELS, [GET_CHANNELS]),
        ...mapGetters(CONTENT_MANAGER, [GET_SIDE_BAR_NOTIFICATION]),
        ...mapGetters(CONTACTS, [GET_MY_CONTACT]),
    },
    methods: {
        async watchAllChatsAndChannels () {
            const unwatchedChats = this.$store.getters['chats/getTotalUnwatched']()
            const unwatchedChannels = this.$store.getters['channels/getTotalUnwatchedMessages']
            const chat_marked = []
            const chan_marked = []
            for (let i in this.chats) {
                if (this.chats[i] && this.chats[i].cid && this.chats[i].cidType && this.chats[i].marked) {
                    chat_marked.push(this.chats[i])
                }
                if (this.$store.getters['channels/isMarked']({ chId: this.chats[i].chId })) {
                    chan_marked.push(this.chats[i])
                }
            }

            async function watch () {
                for (let i in chat_marked) {
                    await this.$store.dispatch(`${CHATS}/${ACT_SET_CHAT_MARKED}`, {
                        cid: chat_marked[i].cid,
                        cidType: chat_marked[i].cidType,
                        marked: false,
                    })
                }
                for (let i in chan_marked) {
                    await this.$store.dispatch(`${CHANNELS}/${ACT_SET_CHANNEL_MARKED}`, {
                        chId: chan_marked[i].chId,
                        marked: false,
                    })
                }
                this.$store.dispatch(`${CHATS}/${ACT_WATCH_ALL_MESSAGES}`, {})
                for (let i in unwatchedChannels) {
                    await this.$store.dispatch('channels/watchChannelPublications', { chId: unwatchedChannels[i].chId })
                }
            }
            if (!!unwatchedChats.length || !!unwatchedChannels.length || !!chat_marked.length || !!chan_marked.length) {
                this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('mark-as-read-conf'),
                    btnOk: {
                        cb: () => (watch.call(this))
                        }
                    }
                })
            } else {
                this.modalOpen({
                name: 'info',
                props: {
                    text: this.$t('all-been-read'),
                    btnOk: {
                        cb: () => (watch.call(this))
                        }
                    }
                })
            }
        },
        doMenu: function (event, position) {
            let handlers = []
            let open = this.cmOpen
            let mute_act
            if (localStorage.getItem('muted')) mute_act = locale.enable
            else mute_act = locale.disable
            handlers.push({ item_name: this.$t('new-chat'), handler: this.doCreatePrivateChat })
            if (this.isGroupChatsAllowed) handlers.push({ item_name: this.$t('new-group'), handler: this.doCreateChat })
            handlers.push({ item_name: this.$t('modal.new-channel'), handler: this.doCreateChannel })
            handlers.push({ item_name: '' })
            handlers.push({ item_name: this.$t('mark-all-as-read'), handler: this.watchAllChatsAndChannels })

            open(event, handlers, position)
        },
        doCreateChannel () {
            this.$store.dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                name: 'channel-edit',
            })
        },
        doCreatePrivateChat() {
            this.modalOpen({ component: SelectContactToPrivate })
        },
        doCreateChat: function () {
            this.modalOpen({ component: CreateChat })
        },
        dragToChat(chat) {
            let { cid, cidType } = chat
            const promise = this.chatPromise(cid)
            this.lastPromise = promise
            return new Promise((res, rej) => {
                promise.then(() => {
                if (promise == this.lastPromise ) {
                    this.lastPromise = null
                    if (cid && cid !== this.lastLeftChatCid) {
                        this.$store.dispatch(`${CHAT}/${ACT_OPEN_CHAT}`, {cid, cidType})
                    }
                }
                })
            })
        },
        chatPromise(cid) {
            return new Promise((resolve, reject) => { setTimeout(() => {
                let res = 'cid: ' + cid
                    resolve(res)
                }, DRAG_OVER_TIME)
            })
        },
        dragLeaveChat(e) {
            let cid = (e && e.cid) ? e.cid : 0
            this.lastLeftChatCid = cid
        }
    },
}

function getChatItem (chat) {
    let item = {
        photo: getChatPhoto(chat),
        cid: chat.cid,
        cidType: chat.cidType || declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER,
        lastMessageId: chat.lastMessage && chat.lastMessage.id || 0,
        time: date_helper.unixTimeToTimeOrDateFormat(chat.lastMessage.unixTime),
        text: app.store.getters[`${CHAT}/${GET_CHAT_ITEM_TEXT}`]({message: chat.lastMessage, list: true}),
        unwatched: app.store.getters['chats/getChatUnwatchedMessages']({ cid: chat.cid, cidType: chat.cidType }),
        muted: app.store.getters['chats/isMuted']({ cid: chat.cid, cidType: chat.cidType }),
        marked: app.store.getters['chats/isMarked']({ cid: chat.cid, cidType: chat.cidType }),
        senderId: chat.lastMessage.senderId,
        pinned: app.store.getters[`${CHATS}/${IS_CHAT_PINNED}`]({ cid: chat.cid, cidType: chat.cidType }),
    }

    if (chat.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) {
        item['name'] = chat.name || locale['search-comp']['group-chat'] // @todo в константы
        item.unwatched = checkUnwatched(chat)
        return item
    }
    let contact = app.getContact(chat.cid)
    // item['name'] = contact && contact.fio || "Безымянный контакт"; //@todo в константы
    item['name'] = (app.store.getters['userdata/getUid'] === chat.cid) ? locale['notes-chat-name'] : contact.fio
    item.unwatched = checkUnwatched(chat)

    let bot = app.store.getters[`${BOTS}/${GET_BOT_BY_ID}`](chat.cid)
    if (bot) {
        item.isBot = true
        item.name = bot[CONTACT_FIELD_TYPES.BOTTITLE]
    }
    if ('official' in chat) item.official = chat.official

    if (chat.cid === 0) {
        item.name = chat.name
        item.photo = chat.photo
    }
    return item
}

function checkUnwatched (chat) {
    let senderId = chat.lastMessage.senderId
    let uid = app.store.getters['userdata/getUid']
    if (chat.cidType === 'user' && senderId === uid) return 0
    else return app.store.getters['chats/getChatUnwatchedMessages']({ cid: chat.cid, cidType: chat.cidType })
}

function getChannelItem (channel) {
    let obj = {
        photo: channel.icon && app.getChannelPhotoUrl(channel.icon) || '',
        chId: channel.chId,
        name: channel.name,
        privilege: channel.privilege,
        chType: channel.chType || declarations.channel_types.PUBLIC,
        time: date_helper.unixTimeToTimeOrDateFormat(channel.unixLastTime || channel.unixCreateTime),
        text: channel.lastTitle || channel.info,
        unwatched: app.store.getters['channels/getChannelUnwatched']({ chId: channel.chId }),
        muted: app.store.getters['channels/isMuted']({ chId: channel.chId }),
        marked: app.store.getters['channels/isMarked']({ chId: channel.chId }),
        pinned: app.store.getters[`${CHANNELS}/${IS_CHANNEL_PINNED}`]({ chId: channel.chId }),
    }
    return obj
}

function getChatPhoto (chat) {
    var type = chat.cidType
    var cid = chat.cid
    if (!type || type === declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER) {
        if (app.store.getters['userdata/getUid'] === cid) return 'img/note.png'
        else return app.store.getters['contacts/getMergedContactById'](cid)
    } else if (type === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) {
        return app.getGroupPhotoUrl(chat.cid)
    }
    return ''
}

