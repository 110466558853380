var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "audio-message", class: _vm.classes }, [
    _vm.recordingAudioMessage || _vm.sendingProgress
      ? _c("div", { staticClass: "audio-message-info" }, [
          _c("span", { staticClass: "voice-message" }, [
            _vm._v(_vm._s(_vm.$t("voice-message")))
          ]),
          _vm.sendingProgress
            ? _c("div", { staticClass: "sending-progress" }, [
                _c(
                  "div",
                  {
                    staticClass: "sending-progress-bar",
                    style: _vm.progressBarStyle
                  },
                  [
                    _c("div", { staticClass: "progress-label" }, [
                      _vm._v(_vm._s(_vm.$t("sending")) + "...")
                    ])
                  ]
                )
              ])
            : _c("canvas", { attrs: { id: "audio-spectrum" } }),
          _c("div", { staticClass: "actions" }, [
            _c(
              "div",
              {
                staticClass: "recording-btns buttons circle-animation",
                on: { click: _vm.cancelVoiceMessage }
              },
              [
                _c("i", {
                  staticClass: "fal fa-times-circle",
                  attrs: { size: "5x" }
                })
              ]
            ),
            _c("div", { staticClass: "recording-time" }, [
              _c("i", {
                staticClass: "fas fa-circle recording-circle",
                attrs: { size: "2x" }
              }),
              _c("span", [_vm._v(_vm._s(_vm.recordTimeText))])
            ]),
            _c(
              "div",
              {
                staticClass: "recording-btns buttons circle-animation",
                on: { click: _vm.sendVoiceMessage }
              },
              [_c("i", { staticClass: "fal fa-check-circle" })]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }