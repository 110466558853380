export default {
    emitters: {
        getPublicKey() {
            return new Promise(async (resolve, reject) => {
                this.log('getPublicKey: >')
                let data
                try {
                    data = await this._emitWithTimeOut('get-public-key', null)
                    if (data.error) {
                        this.log('getPublicKey: < error: ' + data.error)
                        reject(data.error)
                    } else {
                        this.log(`getPublicKey: < length ${data.publicKey && data.publicKey.length || 0}`)
                        resolve(data.publicKey)
                    }
                } catch (e) {
                    this.log('getPublicKey: < error ' + e.message)
                    reject(e)
                }
            })
        },
        setSessionKey(encSessionKey) {
            return new Promise(async (resolve, reject) => {
                this.log(`setSessionKey: > ${encSessionKey}`)
                let data
                try {
                    data = await this._emitWithTimeOut('set-session-key', {encSessionKey})
                } catch (e) {
                    this.log('setSessionKey: < error: ' + e.message)
                    reject(e)
                }
                if (data && data.error) {
                    this.log('setSessionKey: < error: ' + data.error)
                    reject(data.error)
                } else {
                    this.log('setSessionKey: < ok')
                    resolve()
                }
            })
        },
    },
    listeners: {},
}