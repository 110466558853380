//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomInputText from '../custom/CustomInputText.vue'
import DateRangePicker from '../settings/DateRangePicker.vue'
import CustomRadio from '../custom/CustomRadio.vue'
import CustomSelect from '../custom/CustomSelect.vue'
import CustomCheckbox from '../custom/CustomCheckboxRound.vue'
import ListItem from '../custom/ListItem.vue'
import ModalBtn from '../modal/ModalBtn.vue'
import VideoMostConfMenu from './mainContentVideoConfs.vue'
import {CONF_TYPES} from '../../constants'
import {
    GENERATE_VC_CONF_PROPS,
} from '../../store/gettersTypes'
import {mapActions, mapGetters} from 'vuex'
import {VIDEO_CONF_NEW} from '../../store/modulesNames'
import {ACT_VC_CONF_ADD} from '../../store/actionsTypes'
import confManagementMixin from './confManagementMixin'
import {CONF_SCHEDULE_PROPS} from '../../store/modules/video-conf-abstract'

export default {
    name: 'enterConferenceBody',
    components: {
        CustomInputText,
        DateRangePicker,
        CustomRadio,
        CustomSelect,
        CustomCheckbox,
        ListItem,
        ModalBtn,
        VideoMostConfMenu,
    },
    mixins: [confManagementMixin],
    props: {
        type: {
            type: String,
            default: CONF_TYPES.VM,
            required: false,
        },
        vcLink: {
            type: String,
            default: '',
            required: false,
        },
        fio: {
            type: String,
            default: '',
            required: false,
        },
        integrationServer: {
            type: String,
            default: '',
            required: false,
        },
        showMediaToggleBtns: {
            type: Boolean,
            default: true,
            required: false,
        }
    },
    created () {
        this.confTypes = this.getTypeOptions()
        let selectedType
        if (this.vcLink && this.vcLink.length) {
            selectedType = this.getTypeByLink(this.vcLink)
            const linkData = (this[GENERATE_VC_CONF_PROPS](selectedType, {link: this.vcLink}) || {})

            if (linkData.link === linkData.generatedLink) {
                this.confID = linkData.confId
                this.confPassword = linkData.pass
            } else {
                this.chosenEnterWay = 1
            }
        } else {
            selectedType = this.confTypes[0].value
        }
        this.setConfTypeValue(selectedType)
    },
    data () {
        return {
            enterWays: [
                {
                    name: this.$t('videomost.conf-enterway-id'),
                },
                {
                    name: this.$t('videomost.conf-enterway-link'),
                },
            ],
            confMode: {},
            confID: '',
            confPassword: '',
            confLink: '',
            placeholder: this.$t('videomost.conf-enterway'),
            chosenEnterWay: 0,
            participantName: '',
            defTextID: '',
            defTextPassword: '',
            defTextLink: this.vcLink ? this.vcLink : '',
            defText: '',
            isNoCamera: false,
            isNoMicrophone: false,
        }
    },
    computed: {
        showType() {
            return this.confTypes.length > 1
        },
        getType() {
            return (this.confTypes.find((item) => item.selected) || {value: CONF_TYPES.VM}).value
        },
        ...mapGetters(VIDEO_CONF_NEW, [GENERATE_VC_CONF_PROPS]),
    },
    methods: {
        chosenWayOptionClick (way) {
            this.chosenEnterWay = way
        },
        setConfTypeValue(val) {
            this.confTypes.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
            this.confProps[CONF_SCHEDULE_PROPS.TYPE] = val
        },
        getTypeByLink (link) {
            let type
            const RE_VM_CONF_LINK = /\S*?\/service\/join\/\?confid=\S*/g
            const RE_ROSCHAT_CONF_LINK = /\S*?\/join\/\?meetingid=\S*/g
            const checkVmConfLink = link.match(RE_VM_CONF_LINK)
            const checkRoschatConfLink = link.match(RE_ROSCHAT_CONF_LINK)
            if (checkVmConfLink) {
                type = CONF_TYPES.VM
            } else if (checkRoschatConfLink) {
                type = CONF_TYPES.ROSCHAT
            }
            return type
        },
        enterConference () {
            let type
            let props = {
                noCamera: this.isNoCamera,
                noMicrophone: this.isNoMicrophone,
            }
            let userName = this.participantName || this.fio
            if (userName) props.userName = userName
            const confProps = {}
            if (this.chosenEnterWay) {
                type = this.getTypeByLink(this.confLink)
                confProps.link = this.confLink
            } else {
                type = this.getType
                let payload = {confId: this.confID, pass: this.confPassword}
                if (type === CONF_TYPES.VM) payload.server = this.integrationServer
                Object.assign(confProps, payload)
            }
            Object.assign(props, this[GENERATE_VC_CONF_PROPS](type, confProps))
            //this.modalClose()
            this[ACT_VC_CONF_ADD]({type, data: props})
        },
        ...mapActions(VIDEO_CONF_NEW, [ACT_VC_CONF_ADD]),
    },
    watch: {
        participantName(val) {
            this.$emit('participantName', val)
        },
        confID(val) {
            this.$emit('confID', val)
        },
        confPassword(val) {
            this.$emit('confPassword', val)
        },
        defTextLink(val) {
            this.$emit('defTextLink', val)
        },
    }
}
