import ipc from "../../../electron/ipc"
import { getIDB, setIDB } from "../../../ext/indexedDB"

import { mapGetters, mapActions } from 'vuex'
import { AJAX, BLOB, DLP, CHAT, CHATS, CONTACTS, USERDATA } from '../../store/modulesNames'
import {
    ACT_ABORT_AJAX_CALL,
    ACT_AJAX_GET_FILE,
    ACT_SEND_FILE_SAVE_EVENT,
} from '../../store/actionsTypes'
import { DLP_SOURCE_TYPES } from '../../store/modules/dlp'
import {
    GET_AJAXX_CALL_IS_EXIST_BY_FILE,
    GET_AJAXX_CALL_PROGRESS_BY_FILE,
    GET_FILE_BLOB,
    GET_SELECTED_CHAT,
    GET_MY_CONTACT,
    GET_MERGED_CONTACT_BY_ID,
    GET_IS_ROLES_SUPPORTED,
    GET_CHAT_ITEM_TEXT,
    GET_ROSCHAT_REDIRECTED_SERVER,
} from '../../store/gettersTypes'

import moment from 'moment'

import Logger from '../../common/Logger'
const logger = new Logger('chat-message-mixin')

const urlPreviews = new Map(), MAX_UPDATED_TIME = 60 * 60 * 24

const mixin = {
    computed: {
        xhrFile() { return this.message && this.message.msg && this.message.msg.file },
        xhrId() { return this.xhrFile && this[GET_AJAXX_CALL_IS_EXIST_BY_FILE](this.xhrFile) },
        isPending() { return this[GET_AJAXX_CALL_IS_EXIST_BY_FILE](this.xhrFile) },
        loadProgress() { return this[GET_AJAXX_CALL_PROGRESS_BY_FILE](this.xhrFile) },
        link() { return this[GET_FILE_BLOB](this.xhrFile) },
        isRolesModelSupported() {
            return this[GET_IS_ROLES_SUPPORTED]
        },
        myContactActions() {
            return this[GET_MY_CONTACT].actions || []
        },
        isWithPreview() {
            return this.message && this.message.msg && this.message.msg.previewFile
        },
        getChatContact() {
            const cid = this[GET_SELECTED_CHAT] && this[GET_SELECTED_CHAT].cid
            if (!cid) return {}
            return this[GET_MERGED_CONTACT_BY_ID](cid)
        },
        getAllowedActions() {
            return this.getChatContact && this.getChatContact.actions || {}
        },
        isSendMediaMessage() {
            if (!this.isRolesModelSupported) return true
            let isAllowed = true
            if (this.myContactActions.hasOwnProperty('send-media-message')) isAllowed = this.myContactActions['send-media-message']
            if (this.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) return isAllowed
            if (this.getAllowedActions.hasOwnProperty('send-media-message')) isAllowed = isAllowed && this.getAllowedActions['send-media-message']
            return isAllowed
        },
        ...mapGetters(AJAX, [GET_AJAXX_CALL_IS_EXIST_BY_FILE, GET_AJAXX_CALL_PROGRESS_BY_FILE]),
        ...mapGetters(BLOB, [GET_FILE_BLOB]),
        ...mapGetters(CHATS, [GET_SELECTED_CHAT]),
        ...mapGetters(CONTACTS, [GET_MY_CONTACT, GET_MERGED_CONTACT_BY_ID, GET_IS_ROLES_SUPPORTED]),
        ...mapGetters(USERDATA, [GET_ROSCHAT_REDIRECTED_SERVER]),
    },
    methods: {
        abortAjaxCall() {
            if (this.xhrId) this[ACT_ABORT_AJAX_CALL](this.xhrId)
        },
        withPreview(type) {
            return ['video', 'image'].includes(type)
        },
        async getPreview(msg, remoteHost = '') {
            let preview
            if (remoteHost) {
                preview = `https://${remoteHost}` + '/' + declarations.http_post_dirs.CHAT_DATA_DIR + previewFile
                return preview
            }
            let storedPreview = false
            let previewFile = msg.previewFile
            try {
                storedPreview = await this.getDB_Preview(previewFile)
            } catch (error) {}
            if (storedPreview) return this.getObjectURL(storedPreview)
            if (previewFile) {
                preview = this.$store.getters['userdata/getHost'] + '/' + declarations.http_post_dirs.CHAT_DATA_DIR + previewFile
                if (preview) {
                    try {
                        this.setDB_Preview(msg.previewFile, preview)
                    } catch (e) {
                        console.log("🚀 ~ file: chat-message-mixin.js:94 ~ getPreview ~ e:", e)
                    }
                }
                return preview
            }
            else if (msg.preview) return 'data:image/png;base64,' + msg.preview
        },
        async getDB_Preview(previewFile) {
            return await getIDB(previewFile)
        },
        async setDB_Preview(fileName, preview) {
            const blobFile = await this.getBlobFromURL(preview)
            setIDB(fileName, blobFile)
        },
        getObjectURL(storedPreview) {
            window.URL.revokeObjectURL(storedPreview)
            let objectURL = window.URL.createObjectURL(storedPreview)
            return objectURL
        },
        async getGifPreview(msg, remoteHost = '') {
            const storedPreview = await getIDB(msg.file)
            if (storedPreview) {
                window.URL.revokeObjectURL(storedPreview)
                return window.URL.createObjectURL(storedPreview)
            }
            if (msg.previewFile) {
                let preview
                if (remoteHost) {
                    preview = `https://${remoteHost}` + '/' + declarations.http_post_dirs.CHAT_DATA_DIR + msg.previewFile
                }
                else preview = this.$store.getters['userdata/getHost'] + '/' + msg.file
                const blobFile = await this.getBlobFromURL(preview)
                setIDB(msg.file, blobFile)
                return preview
            }
        },
        getDownloadFileName(type, name, extension) {
            let filename = name + (extension ? '.' + extension : '')
            let date = moment().format('YYYY-MM-DD_HH-MM-SS')
            if (!name || !filename) filename = (type + '_' + date) + (extension ? '.' + extension : '')
            filename = filename && filename.replace(/:/g, '_') // fix file name for Windows
            return filename
        },
        async downloadFile(message, isSaveFile = false) {
            let link = this.$store.getters['blob/getFileBlob'](message.msg.file)
            let fileName = this.getDownloadFileName(message.sub_type, message.msg.name, message.msg.extension)
            // #if process.env.WEBPACK_BUILD_TARGET === 'web'
            let a = document.createElement('a')
            a.setAttribute('href', link)
            a.setAttribute('download', fileName)
            let evt = document.createEvent('MouseEvents')
            evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
            a.dispatchEvent(evt)
            // #endif

            // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//             let params = {}
//             const uid = message.id + '-' + message.msg.file.match(/(?<=\/data\/).+(?=\.)/)[0] // link && link.replace('blob:file:///', '')
//             let fileKey
//             if (uid) fileKey = 'FilePath:' + uid + fileName
//             else fileKey = 'FilePath:' + fileName
// 
// 
//             let storedPath
//             try {
//                 storedPath = await getIDB(fileKey)
//             } catch { }
//             if (storedPath && !isSaveFile) {
//                 params.fpath = storedPath
//                 try {
//                     let openedFilePath = await ipc.sendAsync('open-file', params)
//                     setIDB(fileKey, openedFilePath)
//                     return true
//                 } catch (e) {
//                     logger.error(e, 'open-file-path error')
//                 }
//             }
//             let fileBlob = await this.getBlobFromURL(link)
//             const reader = new FileReader()
//             reader.onload = async () => {
//                 if (reader.readyState == 2) {
//                     let buffer = Buffer.from(reader.result)
//                     params = { fileName, buffer }
//                     try {
//                         const savedFilePath = await ipc.sendAsync('download-file', params)
//                         if (savedFilePath) {
//                             setIDB(fileKey, savedFilePath)
//                         }
//                     } catch (e) {
//                         logger.error(e, 'download-file error')
//                     }
//                 }
//             }
//             try {
//                 reader.readAsArrayBuffer(fileBlob)
//             } catch (e) {
//                 logger.error(e, 'File read error')
//             }
            // #endif
            this.$store.dispatch(`${DLP}/${ACT_SEND_FILE_SAVE_EVENT}`, {
                fileName: message.msg.file,
                sourceType: DLP_SOURCE_TYPES.CHAT_MESSAGE,
                sourceData: {
                    id: message.id,
                    cid: message.cid,
                    cidType: message.cidType,
                }
            })
        },
        async checkSavedFileExists(message) {
            let result = { storedPath: '', checkResult: false }
            // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//             let fileName = this.getDownloadFileName(message.sub_type, message.msg.name, message.msg.extension)
//             const uid = message.id + '-' + message.msg.file.match(/(?<=\/data\/).+(?=\.)/)[0] // link && link.replace('blob:file:///', '')
//             let fileKey
//             if (uid) fileKey = 'FilePath:' + uid + fileName
//             else fileKey = 'FilePath:' + fileName
//             try {
//                 result.storedPath = await getIDB(fileKey)
//                 result.checkResult = await ipc.sendAsync('check-file-exists', result.storedPath)
//             } catch { }
            // #endif
            return result
        },
        showFileInFolder(fpath) {
            // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//             ipc.sendAsync('show-file-in-folder', fpath)
            // #endif
        },
        getSlicedText(dom) {
            let path = [dom.body.childNodes[0]]
            let maxChars = this.maxCharsWithShortMessage
            let chars = 0
            let pop = false
            let full = false
            while (path.length) {
                let node = path[path.length - 1]
                if (!pop && !full) {
                    if (node.nodeType === Node.TEXT_NODE) {
                        let len = node.textContent.length
                        if (chars + len >= maxChars) {
                            if (chars + len !== maxChars) {
                                let sliceVal = node.textContent.slice(0, maxChars - chars)
                                node.textContent = sliceVal
                            }
                            full = true
                        }
                        chars += node.textContent.length
                    } else if (node.classList[0] === 'emoji') {
                        chars += 1
                        if (chars >= maxChars) full = true
                    } else if (node.nodeType === 1) {
                        let childs = node.childNodes
                        let textChildNode = childs[0] && childs[0].nodeType === Node.TEXT_NODE ? childs[0] : null
                        if (textChildNode) {
                            let len = textChildNode.textContent.length
                            if (chars + len >= maxChars) {
                                if (chars + len !== maxChars) {
                                    let sliceVal = textChildNode.textContent.slice(0, maxChars - chars)
                                    textChildNode.textContent = sliceVal
                                }
                                chars += len
                                full = true
                            }
                        }
                        if (chars >= maxChars) full = true
                    }
                }
                if (!pop && node.childNodes.length) {
                    let child = node.childNodes[0]
                    path.push(child)
                } else if (node.nextSibling) {
                    let child = node.nextSibling
                    if (full) {
                        child.remove()
                    } else {
                        path.splice(path.length - 1, 1, child)
                    }
                } else {
                    path.pop()
                    pop = true
                    continue
                }
                pop = false
            }
            return dom.body.innerHTML
        },
        async loadMessageFile(params, cb) {
            let blob, blobFile = this.$store.getters['blob/getFileBlob'](params.file)
            if (blobFile) return cb && cb()
            try {
                blob = await getIDB(params.file)
            } catch { }
            if (blob) {
                window.URL.revokeObjectURL(blob)
                const blob_url = window.URL.createObjectURL(blob)
                this.$store.commit('blob/updateFileBlob', {
                    file_name: params.file,
                    link: blob_url
                })
            } else {
                try {
                    blob = await this[ACT_AJAX_GET_FILE]({ url: params.file, remoteHost: params.remoteHost, fileSize: params.size, encKey: params.key, link: true })
                    setIDB(params.file, blob)
                } catch (e) {
                    logger.info('get file error')
                }
            }
            cb && cb()
        },
        async getBlobFromURL(url) {
            let blob
            await fetch(url).then(res => blob = res.blob())
            return blob
        },
        setWindowProtection(isProtected) {
            // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//             ipc.send('protect-window', { window: 'mainWindow', isProtected })
            // #endif
        },
        _getFio(cid) {
            return (this.$store.getters["contacts/getMergedContactById"](cid).fio || "")
        },
        _getItemText(message) {
            let text = ''
            switch (message.dataType) {
                case declarations.msgDataTypes.MSG_DATA_TYPE_TEXT:
                    text = message.data
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_DATA:
                    text = this._getDataText(message.data)
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_UNSTORED:
                    text = message.data
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_SYSTEM:
                    text = ""
                    break
                default:
                    text = ""
            }
            return text
        },
        _getDateTime(details) {
            return (details.sentDate + ' ' + details.sentTime) || ''
        },
        _getDataTypeName(type) {
            switch (type) {
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
                    return this.$t('image')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
                    return this.$t('video')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
                    return this.$t('file')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
                    return this.$t('audio')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
                    return this.$t('contact')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
                    return this.$t('mainPage.location')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
                    return this.$t('channel')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
                    return this.$t('publication')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
                    return this.$t('poll')
                default:
                    return false
            }
        },
        _getDataText(data) {
            let text = ''
            switch (data.type) {
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
                    const fields = Array.isArray(data.fields) && data.fields
                    const phones = fields && fields.filter(f => f.type === "phone" || f.type === "workphone")
                    const phone = phones && phones.length && phones[0].value
                    const mails = fields && fields.filter(f => f.type === "mail")
                    const mail = mails && mails.length && mails[0].value
                    const fio = (fields && fields.fio) || phone || mail
                    text = fio
                    break
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
                    text = data.text
                    break
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
                    return ''
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
                    text = data.longitude + " " + data.latitude
                    break
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
                    text = data && data.hasOwnProperty("text") ? data.text : '' || ''
                    break
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
                    text = data.topic
                    break
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
                    text = data.text
                    break
                default:
                    text = ""
            }
            return text
        },
        async getUrlPreviewBlock(url) {
            if (urlPreviews.has(url)) {
                const currentTimeSec = +new Date() / 1000
                const savedObj = urlPreviews.get(url)
                const { updated } = savedObj
                if ((currentTimeSec - updated) > MAX_UPDATED_TIME) urlPreviews.delete(url)
                else return savedObj
            }
            const host = this[GET_ROSCHAT_REDIRECTED_SERVER]
            let proxyUrl = `${host}/proxy?url=${url}`
            try {
                const response = await fetch(proxyUrl)
                let data = await response.text()
                const parser = new DOMParser()
                const doc = parser.parseFromString(data, 'text/html')
                const titleTextContent = doc.querySelector('title')?.textContent || ''
                const title = titleTextContent.length > 100 ? titleTextContent.slice(0, 200) + ' ...' : titleTextContent
                const descriptionContent = doc.querySelector('meta[name="description"]')?.getAttribute('content') || ''
                let description = descriptionContent.length > 100 ? descriptionContent.slice(0, 100) + ' ...' : descriptionContent
                if (description === title) description = ''
                const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || ''
                const previewObj = { url, title, description, image, updated: +new Date() / 1000 }
                urlPreviews.set(url, previewObj)
                return previewObj
            } catch (e) {
                return { url: '', title: '', description: '', image: '' }
            }
        },
        getDateItem(prev, cur) {
            let prev_date = date_helper.secondsLeftToDateFormat(prev && prev.time)
            let cur_date = date_helper.secondsLeftToDateFormat(cur.time)
            if (prev_date !== cur_date || !prev) {
                return {
                    senderId: 0,
                    type: 'system',
                    sub_type: 'time',
                    msg: cur_date,
                    side: 'center',
                    classes: ['system-message', 'side-center', 'time'],
                }
            }
        },
        getMessageItem(message) {
            const cid = message.cid
            const isGroupChat = message.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP
            const contact = app.store.getters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](cid)
            let remoteServerId = contact && contact.objectId
            if (isGroupChat) {
                const externalContact = app.store.getters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](message.senderId)
                remoteServerId = externalContact && externalContact.objectId
            }
            let out = message.type === 'out' || app.getUid() === message.senderId
            let time = date_helper.secondsLeftToTimeFormat(message.time)
            const isPollId = message.data && message.data.hasOwnProperty('pollId') || false
            if (isPollId) message.data.type = 'poll'
            const returnObj = {
                id: message.id,
                cid,
                cidType: message.cidType,
                senderId: message.senderId,
                author: message.author,
                isNote: cid === message.senderId,
                type: message.dataType,
                sub_type: message.data && message.data.type,
                time: time,
                dateTime: message.timeDetails && message.timeDetails.fullTime,
                msg: app.store.getters[`${CHAT}/${GET_CHAT_ITEM_TEXT}`]({ message }),
                fields: message.data && message.data.fields,
                entities: message.data && message.data.entities,
                ownMsg: out,
                status: out ? this.getMessageStatus(message) : '',
                sentTime: message.timeDetails && message.timeDetails.sentTime,
                keyboard: message.data && message.data.keyboard,
                replyId: message.replyId,
                edited: 'editedTime' in message,
                previewSize: message.data && message.data.previewSize,
                remoteServerHost: remoteServerId && message.type !== 'out' ? app.store.getters[`${USERDATA}/${GET_SERVER_REMOTE_HOST}`](remoteServerId) : false,
                reactions: message.reactions || [],
                comments: message.comments || 0,
                commentsSubscribed: message.commentsSubscribed || false,
            }
            if (message.data && message.data.fileGroupId) returnObj.fileGroupId = message.data.fileGroupId
            return returnObj
        },
        getMessageStatus(message) {
            if (message.watchedTime !== undefined) return 'watched'
            if (message.receivedTime !== undefined) return 'received'
            return ''
        },
        getMsgNameByType(message) {
            let msg
            switch (message.sub_type) {
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
                    msg = this.$t('image')
                    if (message.msg.text) msg = msg + ': ' + message.msg.text
                    return msg
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
                    msg = this.$t('video')
                    if (message.msg.text) msg = msg + ': ' + message.msg.text
                    return msg
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
                    msg = this.$t('file')
                    let _fileName = message.msg.name 
                    let _extension = message.msg.extension ? '.'+message.msg.extension : ''
                    let fileName =  _fileName + _extension 
                    let text = message.msg.text || ''
                    msg = msg + ': ' + fileName + ' ' + text 
                    return msg
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
                    msg = this.$t('audio')
                    if (message.msg.text) msg = msg + ': ' + message.msg.text
                    return msg
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
                    return this.$t('contact')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
                    return this.$t('mainPage.location')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
                    return this.$t('channel')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
                    return this.$t('publication')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
                    return this.$t('poll')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
                    return this.$t('chat.abonentIsAvailable')
                case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
                    return this.getHtml(message.msg)
            }
        },
        getHtml(text) {
            let sanitized_text = text.replace(/</g, '&lt;').replace(/>/g, '&gt;')
            return this.wrapEmoji(this.lookForRefs(sanitized_text))
        },
        ...mapActions(AJAX, [
            ACT_AJAX_GET_FILE,
            ACT_ABORT_AJAX_CALL,
        ]),
    },
}

export default mixin