//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modal_mixin from "../../components/modal/modal-mixin"
import ModalBtn from "../../components/modal/ModalBtn.vue"
import RecycleContactsList from "../modal/RecycleContactsList.vue"
import CustomSearch from "../custom/CustomSearch.vue"
import SelectContactBody from "../../components/modal/SelectContactBody.vue"
import TetraChat from "./TetraChat.vue"

import { mapGetters, mapActions } from "../../../vuex"
import {
    RADIO,
    TETRA,
    INFO,
    PHONE_CNTL,
} from "../../store/modulesNames"
import {
    GET_CURRENT_RADIO,
    GET_TETRA_SEARCHED_USERS,
    GET_TETRA_ACTIVE_GROUP,
    GET_TETRA_USERS_LIST,
    GET_TETRA_GROUP_USERS,
    GET_TETRA_MESSAGES,
    GET_TETRA_USER_UNWATCHED_MESSAGES_COUNT,
} from "../../store/gettersTypes"
import {
    ACT_TETRA_SEARCH_USERS,
    ACT_TETRA_UPDATE_USERS_LIST,
    ACT_TETRA_GET_GROUP_USERS,
    ACT_INFO_PUSH,
    CALLS_CONTROLLER,
} from '../../store/actionsTypes'
import {PHONE_TYPES} from '../../constants'

const SEARCH_COUNT = 50

export default {
    name: "SelectTetraContacts",
    props: {
        isGroupContacts: {
            type: Boolean,
            required: false,
            default: false
        },
        isSDS: {
            type: Boolean,
            required: false,
            default: false
        },
    },        
    components: { ModalBtn, SelectContactBody, CustomSearch, RecycleContactsList, TetraChat },
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                delay: 350,
                pivotY: 0.1,
                width: 400,
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                styles: "min-width: 300px;"                    
            },
            contactsList: [],
            selected: [],
            search: '',
            fromSsi: 0,
            searchStep: SEARCH_COUNT,
            loading: false,
            isScrolledDown: false,
            isNewSearch: false           
        }
    },
    created() {
        this.getRecentUsersList()
    },
    mounted() {
        if (this.isGroupContacts) this.searchContacts()
        setTimeout(() => {
            let scrollerEl = this.$refs['scroller'].$el.querySelector('.vue-recycle-scroller')
            scrollerEl.addEventListener('scroll', () => {
                const el = scrollerEl
                if (!el) return
                const scrollHeight = Math.round(el.scrollHeight)
                const scrollTop = Math.round(el.scrollTop)
                const clientHeight = Math.round(el.clientHeight)
                if (clientHeight + scrollTop >= scrollHeight - 100) {
                    if (this.search) this.isScrolledDown = true
                }
            })            
        }, 1000)
    },
    beforeDestroy() {
        this.props.cancel && this.props.cancel()
    },
    computed: {
        botsIsVisible() { return false },
        cancelText() { return this.props.cancelText ||  this.$t('cancel') },
        okText() { return this.props.okText || this.$t('loginPage.ok') },
        multiple() { return !!this.props.multiple },
        title() { return this.isSDS ? this.$t('tetra-sds-messages') : this.$t('chat.contact-selection') },
        mySSI(){ return this[GET_CURRENT_RADIO] && this[GET_CURRENT_RADIO].ssi },
        searchedUsers() {
            const isSearchInGroup = this.isGroupContacts
            if (isSearchInGroup) {
                const activeGroup = this[GET_TETRA_ACTIVE_GROUP]
                return this[GET_TETRA_GROUP_USERS](activeGroup.gssi) || []
            } else return this[GET_TETRA_SEARCHED_USERS]
        },
        recentUsersSSI() {
            const listType = this.isSDS ? 'msg' : 'contact'
            let recentlySearchedList = this[GET_TETRA_USERS_LIST](listType)
            if (!recentlySearchedList) return []
            let recentlySearched = recentlySearchedList.sort((a,b) => b.updated - a.updated)
            let recentlySearchedSSI = recentlySearched.map(u => u.ssi.toString())
            return recentlySearchedSSI
        },
        recentUsers() {
            const listType = this.isSDS ? 'msg' : 'contact'
            let _users = this[GET_TETRA_USERS_LIST](listType)
            if (!_users) return []
            const result = []
            _users.forEach(user => {
                let missedMsgsCount = this.getUnwatchedUserMsgsCount(user.ssi)
                let modContact = { cid: user.ssi, ssi:user.ssi, fio: user.name || user.ssi.toString(), note: user.note || '', missedMsgsCount }
                result.push(modContact)
            })
            return result
        },
        ...mapGetters(TETRA, [
            GET_TETRA_SEARCHED_USERS,
            GET_TETRA_GROUP_USERS,
            GET_TETRA_ACTIVE_GROUP,
            GET_TETRA_USERS_LIST,
            GET_TETRA_MESSAGES,
            GET_TETRA_USER_UNWATCHED_MESSAGES_COUNT,
        ]),
        ...mapGetters(RADIO, [GET_CURRENT_RADIO])
    },
    watch: {
        search(newVal, oldVal) {
            this.isScrolledDown = false
            if (this.isGroupContacts) this.searchContacts()
            else {
                if (oldVal && !newVal) {
                    this.contactsList = []
                    this.fromSsi = 0
                } else if (newVal !== oldVal) {
                    this.isNewSearch = true
                    this.searchContacts()
                }
            }
        },
        searchedUsers(val) {
            // console.log("🚀 ~ file: SearchTetraContacts.vue:196 ~ searchedUsers ~ val:", val)
            const preparedContacts = []
            let modContact = {}
            const my_ssi = this.mySSI.toString()
            // console.log("🚀 ~ file: SearchTetraContacts.vue:200 ~ searchedUsers ~ my_ssi:", my_ssi)
            val.forEach(c => {
                const isNotMySSI = my_ssi !== c.ssi.toString().toLowerCase()
                if (isNotMySSI && this.isGroupContacts && this.search) {
                    const _search = this.search.toLowerCase()
                    const _ssi = c.ssi && c.ssi.toString().toLowerCase() || '', 
                        _name = c.name && c.name.toString().toLowerCase() || '',
                        _note = c.note && c.note.toString().toLowerCase() || ''
                    if ( _ssi.indexOf(_search)>-1 || _name.indexOf(_search)>-1 || _note.indexOf(_search)>-1) {
                        modContact = { cid: c.ssi, ssi:c.ssi, fio: c.name || c.ssi.toString(), note: c.note || '' }
                        preparedContacts.push(modContact)
                    }
                } else {
                    if (isNotMySSI) {
                        modContact = { cid: c.ssi, ssi:c.ssi, fio: c.name || c.ssi.toString(), note: c.note || '' }
                        preparedContacts.push(modContact)
                    }
                }
            })
            const lastContact = preparedContacts[preparedContacts.length -1]
            if (this.isGroupContacts) this.contactsList = preparedContacts.length ? preparedContacts : []
            else {
                if (this.isNewSearch) {
                    this.fromSsi = 0
                    this.contactsList = preparedContacts.length ? preparedContacts : []
                } else {
                    this.fromSsi = lastContact.ssi
                    this.contactsList = this.contactsList.concat(preparedContacts.slice(1))
                }
            }
        },
        isScrolledDown(val) {
            if (!val) return
            this.searchContacts()
        }
    },
    methods: {
        async getRecentUsersList() {
            const usersSSI = this.recentUsersSSI
            if (!(usersSSI && usersSSI.length)) return
            let params = {list: usersSSI}
            if (this.isSDS) params.type = 'msg'
            else params.type = 'contact'
            await this[ACT_TETRA_UPDATE_USERS_LIST](params)
        },
        async searchContacts() {
            try {
                this.loading = true
                let parms
                if (this.isGroupContacts) {
                    const activeGroup = this[GET_TETRA_ACTIVE_GROUP]
                    parms = { group: activeGroup.gssi }
                    this[ACT_TETRA_GET_GROUP_USERS](parms)
                } else {
                    parms = {filter: this.search, fromSSI: this.fromSsi, count: this.searchStep}
                    await this[ACT_TETRA_SEARCH_USERS](parms)
                }
            } catch (e) {
                console.log("🚀 ~ file: SearchTetraContacts.vue:233 ~ searchContacts ~ e:", e)
            }
            this.loading = false
        },
        getUnwatchedUserMsgsCount(ssi) {
            return this[GET_TETRA_USER_UNWATCHED_MESSAGES_COUNT](ssi)
        },
        startChatWithContact(contact) {
            this.modalOpen({
                name: 'tetra-chat',
                component: TetraChat,
                props: { contact }
            })
            this.modalClose()
        },
        showContextMenu(event, contact) {
            let handlers = [], open = this.cmOpen
            handlers.push({
                item_name: this.$t('settings.goto-profile'),
                handler: () => {
                    this[ACT_INFO_PUSH]({type: 'tetra-contact-info', params: {ssi: contact.ssi, 
                        name: contact.fio.trim(), note: contact.note, isGroup: false, canBack: false}})
                    this.modalClose()
                }
            })
            handlers.push({
                item_name: this.$t('tetra-duplex-call'),
                handler: () => {
                    this[CALLS_CONTROLLER]({type: PHONE_TYPES.TETRA, data: { ssi: contact.ssi }})
                    this.modalClose()
                }
            })
            // console.log("🚀 ~ file: SearchTetraContacts.vue:272 ~ showContextMenu ~ contact:", contact)
            handlers.push({
                item_name: this.$t('tetra-sds'),
                handler: () => this.startChatWithContact(contact)
            })
            open(event, handlers)
        },
        ...mapActions(TETRA, [
            ACT_TETRA_SEARCH_USERS,
            ACT_TETRA_GET_GROUP_USERS,
            ACT_TETRA_UPDATE_USERS_LIST,
        ]),
        ...mapActions(INFO, [ACT_INFO_PUSH]),
        ...mapActions(PHONE_CNTL, [CALLS_CONTROLLER]),
    }
}

