import { render, staticRenderFns } from "./CustomNotificationWrapper.vue?vue&type=template&id=35c900c6&scoped=true&lang=pug&"
import script from "./CustomNotificationWrapper.vue?vue&type=script&lang=js&"
export * from "./CustomNotificationWrapper.vue?vue&type=script&lang=js&"
import style0 from "./CustomNotificationWrapper.vue?vue&type=style&index=0&id=35c900c6&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c900c6",
  null
  
)

export default component.exports