export default {
    emitters: {
        tetraRadioConnect(params) {
            return new Promise((resolve, reject) => {
                //this.log('tetra-radio-connect: > ' + JSON.stringify(params))
                this.socket.emit('tetra-radio-connect', params, (result) => {
                    if (result.error || !result.status) {
                        this.log(`tetra-radio-connect: < failed ${result.error}`)
                        // return reject(result.error)
                        resolve(result)
                    }
                    resolve(result)
                })
            })
        },
        tetraRadioDisconnect(params = {}) {
            return new Promise((resolve, reject) => {
                //this.log('tetra-radio-disconnect: > ' + JSON.stringify(params))
                if (this.isConnected) {
                    this.socket.emit('tetra-radio-disconnect', params, (result) => {
                        if (result && result.error) {
                            this.log(`tetra-radio-disconnect: < failed ${result.error}`)
                            return reject(result.error)
                        }
                        resolve()
                    })
                } else resolve()
            })
        },
        tetraRadioGetStatus(params) {
            return new Promise((resolve, reject) => {
                //this.log('tetra-radio-get-status: > ' + JSON.stringify(params))
                this.socket.emit('tetra-radio-get-status', params, (result) => {
                    if (!result.status) {
                        this.log(`tetra-radio-get-status: < failed`)
                        return reject()
                    }
                    resolve(result.status)
                })
            })
        },
        tetraRadioSendCmd(params) {
            if (!params || !params.cmd || params.cmd.msgType !== 'speech') this.log('tetra-radio-send-cmd: > ' + JSON.stringify(params))
            //this.log('tetra-radio-send-cmd: > ' + JSON.stringify(params))
            return new Promise((resolve, reject) => {
                this.socket.emit('tetra-radio-send-cmd', params, (result) => {
                    if (result && result.error) {
                        this.log(`tetra-radio-send-cmd: < failed ${result.error}`)
                        resolve(result.error)
                    }
                    resolve(true)
                })
            })
        },
    },
    listeners: {
        'tetra-radio-status-change-event'(data) {
            log('bind: < tetra-radio-status-change-event: ' + JSON.stringify(data))
            this.emit('tetra-radio-status-change-event', data)
        },
        'tetra-radio-message-event'(data) {
            if (!data || data.msgType !== 'speech') log('bind: < tetra-radio-message-event: ' + JSON.stringify(data))
            //log('bind: < tetra-radio-message-event: ' + JSON.stringify(data))
            this.emit('tetra-radio-message-event', data)
        },
    },
}