import { render, staticRenderFns } from "./RadioComponent.vue?vue&type=template&id=630f56c3&scoped=true&lang=pug&"
import script from "./RadioComponent.vue?vue&type=script&lang=js&"
export * from "./RadioComponent.vue?vue&type=script&lang=js&"
import style0 from "./RadioComponent.vue?vue&type=style&index=0&id=630f56c3&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630f56c3",
  null
  
)

export default component.exports