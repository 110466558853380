//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconImage from '../IconImage.vue'

import {ASISTANTS, CONTACTS, PHONE_CNTL} from '../../store/modulesNames'
import {
    GET_ANSWERED_CALLS,
    GET_CALLS,
    GET_MERGED_CONTACT_BY_ID,
    GET_MERGED_CONTACT_BY_PHONE
} from '../../store/gettersTypes'
import { ACT_SELECT_CALL } from '../../store/actionsTypes'

import { PHONE_TYPES } from '../../constants'

import { mapGetters } from "vuex"

export default {
    name: 'calls-selector',
    props: ['type'],
    data () {
        return {
            showCallsList: false
        }
    },
    components: {
        IconImage
    },
    watch: {
        calls () {
            if (this.calls.length === 1) this.showCallsList = false
        }    
    },
    computed: {
        calls () {
            let answeredCalls = this.$store.getters[`${PHONE_CNTL}/${GET_ANSWERED_CALLS}`]
            let calls = []
            if (answeredCalls.length && answeredCalls[0].type === PHONE_TYPES.ASSISTANT) {
                let queueIds = this.$store.getters[`${ASISTANTS}/${GET_CALLS}`].map(item => item.id)
                queueIds.forEach(id => {
                    answeredCalls.forEach(item => {
                        if (item.id === id) calls.push(item)
                    })   
                })                
            } else if (answeredCalls.length) {
                calls = answeredCalls.slice()
                calls = calls.sort(({active: activeA}, {active: activeB}) => {
                    if (activeA && !activeB) return -1
                    else if (!activeA && activeB) return 1
                    else return 0
                })
            } else calls = answeredCalls.slice()
            calls.shift() //@todo разобраться с очередями во всех телефонах

            calls.forEach((item, i) => {
                switch (item.type) {
                    case PHONE_TYPES.PHONE:
                        calls[i].user = calls[i].user = this.getUser({cid: item.data.cid, number: item.data.number})
                        break   
                    case PHONE_TYPES.ASSISTANT:
                        calls[i].user = this.getUser({number: item.data.digits})
                        break
                    case PHONE_TYPES.CONFERENCE:
                        calls[i].user = {
                            fio: this.$t('phone.conference'),
                            photo: 'img/logo_video_conf.png'
                        }
                        break
                    case PHONE_TYPES.TETRA:
                        calls[i].user = this.getUser({tetraUser: item.data.tetraUser})
                        break
                }           
            })
            return calls
        },
        ...mapGetters(CONTACTS, [
            GET_MERGED_CONTACT_BY_ID,
            GET_MERGED_CONTACT_BY_PHONE,
        ])
    },
    methods: {
        getUser({cid, number, tetraUser}) {
            let contact
            if (cid) contact = this[GET_MERGED_CONTACT_BY_ID](cid)
            else if (number) {
                contact = this[GET_MERGED_CONTACT_BY_PHONE](number)
                if (!contact) contact = {fio: number, photo: ''}
            } else if (tetraUser) {
                contact = {fio: tetraUser && (tetraUser.name || tetraUser.ssi) || '', photo: ''}
            } else if (number === '') {
                contact = {fio: this.$t('modal.not-defined-number'), photo: ''}
            }
            return contact
        },
        toggleCallsList () {
            this.showCallsList = !this.showCallsList     
        },
        showSelectCall (data) {
            this.showCallsList = false
            this.$store.dispatch(`${PHONE_CNTL}/${ACT_SELECT_CALL}`, data)
        }
    }
}

