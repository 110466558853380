//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import login_mixin from './login-mixin';
import {mapGetters} from 'vuex';
import {GET_LOGIN_DATA, GET_IS_ELECTRON, GET_CONNECTION_STATUS} from "../../store/gettersTypes";
import {LOGIN, CLIENTDATA, SOCKET} from "../../store/modulesNames";

export default {
    name: "ChooseMethod",
    mixins: [login_mixin],
    computed: {
        ...mapGetters(LOGIN, [GET_LOGIN_DATA]),
        ...mapGetters(CLIENTDATA, [GET_IS_ELECTRON]),
        ...mapGetters(SOCKET, [GET_CONNECTION_STATUS]),
    },
    methods: {
        async goToNext(next) {
            this.$emit('update:next', next);
            if (this[GET_IS_ELECTRON]) this.$parent.doAdd('select-server');
            else {
                try {
                    await app.init(false);
                    this.$parent.doAdd(next);
                } catch(e) {
                    this.showAlert(this.getLoginError(e))
                }
            }
        },
        doOpenEntrance(type) {
            this.$parent.doAdd(type);
        },
    },
}
