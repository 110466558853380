
import { mapActions } from 'vuex'
import SelectContact from "./SelectContact.vue"
import SendContactWithFields from "./SendContactWithFields.vue"
import {AJAX, BLOB, CONTACTS} from '../../store/modulesNames'
import {ACT_AJAX_SEND_FILE, ACT_GET_FILE_FROM_BLOB_URL} from '../../store/actionsTypes'
import {IS_CONTACT_HAVE_LOCAL_CHANGES} from '../../store/gettersTypes'

export default {
    name: "SelectContactSendInChat",
    extends: SelectContact,
    created() {
        this.showContactsTabs = true
    },
    computed: {
        type: () => null
    },
    methods: {
        async onOk(contact) {
            console.log("🚀 ~ file: SelectContactSendInChat.vue:20 ~ onOk ~ contact:", contact)
            let cid = contact.cid, 
                photoFileName = '', 
                remoteCid = contact && contact.remoteCid,
                photo = contact && contact.photo
            const isLocal = this.$store.getters[`${CONTACTS}/${IS_CONTACT_HAVE_LOCAL_CHANGES}`](cid)
            let localPhoto, localPhotoFile
            if (remoteCid) localPhoto = photo
            else if (isLocal) {
                localPhoto = this.$store.getters['userdata/getLocalPhotoUrl'](cid)
                localPhotoFile = await fetch(localPhoto).catch()
            }
            let globalPhoto
            if (remoteCid) globalPhoto = photo
            else globalPhoto = this.$store.getters['userdata/getPhotoUrlbyCid'](cid)
            let globalPhotoFile = await fetch(globalPhoto).catch()
            const isPhotoGlobal = !localPhotoFile.ok
            const isPhotoGlobalOk = globalPhotoFile.ok

            if (!isLocal && !this.isLocalContacts) {
                const localFields = this.$store.getters['contacts/getLocalChangedFields'](cid)
                if (!isPhotoGlobal && contact.photo) {
                    photoFileName = await this.getFileName(contact.photo)
                }
                this.modalClose()
                this.props.cb && this.props.cb({ contact: contact, photoFileName, fields: localFields})
            } else {
                this.modalClose()
                this.modalOpen({
                    name: 'select-contact-withfields',
                    component: SendContactWithFields,
                    props: {
                        contact,
                        isLocal,
                        isPhotoGlobal,
                        isPhotoGlobalOk,
                        cb: async ({fields, withPhoto}) => {
                            if (isLocal) {
                                try {
                                    if (withPhoto && localPhotoFile.ok) photoFileName = await this.getFileName(localPhoto)
                                    else if (isPhotoGlobalOk) photoFileName = await this.getFileName(globalPhoto)
                                    else if (contact.photo) photoFileName = await this.getFileName(contact.photo)
                                } catch (e){
                                    console.log("!! -> file: SelectContactSendInChat.vue -> cb: -> e", e)
                                }
                            } else if (withPhoto) {
                                if (!isPhotoGlobal && contact.photo) photoFileName = await this.getFileName(contact.photo)
                                if (isPhotoGlobalOk) photoFileName = await this.getFileName(globalPhoto)
                            }
                            console.log("🚀 ~ file: SelectContactSendInChat.v ue:70 ~ cb: ~ contact:", contact)
                            this.props.cb && this.props.cb({ contact: contact, photoFileName, fields})
                        }
                    },
                })
            }
        },
        onTabClick(val) {
            this.isLocalContacts = Boolean(val)
        },
        async getFileName(photoUrl) {
            const file = await this[ACT_GET_FILE_FROM_BLOB_URL]({url: photoUrl})
            return this[ACT_AJAX_SEND_FILE]({url: declarations.http_post_dirs.CHAT_DATA_DIR, file})
        },
        ...mapActions(AJAX, [ACT_AJAX_SEND_FILE]),
        ...mapActions(BLOB, [ACT_GET_FILE_FROM_BLOB_URL]),
    }
}

