'use strict';
import Vue from 'vue';
import proto from '../../protocol';
import {
    GET_SELECTED_CONTACT,
    GET_SUPPORT,
    GET_INFO_HISTORY_LENGTH,
    GET_INFO_OPEN,
    GET_BIRTHDAY_NOTIFY,
    GET_MERGED_CONTACTS_BY_IDS,
    GET_FAVOURITES,
    GET_FAVOURITES_CIDS,
    GET_CONTACT_STATUS_TEXT,
    GET_PHOTO_URL_BY_CID,
    GET_REMOTE_PHOTO_URL_BY_CID,
    GET_MERGED_CONTACT_BY_ID,
    GET_CONTACT_BY_ID,
    GET_LOCAL_CONTACT_BY_ID,
    GET_MERGED_CONTACT_BY_PHONE,
    GET_MERGED_CONTACT_BY_EMAIL,
    GET_FILTER_VALUES_LIST_BY_TYPE,
    GET_UID,
    GET_MY_CONTACT,
    GET_MY_FULL_NAME,
    GET_PHONE_LIST_BY_ID,
    GET_CONTACTS_MERGED,
    GET_CONTACTS_GLOBAL,
    GET_CONTACTS_LOCAL,
    GET_SERVER_API,
    GET_ROLE_REVISION,
    GET_SIDE_BAR_NOTIFICATION,
    GET_IS_ROLES_SUPPORTED,
    GET_CHATS,
    GET_IS_SEARCH_FILTER_SUPPORTED,
    GET_CONTACTS_FILTER_BY_NAME,
    FAVOURITES_IN_CHANGE_MODE,
    GET_LIST_OF_BIRTHDAYS,
    GET_ROLES_REVISION,
    IS_CONTACT_HAVE_LOCAL_CHANGES,
    GET_LOCAL_CHANGED_FIELDS,
    GET_MAILS_LIST_BY_ID,
    GET_CONTACT_BY_PHONE,
    GET_LOCAL_PHOTO_URL,
    GET_CALLS_HISTORY_LIST,
    IS_CONTACT_LOCAL_ONLY,
} from '../gettersTypes'
import {
    ACT_INFO_PUSH,
    ACT_SELECT_CONTACT,
    ACT_CHANGE_CONTACT,
    ACT_ADD_CONTACT,
    ACT_ADD_BOT_CONTACT,
    ACT_ADD_CONTACT_CONNECTIONS,
    ACT_DELETE_CONTACT,
    ACT_PUSH_FAVOURITES,
    ACT_UPDATE_CONTACT_STATUS,
    ACT_LOAD_BOT_CONTACT,
    ACT_UPDATE_CONTACTS_BIRTHDAYS,
    ACT_SEARCH_CONTACTS,
    ACT_UPDATE_SEARCHED_CONTACT,
    ACT_ADD_CONTACT_SHORT_INFO,
    ACT_REPLACE_MAIN_TYPE,
    ACT_UPDATE_SEARCH_FILTER,
    ACT_SET_CONTACTS_FILTER,
    ACT_INVITE_GUEST_CONTACT,
    ACT_UPDATE_FAVOURITES,
    ACT_CHANGE_FAVOURITES_MODE,
    ACT_SET_FAVOURITES,
    ACT_SPLICE_FAVOURITES,
    ACT_ADD_FAVOURITE,
    ACT_UPDATE_ROLE_REVISION,
    ACT_REFRESH_UPDATE_CONTACTS,
    ACT_UPDATE_MY_CONTACT_ROLE_ACTIONS_INFO,
    ACT_UPDATE_SINGLE_CONTACT,
    ACT_HANDLE_FAVOURITES_EVENT,
    ACT_HANDLE_ROLES_REVISION_EVENT,
    ACT_HANDLE_CONTACT_CONNECTION_CHANGE_EVENT,
    ACT_SET_BIRTHDAYS_WATCHED,
    ACT_BOT_REMOVE,
    ACT_HANDLE_CONTACT_CHANGE_EVENT,
    ACT_ADD_CONTACT_TO_LIST,
    ACT_UPDATE_CONTACTS,
    ACT_FORM_PROJECT_FORMAT_CONTACT,
    ACT_FORM_CONTACT_DISPLAY_NAME,
    ACT_ADD_CONTACTS_TO_LIST,
    ACT_UPDATE_CALLS_AFTER_CONTACT_DELETED,
} from '../actionsTypes'
import {
    MUT_SET_SELECTED_CONTACT,
    MUT_CHANGE_BOT,
    MUT_SET_SUPPORT,
    MUT_SET_SIDE_BAR_NOTIFICATION,
    MUT_DELETE_SIDE_BAR_NOTIFICATION,
    MUT_CLEAR_CONTACTS,
    MUT_CHANGE_BOT_PROP,
} from '../mutationsTypes'
import {
    CONTENT_MANAGER,
    BOTS,
    INFO,
    USERDATA,
    LOGIN,
    CHATS,
    // CONTACTS,
    CALLS,
} from '../modulesNames'

import { i18n } from '../../../ext/i18n'
import moment from 'moment';

import { MAIN_TYPES, SIDE_TYPES } from "./content-manager";
import {
    CONTACTS_SEARCH_TYPES,
    CONTACT_FIELD_TYPES,
    MULTIPLE_CONTACT_FIELD_TYPES,
    PHONE_CONTACT_FIELD_TYPE, CONTACT_TYPE,
} from '../../constants'


let locale = i18n.messages[i18n.locale]

const systemCid = 0

// @todo убрать в 2023
let birthdaysWatchedOld = localStorage.getItem('birthdays_watched')
if (birthdaysWatchedOld) localStorage.removeItem('birthdays_watched')

const state = {
    contacts: {},
    localContacts: {},
    contactsMerged: {},
    favourites: [],
    birthdaysList: {},
    searchedList: {},
    shortList: {},
    changeFavouritesMode: false,
    birthdays_watched: birthdaysWatchedOld || moment().add(-1, 'days').format('DD.MM.YYYY'), // @todo убрать birthdaysWatchedOld в 2023
    selected: null,
    support: null,
    contactsStatuses: {},
    filterList: {},
    filters: {},
    rev: 0,
};

const getters = {
    [GET_ROLE_REVISION]: state => state.rev,
    [GET_SELECTED_CONTACT]: state => state.selected,
    [FAVOURITES_IN_CHANGE_MODE]: state => state.changeFavouritesMode,
    [GET_FAVOURITES]: state => state.favourites.map(({cid}) => getContactById(state.contactsMerged, cid)),
    [GET_FAVOURITES_CIDS]: state => state.favourites.map(({cid}) => cid),
    [GET_IS_ROLES_SUPPORTED](state, getters) {
        const serverAPILevel = getters[GET_SERVER_API]
        return serverAPILevel > declarations.serverAPILevels.LEVEL_11
    },
    [GET_IS_SEARCH_FILTER_SUPPORTED](state, getters) {
        const serverAPILevel = getters[GET_SERVER_API]
        return serverAPILevel >= declarations.serverAPILevels.LEVEL_13
    },
    [GET_LIST_OF_BIRTHDAYS](state, getters, rootState, rootGetters) {
        let birthdays = []
        let contacts = getters[GET_CONTACTS_MERGED]
        const serverAPI = getters[GET_SERVER_API]
        const BIRTHDAY_SHOW_PERIOD = rootGetters[`${USERDATA}/${GET_BIRTHDAY_NOTIFY}`]
        if (BIRTHDAY_SHOW_PERIOD === 10) return // значение не беспокоить
        contacts = serverAPI < declarations.serverAPILevels.LEVEL_12 ? getters[GET_CONTACTS_MERGED] : Object.values(state.birthdaysList)
        contacts.forEach(contact => {
            const start_day = moment().subtract(1, 'days');
            const end_day = moment().add(BIRTHDAY_SHOW_PERIOD, 'days');
            let birthday = contact && contact[CONTACT_FIELD_TYPES.BIRTHDAY] || null
            let bObj = moment(birthday, 'DD.MM')
            if (!birthday || !bObj.isValid()) return
            birthday = `${bObj.format('DD.MM')}.${start_day.format('YYYY')}`
            if (birthday && moment(birthday, 'DD.MM.YYYY').isAfter(start_day) && moment(birthday, 'DD.MM.YYYY').isBefore(end_day)) {
                birthday && birthdays.push({
                    cid: contact.cid,
                    date: bObj.calendar(null, {sameDay: `[${locale.settings.today}]`, nextDay: `[${locale.tomorrow}]`, nextWeek: 'DD MMMM', sameElse: 'DD MMMM'}),
                    dateComp: bObj.format('DD.MM'),
                    fio: contact.fio,
                    photo: contact.photo
                })
            }
        })

        birthdays.sort((a,b) => {
            let bd1 = moment(a.dateComp, 'DD.MM.YYYY');
            let bd2 = moment(b.dateComp, 'DD.MM.YYYY');
            if (moment(bd1).isAfter(moment(bd2))) return 1;
            else if (moment(bd1).isBefore(moment(bd2))) return -1;
            else return utils.textSortFn(a.fio, b.fio)
        });
        if ((state.birthdays_watched &&
             moment(state.birthdays_watched, 'DD.MM.YYYY')
             .isSame(moment(), 'day'))) return false
        else return birthdays
    },
    [GET_CONTACTS_GLOBAL]: state => Object.values(state.contacts),
    [GET_CONTACTS_MERGED]: (state) => Object.values(state.contactsMerged),
    [GET_CONTACTS_LOCAL]: state => Object.values(state.localContacts),
    [GET_CONTACTS_FILTER_BY_NAME]: state => (name) => state.filters[name] || {},
    [GET_ROLES_REVISION]: state => state.rev,
    [GET_CONTACT_BY_ID]: state => cid => {
        let contact = getContactById(state.contacts, cid)
        if (!contact.cid) contact = getContactById(state.searchedList, cid)
        return contact
    },
    [GET_LOCAL_CONTACT_BY_ID]: state => cid => getContactById(state.localContacts, cid),
    [IS_CONTACT_HAVE_LOCAL_CHANGES]: (state, getters) => cid => Boolean(getters[GET_LOCAL_CONTACT_BY_ID](cid)),
    [IS_CONTACT_LOCAL_ONLY]: (state, getters) => cid => {
        return Boolean(getters[GET_LOCAL_CONTACT_BY_ID](cid).cid && !getContactById(state.contacts, cid).cid)
    },
    [GET_MERGED_CONTACT_BY_ID]: state => cid => {
        let contact = getContactById(state.contactsMerged, cid)
        if (contact && contact.notFound) contact = getContactById(state.searchedList, cid)
        if (contact && contact.notFound) contact = getContactById(state.shortList, cid)
        return contact
    },
    [GET_MERGED_CONTACTS_BY_IDS]: state => cids => {
        return cids.map(cid => getContactById(state.contactsMerged, cid)).sort((a, b) => a.fio.toLowerCase() > b.fio.toLowerCase() ? 1 : -1)
    },
    [GET_FILTER_VALUES_LIST_BY_TYPE]: (state, getters) => (type) => {
        return [...new Set(state.filterList[type] ?? [])].sort((a, b) => a.toUpperCase() > b.toUpperCase() ? 1 : -1)
    },
    [GET_LOCAL_CHANGED_FIELDS]: (state, getters) => cid => {
        let globalContactFields = getters[GET_CONTACT_BY_ID](cid).fields
        let mergedContactFields = getters[GET_MERGED_CONTACT_BY_ID](cid).fields
        return getChangedFields(globalContactFields, mergedContactFields)
    },
    [GET_PHONE_LIST_BY_ID]: (state, getters) => (cid, isSearched = false) => {
        let contact = getters[GET_MERGED_CONTACT_BY_ID](cid)
        if (!( contact && contact.fields && contact.fields.length)) return []
        let phones = PHONE_CONTACT_FIELD_TYPE.reduce((phones, fieldType) => {
            const filteredPhones = contact.fields.filter(({type}) => type === fieldType)
            return filteredPhones.length ? phones.concat(filteredPhones) : phones
        }, [])
        let phones_unique = phones.filter((e, i) => phones.findIndex(a => a.value === e.value) === i)
        return phones_unique
    },
    [GET_MAILS_LIST_BY_ID]: (state, getters) => (cid, isSearched = false) => {
        let contact = getters[GET_MERGED_CONTACT_BY_ID](cid)
        let mails = []
        if (contact && contact.fields && contact.fields.length) {
            mails = contact.fields.filter(({type}) => type === CONTACT_FIELD_TYPES.MAIL)
        }
        return mails || []
    },
    [GET_CONTACT_BY_PHONE]: (state, getters) => (phone) => {
        return getters[GET_CONTACTS_GLOBAL].find((contact) => {
            return !!([].concat(
                contact[CONTACT_FIELD_TYPES.WORKPHONE],
                contact[CONTACT_FIELD_TYPES.HOMEPHONE],
                contact[CONTACT_FIELD_TYPES.MOBILEPHONE],
                contact[CONTACT_FIELD_TYPES.PHONE],
            ).find((cur_phone) => {
                return cur_phone === phone
            }))
        })
    },
    [GET_MERGED_CONTACT_BY_PHONE]: (state, getters) => phone => {
        return getters[GET_CONTACTS_MERGED].find((contact) => {
            return !!([].concat(
                contact[CONTACT_FIELD_TYPES.WORKPHONE],
                contact[CONTACT_FIELD_TYPES.HOMEPHONE],
                contact[CONTACT_FIELD_TYPES.MOBILEPHONE],
                contact[CONTACT_FIELD_TYPES.PHONE],
            ).find((cur_phone) => {
                return cur_phone === phone
            }))
        })
    },
    [GET_MERGED_CONTACT_BY_EMAIL]: (state, getters) => email => {
        const contactsWithEmails = getters[GET_CONTACTS_MERGED].filter(c => c[CONTACT_FIELD_TYPES.MAIL])
        return contactsWithEmails.find(c => c[CONTACT_FIELD_TYPES.MAIL].some(m => m === email))
    },
    [GET_CONTACT_STATUS_TEXT]: (state) => payload => {
        let status = locale.updating.toLowerCase()
        if (!state.contactsStatuses || !state.contactsStatuses[payload.cid] || state.contactsStatuses[payload.cid].pending) return status
        else if('statusTime' in state.contactsStatuses[payload.cid] ) {
            if(state.contactsStatuses[payload.cid].statusTime === -1) status = locale.mainPage.offline;
            if(state.contactsStatuses[payload.cid].statusTime === 0) status = locale.mainPage.online;
            else if (state.contactsStatuses[payload.cid].statusTime > 0) {
                let today = moment().startOf('day');
                let yesterday = moment().subtract(1, 'days').startOf('day');
                let time = state.contactsStatuses[payload.cid].statusTime;
                let timeFormat = moment().subtract(time, 'seconds');
                if(time < 60) {
                    status = locale.mainPage['last-seen-recently'];
                }  else if(time < 43200) {
                    const roundingDefault = moment.relativeTimeRounding()
                    moment.relativeTimeRounding(Math.floor)
                    status = locale.mainPage['last-seen'] + moment().to(timeFormat)
                    moment.relativeTimeRounding(roundingDefault)
                } else if(timeFormat.isAfter(today)) {
                    status = locale.mainPage['last-seen-today'] + moment().subtract(time, 'seconds').format('HH:mm');
                } else if(timeFormat.isAfter(yesterday)) {
                    status = locale.mainPage['last-seen-yesterday'] + moment().subtract(time, 'seconds').format('HH:mm');
                } else {
                    status = locale.mainPage['last-seen'] + moment().subtract(time, 'seconds').format('DD MMMM YYYY');
                }
            }
        } else {
            status = locale.mainPage.offline
        }
        return status
    },
    [GET_SUPPORT](state) {
        return state.support;
    },
    [GET_MY_CONTACT](state, getters, rootState, rootGetters) {
        let uid = rootGetters[`${USERDATA}/${GET_UID}`]
        return getters[GET_MERGED_CONTACT_BY_ID](uid)
    },
    [GET_MY_FULL_NAME](state, getters) {
        return (getters[GET_MY_CONTACT] || {}).fio
    },
    [GET_SERVER_API](state, getters, rootState, rootGetters) {
        return rootGetters[`${LOGIN}/${GET_SERVER_API}`]
    },
};

const actions = {
    [ACT_SET_CONTACTS_FILTER] ({state}, payload) {
        state.filters = { ...payload }
    },
    [ACT_CHANGE_FAVOURITES_MODE]: (obj, mode) => {
        obj.commit('changeFavouritesMode', mode);
    },
    [ACT_ADD_CONTACT_CONNECTIONS]: async (obj, cids) => {
        let data = {}
        data.connections = cids
        try {
            await proto.addContactConnections(data)
        } catch(e) {
            console.error(e);
        }
    },
    [ACT_SET_FAVOURITES]: async ({commit, state}, cids) => {
        const tmp = {};
        const favourites = state.favourites;
        for (let i = 0; i < favourites.length; i++) {
            tmp[favourites[i].cid] = favourites[i];
        }
        const newFavourites = [];
        for (let i = 0; i < cids.length; i++) {
            if (!!tmp[cids[i]]) newFavourites.push(tmp[cids[i]]);
            else newFavourites.push({cid: cids[i]});
        }
        try {
            await proto.setFavouritesList(newFavourites);
            commit('favourites', newFavourites);
        } catch(e) {
            console.error(e);
        }
    },
    [ACT_PUSH_FAVOURITES]: async ({commit, state}, cids) => {
        for (let i in state.favourites) {
            if (cids.includes(state.favourites[i].cid)) return;
        }

        const favourites = state.favourites.concat(cids.map((cid) => ({cid})));
        try {
            await proto.setFavouritesList(favourites);
            commit('favourites', favourites);
        } catch(e) {
            console.error(e);
        }
    },
    [ACT_SPLICE_FAVOURITES]: async ({state}, contact_id) => {
        for(let i = 0; i < state.favourites.length; i++) {
            if (state.favourites[i].cid === contact_id) state.favourites.splice(i, 1);
        }
        try {
            await proto.setFavouritesList(state.favourites);
        } catch(e) {
            console.log('Unable to delete favourite');
        }
    },
    [ACT_ADD_FAVOURITE]: async (obj, contact_id) => {
        if (!contact_id) return
        const isExist = obj.state.favourites.find(cid => cid === contact_id)
        if (isExist) return
        obj.state.favourites.push({cid: contact_id})
        try {
            await proto.setFavouritesList(obj.state.favourites)
        } catch(e) {
            console.log('Unable to add to favourites')
        }
    },
    async [ACT_SELECT_CONTACT]({commit, dispatch, getters, rootGetters}, contact_id) {
        const root = true
        let contact ={}
        if (getters[GET_IS_ROLES_SUPPORTED]) {
            contact = getters[GET_MERGED_CONTACT_BY_ID](contact_id)
            if (!contact.actions) {
               try {
                   await dispatch(ACT_UPDATE_SEARCHED_CONTACT, {cid: contact_id})
               } catch (e) {}
            } 
        }
        const isBot = contact.hasOwnProperty(CONTACT_FIELD_TYPES.ISBOT) && contact[CONTACT_FIELD_TYPES.ISBOT]
        if (isBot) {
            dispatch(ACT_ADD_BOT_CONTACT, contact)
        }
        dispatch('acs/setCid', contact_id, {root});
        commit(MUT_SET_SELECTED_CONTACT, contact_id);
        dispatch('chats/selected', {cid: contact_id, cidType: 'user'}, {root});
        //dispatch('chats/open', {cid: contact_id, cidType: 'user'}, {root: true})
        const openInfo = rootGetters[`${INFO}/${GET_INFO_OPEN}`];
        const infoHistoryLength = rootGetters[`${INFO}/${GET_INFO_HISTORY_LENGTH}`];
        if (openInfo && !infoHistoryLength) {
            let params = { isBot }
            dispatch(`${INFO}/${ACT_INFO_PUSH}`, {type: openInfo.type, params}, {root: true});
        }
        let type = MAIN_TYPES.CONTACT
        if (contact_id === getters[GET_MY_CONTACT].cid) type = MAIN_TYPES.NOTE
        dispatch(`${CONTENT_MANAGER}/${ACT_REPLACE_MAIN_TYPE}`, { type, params: {cid: contact_id} }, {root});
    },
    async [ACT_UPDATE_FAVOURITES]({commit}, params) {
        const favourites = await proto.getFavouritesList()
        commit('favourites', favourites)
    },
    async [ACT_UPDATE_ROLE_REVISION]({ getters, commit }, params) {
        const revObj = await proto.getRoleRevision()
        const rev = revObj && revObj.rev
        if (getters[GET_ROLES_REVISION] < rev) commit('setRoleRevision', revObj)
    },
    async [ACT_UPDATE_CONTACTS_BIRTHDAYS]({ commit, rootGetters, dispatch }) {
        const BIRTHDAY_SHOW_PERIOD = rootGetters[`${USERDATA}/${GET_BIRTHDAY_NOTIFY}`]
        let birthdaysList = await proto.getBirthdays({ interval: BIRTHDAY_SHOW_PERIOD })
        birthdaysList = await Promise.all(birthdaysList.map(contact => {
            contact.fio[CONTACT_FIELD_TYPES.BIRTHDAY] = contact.rawBirthday
            return dispatch(ACT_FORM_PROJECT_FORMAT_CONTACT, {type: CONTACT_TYPE.GLOBAL, contact})
        }))
        commit('setBirthDaysList', birthdaysList)
    },
    async [ACT_UPDATE_CONTACTS]({ getters, dispatch, rootGetters }, params) {
        await dispatch(ACT_UPDATE_FAVOURITES)
        if (getters[GET_IS_ROLES_SUPPORTED]) {
            await dispatch(ACT_UPDATE_ROLE_REVISION)
            await dispatch(ACT_UPDATE_CONTACTS_BIRTHDAYS)
            const favCids = getters[GET_FAVOURITES_CIDS]
            const chats = rootGetters[`${CHATS}/${GET_CHATS}`]
            const chatsCids = [...new Set(chats.filter(item => item.cid && item.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER).map(item => item.cid))]
            chatsCids.push(systemCid)
            const supportCid = (getters[GET_SUPPORT] || {}).cid
            if (supportCid) chatsCids.push(supportCid)
            const groupChats = chats.filter(item => item.cid && item.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) //.map(item => item.cid))]
            let groupChatsCids = []
            groupChats.forEach(groupChat => {
                const groupChatCids = [...new Set(groupChat.contacts.map(c => c.cid))]
                groupChatsCids = [...new Set(groupChatsCids.concat(groupChatCids))]
            })
            const callsHistory = rootGetters[`${CALLS}/${GET_CALLS_HISTORY_LIST}`]
            const callsCids = [...new Set(callsHistory.filter(item => item.cid).map(item => item.cid))]
            const connectionsCids = [...new Set(chatsCids.concat(groupChatsCids).concat(callsCids).concat(favCids))]
            await dispatch(ACT_ADD_CONTACT_CONNECTIONS, connectionsCids)
        }
        await dispatch(ACT_REFRESH_UPDATE_CONTACTS)
    },
    async [ACT_UPDATE_SINGLE_CONTACT]({ getters, dispatch }, { type, cid }) {
        let contact = {}, payload = {}
        if (type) {
            contact = await proto.getContact({cid, type})
            payload = {type, contact }
            dispatch(ACT_ADD_CONTACT_TO_LIST, payload)
        } else {
            contact = await proto.getContact({cid, type: CONTACT_TYPE.GLOBAL})
            payload = {type: CONTACT_TYPE.GLOBAL, contact }
            dispatch(ACT_ADD_CONTACT_TO_LIST, payload)
            let local_contact = await proto.getContact({cid, type: CONTACT_TYPE.LOCAL})
            let { error } = local_contact
            if (!error) {
                payload.type = CONTACT_TYPE.LOCAL
                payload.contact = local_contact
                dispatch(ACT_ADD_CONTACT_TO_LIST, payload)
            }
        }
        if (cid === getters[GET_MY_CONTACT].cid) dispatch(ACT_UPDATE_MY_CONTACT_ROLE_ACTIONS_INFO)
    },
    async [ACT_ADD_CONTACT_SHORT_INFO]({ dispatch, commit }, contact) {
        if (!contact || !contact.cid || !contact.fio) return
        const newContact = await dispatch(ACT_FORM_PROJECT_FORMAT_CONTACT, {type: CONTACT_TYPE.GLOBAL, contact})
        commit('addContactShort', newContact)
    },
    async [ACT_UPDATE_SEARCHED_CONTACT]({ getters, dispatch, commit }, { type = CONTACT_TYPE.GLOBAL, cid }) {
        const contact = await proto.getContact({cid, type})
        commit('updateSearchedContact', await dispatch(ACT_FORM_PROJECT_FORMAT_CONTACT, {type, contact}))
    },
    async [ACT_REFRESH_UPDATE_CONTACTS]({ dispatch, commit }) {
        const globalContacts = await proto.getContacts(true)
        const localContacts = await proto.getContacts(false)
        await dispatch(ACT_ADD_CONTACTS_TO_LIST, {type: CONTACT_TYPE.GLOBAL, contacts: globalContacts})
        await dispatch(ACT_ADD_CONTACTS_TO_LIST, {type: CONTACT_TYPE.LOCAL, contacts: localContacts})
        dispatch(ACT_UPDATE_MY_CONTACT_ROLE_ACTIONS_INFO)
    },
    [ACT_UPDATE_MY_CONTACT_ROLE_ACTIONS_INFO]({ getters, rootGetters, commit }) {
        const my_contact = getters[GET_MY_CONTACT]
        const { actions = {} } = my_contact
        let payload = {}, currentMsg = '', isExist = false
        Object.keys(actions).forEach(k => {
            switch (k) {
                case 'accept-call':
                    payload.type = SIDE_TYPES.CALLS_LIST
                    if (!actions[k]) {
                        if (actions['make-call']) {
                            payload.msg = locale.roles["no-accept-call"]
                            commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                        }
                    }
                    if (actions[k] && actions['make-call']) {
                        payload.msg = ''
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
                case 'make-call':
                    if (!actions[k]) {
                        payload.type = SIDE_TYPES.CALLS_LIST
                        if (!actions['accept-call']) payload.msg = locale.roles["no-make-receive-call"]
                        else payload.msg = locale.roles["no-make-call"]
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
                // case 'create-group':
                case 'create-chats':
                    currentMsg = rootGetters[`${CONTENT_MANAGER}/${GET_SIDE_BAR_NOTIFICATION}`](SIDE_TYPES.CHATS)
                    isExist = currentMsg.indexOf(locale.roles["no-create-group"]) > -1
                    payload.type = SIDE_TYPES.CHATS
                    if (!actions[k] && !isExist) {
                        payload.msg = currentMsg ? currentMsg + locale.roles["no-create-group"] : locale.roles["no-create-group"]
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    if (actions[k] && isExist) {
                        payload.msg = locale.roles["no-create-group"]
                        commit(`${CONTENT_MANAGER}/${MUT_DELETE_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
                case 'send-message':
                    currentMsg = rootGetters[`${CONTENT_MANAGER}/${GET_SIDE_BAR_NOTIFICATION}`](SIDE_TYPES.CHATS)
                    isExist = currentMsg.indexOf(locale.roles["no-send-message"]) > -1
                    payload.type = SIDE_TYPES.CHATS
                    if (!actions[k] && !isExist) {
                        payload.msg = currentMsg ? currentMsg + locale.roles["no-send-message"]: locale.roles["no-send-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    if (actions[k] && isExist) {
                        payload.msg = locale.roles["no-send-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_DELETE_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
                case 'receive-message':
                    currentMsg = rootGetters[`${CONTENT_MANAGER}/${GET_SIDE_BAR_NOTIFICATION}`](SIDE_TYPES.CHATS)
                    isExist = currentMsg.indexOf(locale.roles["no-receive-message"]) > -1
                    payload.type = SIDE_TYPES.CHATS
                    if (!actions[k] && !isExist) {
                        payload.msg =  currentMsg ? currentMsg + locale.roles["no-receive-message"]: locale.roles["no-receive-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    if (actions[k] && isExist) {
                        payload.msg = locale.roles["no-receive-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_DELETE_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
                case 'send-media-message':
                    currentMsg = rootGetters[`${CONTENT_MANAGER}/${GET_SIDE_BAR_NOTIFICATION}`](SIDE_TYPES.CHATS)
                    isExist = currentMsg.indexOf(locale.roles["no-send-media-message"]) > -1
                    if (!actions[k] && !isExist) {
                        payload.type = SIDE_TYPES.CHATS
                        payload.msg = currentMsg ? currentMsg + locale.roles["no-send-media-message"] : locale.roles["no-send-media-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    if (actions[k] && isExist) {
                        payload.msg = locale.roles["no-send-media-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_DELETE_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
            }
        })
    },
    async [ACT_LOAD_BOT_CONTACT]({ commit, getters, dispatch }, cid) {
        let contact = getters[GET_MERGED_CONTACT_BY_ID](cid)
        if (!contact.cid) {
            let type = CONTACT_TYPE.GLOBAL
            contact = await proto.getContact({cid, type})
            if (contact) {
                await dispatch(ACT_ADD_CONTACT_TO_LIST, { type: CONTACT_TYPE.LOCAL, contact, temp: true })
            }
        }
    },
    async [ACT_ADD_BOT_CONTACT]({ getters, dispatch, commit }, bot) { //@todo понять кейс
        let botContact = getters[GET_MERGED_CONTACT_BY_ID](bot.cid)
        if (!botContact || !(botContact && botContact.fields)) {
            await dispatch(ACT_ADD_CONTACT_SHORT_INFO, bot)
        }
        botContact = getters[GET_MERGED_CONTACT_BY_ID](bot.cid)
        if (botContact?.cid) commit(`${BOTS}/${MUT_CHANGE_BOT}`, botContact, {root: true})
    },
    async [ACT_UPDATE_CONTACT_STATUS]({commit, state}, params) {
        if (state.contactsStatuses && state.contactsStatuses[params] && state.contactsStatuses[params].pending) return
        commit('setContactsStatuses', {cid: params, pending: true})
        const data = await proto.getContactsStatus(params)
        data.forEach(item => {
            item['pending'] = false
            commit('setContactsStatuses', item)
        })
    },
    async [ACT_CHANGE_CONTACT]({state, commit, dispatch}, data) {
        await proto.changeContact(data)
        if (data.cid) dispatch(ACT_ADD_CONTACT_TO_LIST, {contact: data, type: CONTACT_TYPE.LOCAL})
    },
    async [ACT_ADD_CONTACT] ({dispatch, commit, getters}, data) {
        let cid = await proto.addContact(data)
        let contact = {...{cid}, ...data}
        await dispatch(ACT_ADD_CONTACT_TO_LIST, { type: CONTACT_TYPE.LOCAL, contact })
    },
    async [ACT_ADD_CONTACTS_TO_LIST]({dispatch}, {type, contacts}) {
        return Promise.allSettled(contacts.map((contact, i) => {
            return dispatch(ACT_ADD_CONTACT_TO_LIST, {type,  contact: contacts[i]})
        }))
    },
    async [ACT_ADD_CONTACT_TO_LIST]({dispatch, commit, getters, rootGetters}, {type, contact, temp}) {
        contact = await dispatch(ACT_FORM_PROJECT_FORMAT_CONTACT, {type, contact})

        if (type === CONTACT_TYPE.LOCAL) {
            contact.actions = {
                'make-call': true, 'send-message': true, 'view-contact': true, 'search-contacts': true, 'accept-call': true,
                'send-media-message': true, 'receive-message': true, 'remote-send-message': true, 'create-chats': true, 'use-radio': true, "invite-guest": true
            }
        }

        if (contact[CONTACT_FIELD_TYPES.ISBOT]) commit(`${BOTS}/${MUT_CHANGE_BOT}`, {temp, ...contact}, { root: true })

        if (type === CONTACT_TYPE.GLOBAL) {
            Vue.set(state.contacts, contact.cid, {...contact})
        } else if (type === CONTACT_TYPE.LOCAL) {
            Vue.set(state.localContacts, contact.cid, contact)
        }


        const globalContact = getters[GET_CONTACT_BY_ID](contact.cid)
        const localContact = getters[GET_LOCAL_CONTACT_BY_ID](contact.cid)

        const globalIsSet = 'cid' in globalContact
        const localIsSet = 'cid' in localContact

        if (globalIsSet && localIsSet) {
            const mergedContactFields = mergedFieldArrays(globalContact.fields, localContact.fields)
            const mergedContact = await dispatch(ACT_FORM_PROJECT_FORMAT_CONTACT, {
                type: CONTACT_TYPE.LOCAL,
                contact: {
                    cid: contact.cid,
                    fields: mergedContactFields,
                    actions: globalContact.actions
                }
            })
            Vue.set(state.contactsMerged, contact.cid, mergedContact)
        } else {
            Vue.set(state.contactsMerged, contact.cid, {...contact})
        }
    },
    async [ACT_FORM_PROJECT_FORMAT_CONTACT]({state, dispatch, rootGetters}, {type = CONTACT_TYPE.GLOBAL, contact}) {
        contact = utils.cloneObject(contact)

        if (!contact.fields && contact.fio) {
            contact = {
                fields: Object.entries(contact.fio).map(([key, val]) => ({type: key, value: val})),
                cid: contact.cid
            }
        }

        contact.fields?.forEach((field) => {
            if (field.type) {
                let _type = field.type.toUpperCase()
                let old_type = CONTACT_FIELD_TYPES[_type]
                if (!CONTACT_FIELD_TYPES.hasOwnProperty(_type)) return
                if (MULTIPLE_CONTACT_FIELD_TYPES.indexOf(old_type) === -1) {
                    contact[old_type] = field.value
                } else {
                    if (!contact[old_type]) contact[old_type] = []
                    contact[old_type].push(field.value)
                }
            }
        })

        if (state.support && 'cid' in state.support && state.support.cid === contact.cid) contact.official = true
        const {
            displayName,
            displayFullName,
        } = await dispatch(ACT_FORM_CONTACT_DISPLAY_NAME, contact)
        contact.fio = displayName
        contact.fioFull = displayFullName
        let remoteCid = 0
        if (contact && contact.remoteCid) remoteCid = contact.remoteCid
        if (type === CONTACT_TYPE.LOCAL || type === CONTACT_TYPE.MERGED) contact.photo = rootGetters[`${USERDATA}/${GET_LOCAL_PHOTO_URL}`](contact.cid)
        else if (remoteCid) {
            const remoteParams = { objectId: contact.objectId, remoteCid }
            contact.photo = rootGetters[`${USERDATA}/${GET_REMOTE_PHOTO_URL_BY_CID}`](remoteParams)
        }
        else contact.photo = rootGetters[`${USERDATA}/${GET_PHOTO_URL_BY_CID}`](contact.cid)
        return contact
    },
    async [ACT_FORM_CONTACT_DISPLAY_NAME]({dispatch, getters, rootGetters}, contact) {
        let displayName
        let displayFullName
        if (contact[CONTACT_FIELD_TYPES.ISBOT]) {
            displayName = contact[CONTACT_FIELD_TYPES.BOTTITLE]
        } else {
            let {
                [CONTACT_FIELD_TYPES.SURNAME]: surname,
                [CONTACT_FIELD_TYPES.NAME]: name,
                [CONTACT_FIELD_TYPES.SECONDNAME]: secondname,
                [CONTACT_FIELD_TYPES.NIK]: nik,
                [CONTACT_FIELD_TYPES.PHONE]: phones,
                [CONTACT_FIELD_TYPES.WORKPHONE]: workPhones,
                [CONTACT_FIELD_TYPES.MAIL]: mails
            } = contact

            const phone = workPhones?.slice(0,1).shift() || phones?.slice(0,1).shift()
            const mail = mails?.slice(0,1).shift()

            if (!surname && !name) {
                displayName = nik || phone || mail || ''
                let isExisted = Boolean(contact.cid && getters[GET_MERGED_CONTACT_BY_ID](contact.cid)?.cid)
                if (!displayName.trim().length) {
                    if (isExisted) displayName = locale['search-comp']['noname-contact']
                    else displayName = locale['search-comp']['unknown-contact']
                }
                displayFullName = displayName
            } else {
                displayName = [surname, name].map(cur => {
                    if (cur) return cur.trim()
                }).filter(cur => !!cur).join(' ')
                if (secondname) displayFullName = displayName + ' ' + secondname
                else displayFullName = displayName
            }
        }
        return {
            displayName,
            displayFullName,
        }
    },
    async [ACT_DELETE_CONTACT]({dispatch}, data) {
        await proto.deleteContact(data)
        await dispatch(ACT_SPLICE_FAVOURITES, data.cid)
        dispatch(ACT_UPDATE_FAVOURITES)
    },
    async [ACT_SEARCH_CONTACTS]({state, getters, commit, dispatch}, {
        search = '',
        searchBots = false,
        filter = null,
        isLocal = false,
        type = CONTACTS_SEARCH_TYPES.VIEW_CONTACT,
        fromId = 0,
        count = 50,
        extFilter
    }) {
        let result = { contacts: [], isListPage: false, isLoadPossible: false, lastCid: 0 }
        let arrSearch = search.split(' ').filter(item => item.length > 0)

        if (arrSearch.length === 0) arrSearch[0] = ''
        let contacts = []
        if (getters[GET_IS_ROLES_SUPPORTED] && (search.length >= 2 || extFilter)) {
            let params = {
                filter: search,
                searchType: type,
                fromId,
                count,
                ...(extFilter && {extFilter})
            }
            contacts = await proto.searchContacts(params)
            contacts = await Promise.all(
                contacts.map((contact) => dispatch(ACT_FORM_PROJECT_FORMAT_CONTACT, {
                    type: contact.type,
                    contact,
                })),
            )
            result.isListPage = true
            result.isLoadPossible = contacts.length === count
            const lastContact = contacts[contacts.length - 1]
            if (lastContact) result.lastCid = lastContact.cid
        } else {
            let arrFields = [
                CONTACT_FIELD_TYPES.SURNAME,
                CONTACT_FIELD_TYPES.NAME,
                CONTACT_FIELD_TYPES.SECONDNAME,
                CONTACT_FIELD_TYPES.WORKPHONE,
                CONTACT_FIELD_TYPES.HOMEPHONE,
                CONTACT_FIELD_TYPES.MOBILEPHONE,
                CONTACT_FIELD_TYPES.PHONE,
                CONTACT_FIELD_TYPES.FAX,
                CONTACT_FIELD_TYPES.MAIL,
                CONTACT_FIELD_TYPES.NIK
            ]
            if (searchBots) arrFields.push(CONTACT_FIELD_TYPES.BOTTITLE)

            contacts = isLocal ? state.localContacts : state.contactsMerged
            let filteredContacts = Object.values(contacts).filter(contact => {
                if (filter !== null) {
                    if (!filter && !search) return false
                }
                if (filter === null && !search) return true
                let filterKeys = filter && Object.keys(filter) || []
                if (!filterKeys.every((key) => contact[key] && filter[key].includes(contact[key]))) return false
                
                let fieldsContactToSearch = [];
                let arrResultsSearch = [];

                let multipleName = [
                    CONTACT_FIELD_TYPES.SURNAME,
                    CONTACT_FIELD_TYPES.NAME,
                    CONTACT_FIELD_TYPES.SECONDNAME,
                ];
                
                contact.fields.forEach(item => {
                    if (item) {
                        let res = arrFields.includes(item.type);
                        if (res && typeof item.value === 'string') {
                            let arrayWords = item.value.split(' ')
                            if (arrayWords.length > 1 && multipleName.includes(item.type)) {
                                arrayWords.forEach(word => {
                                    fieldsContactToSearch.push(word.toLowerCase())
                                })
                            }
                            fieldsContactToSearch.push(item.value.toLowerCase())
                        }
                    }
                });
                
                arrSearch.forEach(item => {
                    let resultSearch = fieldsContactToSearch.some(field => {
                        let res = field.toLowerCase().replace(/ё/g, "е").indexOf(item.toLowerCase().replace(/ё/g, "е"))
                        return res === 0;
                    });
                    arrResultsSearch.push(resultSearch);
                });
                
                if (arrResultsSearch.includes(false)) return false
                else return true
            });
            
            let arr1 = []
            let arr2 = []
            filteredContacts.forEach(contact => {
                try {
                    let res = contact.fio && contact.fio.toLowerCase().indexOf(arrSearch[0].toLowerCase())
                    if(res && res === 0) arr1.push(contact)
                    else arr2.push(contact)
                } catch (e) {
                    console.log("!! -> file: contacts.js -> contact", contact)
                    console.log("!! -> file: contacts.js -> e", e)
                }
            })
            contacts = arr1.concat(arr2);
            contacts.sort((a, b) => a.fio > b.fio ? 1 : -1)
        }
        result.contacts = contacts
        return result
    },
    async [ACT_UPDATE_SEARCH_FILTER]({state}){
        let list = await proto.getContactsOrganizationFilter()
        list.forEach((typeList) => state.filterList[typeList.type] = typeList.values.map(item => item.value))
    },
    [ACT_INVITE_GUEST_CONTACT]: async (obj, data) => {
        await proto.inviteGuestContact(data)
    },
    [ACT_SET_BIRTHDAYS_WATCHED]({commit}, watched) {
        if (watched) commit('setBirthdaysWatched')
        else commit('setBirthdaysUnwatched')
    },
    [ACT_HANDLE_FAVOURITES_EVENT]: ({commit}, favourites) => {
        for(let i = 0; i < favourites.length; i++) favourites[i].cid = +favourites[i].cid;
        commit('favourites', favourites);
    },
    async [ACT_HANDLE_ROLES_REVISION_EVENT]({ getters, commit, dispatch }, params) {
        let { cid, rev } = params
        if (cid === getters[GET_MY_CONTACT].cid) {
            dispatch(ACT_REFRESH_UPDATE_CONTACTS)
            commit('setRoleRevision', { rev })
        } else dispatch(ACT_UPDATE_SINGLE_CONTACT, { cid })
    },
    async [ACT_HANDLE_CONTACT_CONNECTION_CHANGE_EVENT]({ getters, commit, dispatch }, params) {
        let { cid, type, contact } = params
        let existed_contact = getters[GET_CONTACT_BY_ID](cid)
        switch (type) {
            case "remove":
                if (existed_contact) commit('deleteContact', { cid, type: CONTACT_TYPE.GLOBAL })
                break;
            case "add":
                await dispatch(ACT_ADD_CONTACT_TO_LIST, { type: CONTACT_TYPE.GLOBAL, contact })
                break;
        }
    },
    [ACT_HANDLE_CONTACT_CHANGE_EVENT] ({dispatch, commit, getters}, {eventType, contact, cid, type}) {
        const isRolesModelSupported = getters[GET_IS_ROLES_SUPPORTED]

        switch (eventType) {
            case 'add':
                let getter = type === CONTACT_TYPE.GLOBAL ? GET_CONTACT_BY_ID : GET_LOCAL_CONTACT_BY_ID
                if (getters[getter](contact.cid).cid) {
                    dispatch(ACT_ADD_CONTACT_TO_LIST, { type, contact })
                    commit(`${BOTS}/${MUT_CHANGE_BOT_PROP}`, {cid, prop: 'temp', val: false}, { root: true })
                } else {
                    dispatch(ACT_ADD_CONTACT_TO_LIST, { type, contact })
                }
                break;
            case 'change':
                if (isRolesModelSupported) dispatch(ACT_UPDATE_SINGLE_CONTACT, { type, cid })
                else dispatch(ACT_ADD_CONTACT_TO_LIST, { type, contact })
                break;
            case 'delete':
                commit(`deleteContact`, {type, cid})
                let stillExists = Boolean(getters[GET_MERGED_CONTACT_BY_ID](cid).cid)
                dispatch(`${BOTS}/${ACT_BOT_REMOVE}`, {cid, contactRemoved: true}, { root: true })
                if (!stillExists) dispatch(`${CALLS}/${ACT_UPDATE_CALLS_AFTER_CONTACT_DELETED}`, { cid }, { root: true })
                if (isRolesModelSupported) {
                    if (!stillExists) {
                        dispatch(ACT_UPDATE_SEARCHED_CONTACT, {cid})
                    }
                }
                break;
            default:
                console.log('Unknown type of contact-change-event');
        }
    },
};

const mutations = {
    changeFavouritesMode: (state, mode) => {state.changeFavouritesMode = mode;},
    favourites: (state, favourites) => state.favourites = favourites,
    [MUT_SET_SELECTED_CONTACT](state, contact_id) {
        state.selected = contact_id;
    },
    setBirthdaysWatched(state) {
        state.birthdays_watched = moment().format('DD.MM.YYYY')
    },
    setBirthdaysUnwatched(state) {
        state.birthdays_watched = moment().add(-1, 'days').format('DD.MM.YYYY')
    },
    [MUT_CLEAR_CONTACTS]: (state) => {
        state.contacts = {}
        state.localContacts = {}
        state.contactsMerged = {}
    },
    setRoleRevision(state, { rev }) {
        state.rev = rev
    },
    deleteContact: (state, {cid, type}) => {
        let localContact = getContactById(state.localContacts, cid)
        let globalContact = getContactById(state.contacts, cid)

        if (type === CONTACT_TYPE.LOCAL && localContact.cid) {
            Vue.delete(state.localContacts, cid)
            if (globalContact.cid) Vue.set(state.contactsMerged, cid, utils.cloneObject(state.contacts[cid]))
            else Vue.delete(state.contactsMerged, cid)
        } else if (type === CONTACT_TYPE.GLOBAL && globalContact.cid) {
            Vue.delete(state.contacts, cid)
            console.log("🚀 ~ file: contacts.js:956 ~ localContact:", localContact)
            console.log("🚀 ~ file: contacts.js:957 ~ state.localContacts[cid]:", state.localContacts[cid])
            if (localContact.cid) Vue.set(state.contactsMerged, cid, utils.cloneObject(state.localContacts[cid]))
            else delete Vue.delete(state.contactsMerged, cid)
        }
    },
    setBirthDaysList(state, contacts) {
        let cids = contacts.map(({cid}) => cid)
        Object.keys(state.birthdaysList).forEach((cid) => {
            if (!cids.includes(cid)) delete state.birthdaysList[cid]
        })
        for (let i = 0, count = contacts.length; i < count; i++) {
            let new_contact = contacts[i]
            Vue.set(state.birthdaysList, new_contact.cid, new_contact)
        }
    },
    setContactsStatuses: function (state, params) {
        let { cid, status = {}, pending } = params
        let { statusTime = -1 } = status
        if (!state.contactsStatuses[cid]) Vue.set(state.contactsStatuses, cid,  {})
        let contactStatus = state.contactsStatuses[cid]
        Vue.set(contactStatus, 'statusTime',  statusTime)
        if (pending) {
            Vue.set(contactStatus, 'pending',  pending)
            setTimeout(() => Vue.delete(contactStatus, 'pending'), 500)
        }
    },
    [MUT_SET_SUPPORT](state, data) {
        if(data && 'cid' in data) data.cid = Number(data.cid);
        state.support = data;
    },
    addContactShort(state, contact) {
        Vue.set(state.shortList, contact.cid, contact)
    },
    updateSearchedContact(state, contact) {
        Vue.set(state.searchedList, contact.cid, contact)
    },
};

function getContactById(contacts, cid) {
    let result = contacts[cid]
    if (!result) {
        result = {
            fio: locale['search-comp']['unknown-contact'],
            fields: [],
            cid,
            notFound: true
        };
    }
    return result;
}

function getChangedFields(globalFields, mergedFields) {
    return mergedFields.filter(mf => {
        return !globalFields.some(gf => {
            return mf.type === gf.type || mf.value === gf.value
        })
    })
}

function mergedFieldArrays(array1, array2) {
    let arr1 = Array.isArray(array1) && utils.cloneObject(array1);
    let arr2 = Array.isArray(array2) && utils.cloneObject(array2);

    let arr3 = [];

    if(!arr1 || arr1.length === 0) {
        arr3 = arr2;
    } else if(!arr2 || arr2.length === 0) {
        arr3 = arr1;
    } else {
        for(let i = 0; i < arr1.length; i++) {
            arr3.push(arr1[i]);
        }

        for(let i = 0; i < arr2.length; i++) {
            let type2 = arr2[i].type;
            let value2 = arr2[i].value;
            let name2 = arr2[i].name;
            let exist = false;

            if(MULTIPLE_CONTACT_FIELD_TYPES.indexOf(type2) > -1) {
                for(let i = 0; i < arr3.length; i++) {
                    if(arr3[i].type === type2 && arr3[i].name === name2 && arr3[i].value === value2) {
                        exist = true;
                        break;
                    }
                }
                if(!exist) arr3.push(arr2[i]);
            } else {
                for(let i = 0; i < arr3.length; i++) {
                    if(arr3[i].type === type2) {
                        arr3[i].value = value2
                        exist = true;
                        break;
                    }
                }
                if(!exist) arr3.push(arr2[i]);
            }
        }
    }
    return arr3;
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}