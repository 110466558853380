//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalBtn from "../../components/modal/ModalBtn.vue"
import modal_mixin from "../../components/modal/modal-mixin"
import CustomTextarea from "../custom/CustomTextarea.vue"
import InfoIcon from "../info/InfoIcon.vue"

import { mapGetters, mapActions, mapMutations } from "../../../vuex"
import { TETRA, INFO, PHONE_CNTL } from "../../store/modulesNames"
import { 
    GET_TETRA_SEARCHED_USERS, 
    GET_TETRA_ACTIVE_GROUP, 
    GET_TETRA_GROUP_USERS,
    GET_TETRA_USER_STATUS,
    GET_TETRA_MESSAGES,
    GET_TETRA_MESSAGE_STATUS,
    GET_TETRA_IS_CONNECTED,
} from "../../store/gettersTypes"
import {
    ACT_TETRA_SEARCH_USERS,
    ACT_TETRA_GET_GROUP_USERS,
    ACT_INFO_PUSH,
    ACT_TETRA_ADD_MESSAGE,
    ACT_TETRA_MESSAGE,
    ACT_TETRA_ADD_RECENT_USER,
    // ACT_TETRA_STORE_MESSAGES,
    // ACT_TETRA_RESTORE_MESSAGES,
    CALLS_CONTROLLER,
} from "../../store/actionsTypes"
import {
    MUT_TETRA_SET_OPENED_CHAT_SSI,
    MUT_TETRA_CLEAR_UNWATCHED_MESSAGES,
} from "../../store/mutationsTypes"

import { MSG } from "../../store/modules/tetra-radio"
import {PHONE_TYPES} from '../../constants'

const MAX_MSG_LENGTH = 127

import moment from 'moment'

export default {
    name: "TetraChat",
    props: {
        contact: {
            type: Object,
            default: null
        },
    },        
    components: { ModalBtn, CustomTextarea, InfoIcon },
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                delay: 350,
                pivotY: 0.25,
                width: 480,
                height: 'auto',
                clickToClose: true,
                adaptive: true,
                styles: "min-width: 300px; max-height: 80vh; overflowY: auto;"                    
            },
            _messages: {},
            chatMessages: {},
            inputMsg: '',
            alert: false,
            alert_msg: '',                
            focus: true,
            observer: null,
            isNewMessage: false,
        }
    },
    mounted() {
        if (this.contact) {
            this._messages = this._getMessages
            this.formChat()
            this[MUT_TETRA_CLEAR_UNWATCHED_MESSAGES](this.contactSSI)
            this[ACT_TETRA_ADD_RECENT_USER]({type: 'contact', ssi: this.contactSSI, name: this.contactName})
        }

        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('last-msg-animation')
                }
            })
        })

        this[MUT_TETRA_SET_OPENED_CHAT_SSI](this.contactSSI)

        this.$refs.modal.$on('closed', () => {
            this[MUT_TETRA_SET_OPENED_CHAT_SSI](0)
        })
    },
    beforeUnmount() {
        this[MUT_TETRA_SET_OPENED_CHAT_SSI](0)
    },
    asyncComputed: {
        inputTextLength() {
            return this.inputMsg && this.inputMsg.trim && this.inputMsg.trim().length || 0
        },
        inputTextIsSet() {
            return Boolean(this.inputTextLength)
        },            
        disableBtn() {
            let blocked = !(this.inputTextLength <= MAX_MSG_LENGTH && this.inputTextIsSet)
            blocked = blocked || !this.isTetraConnected
            return blocked
        },            
    },        
    computed: {
        _getMessages() {
            if (!this.contactSSI) return []
            return this[GET_TETRA_MESSAGES](this.contactSSI) || []
        },
        contactSSI() {
            return this.contact.ssi
        },
        contactName() {
            return this.contact.fio
        },
        cancelText() { return this.props.cancelText ||  this.$t('cancel') },
        okText() { return this.props.okText || this.$t('loginPage.ok') },
        status() {
            const ssiStatus = this[GET_TETRA_USER_STATUS](this.contactSSI)
            const langTetraUserStatus = this.$t('tetra-user-status')
            if (langTetraUserStatus[ssiStatus]) return langTetraUserStatus[ssiStatus]
            else setTimeout(() => { return langTetraUserStatus['unknown'] }, 1000 ) 
        },
        isChatMessages() {
            return Object.keys(this.chatMessages).length
        },
        isTetraConnected() {
            return this[GET_TETRA_IS_CONNECTED] || false
        },
        ...mapGetters(TETRA, [
            GET_TETRA_SEARCHED_USERS, 
            GET_TETRA_GROUP_USERS, 
            GET_TETRA_ACTIVE_GROUP, 
            GET_TETRA_USER_STATUS,
            GET_TETRA_MESSAGES,
            GET_TETRA_MESSAGE_STATUS,
            GET_TETRA_IS_CONNECTED,
        ])
    },
    watch: {
        _getMessages(val) {
            this._messages = val
            this.isNewMessage = true
            this.formChat()
            this.$nextTick(() => {
                let obsElem = document.querySelector('.lastSds')
                if (obsElem) this.observer.observe(obsElem)
                this.scrollChatToBottom()
            })
        },
        inputMsg(val) {
            console.log("🚀 ~ file: TetraChat.vue:207 ~ inputMsg ~ val:", val)
            if (val && val.length > MAX_MSG_LENGTH) {
                this.alert_msg = this.$t('chat.max-length-exceeded')
                this.alert = true
            } else if (this.alert) {
                this.alert = false
                this.alert_msg = ''
            }
        },
    },
    methods: {
        formChat() {
            let res = {}, sorted = {}
            this._messages.forEach(msg => {
                let m_time = moment(msg.time)
                let formatedDate = this.formatDate(msg.time)
                let time = m_time.format("HH:mm")
                if (!res[formatedDate]) res[formatedDate] = []
                res[formatedDate].push({direction: msg.direction, text: msg.text, time, status: msg.status})
            })
            let reversedKeys = Object.keys(res).sort()
            const compare = (a, b) => {
                if ( a.time < b.time ) return -1
                if ( a.time > b.time ) return 1
                return 0
            }
            reversedKeys.forEach((k, i) => {
                sorted[k] = res[k].sort(compare)
            })
            this.chatMessages = sorted
        },
        formatDate(time) {
            let result
            let u_time = moment(time)
            let today = moment().startOf('day')
            let yesterday = moment().subtract(1, 'days').startOf('day')
            if(u_time.isAfter(today)) {
                result = this.$t('settings.today')
            } else if(u_time.isBefore(today) && u_time.isAfter(yesterday)) {
                result = this.$t('channel-comp.yesterday')
            } else {
                result = u_time.format('DD MMM YYYY')
            }
            return result
        },
        focusInput() {
            const inputElem = this.$refs.inputMsg
            const cta = inputElem && inputElem.$refs.custom_input_textarea
            if (cta) cta.focus()
            this.scrollChatToBottom()
        },
        scrollChatToBottom() {
            setTimeout(() => { 
                let tetraChat = document.getElementById('tetra-chat')
                let chatBody = tetraChat && tetraChat.parentElement
                if (chatBody && !!chatBody.scrollHeight) {
                    chatBody.scrollTop = chatBody.scrollHeight 
                }
            }, 0)
        },
        async sendSSI() {
            if (this.disableBtn) return
            const ssi = this.contactSSI
            const type = MSG.SDS
            const text = this.inputMsg.replace(/<br>/g, '\n')
            const time = date_helper.getCurrentMsUnixTime()
            const parms = { ssi, text, type, time }
            setTimeout(()=> {this[ACT_TETRA_MESSAGE](parms)}, 0)
            const message = { direction: MSG.OUT, ssi, text, type, time, status: 1 }
            await this[ACT_TETRA_ADD_MESSAGE](message)
            this._messages = this[GET_TETRA_MESSAGES](this.contactSSI)
            this.formChat()
            this.$nextTick(() => { this.scrollChatToBottom() })
            this[ACT_TETRA_ADD_RECENT_USER]({type: 'msg', ssi: this.contactSSI, name: this.contactName})
            this.inputMsg = ''
            const inputElem = this.$refs.inputMsg
            if (inputElem) inputElem.reset()
        },
        showContextMenu(event, contact) {
            let handlers = [], open = this.cmOpen
            handlers.push({
                item_name: this.$t('settings.goto-profile'),
                handler: () => {
                    this[ACT_INFO_PUSH]({type: 'tetra-contact-info', params: {ssi: contact.ssi, 
                        name: contact.fio.trim(), note: contact.note, isGroup: false, canBack: false}})
                }
            })
            handlers.push({
                item_name: this.$t('tetra-duplex-call'),
                handler: () => {
                    this.makeCall()
                }
            })
            open(event, handlers)
        },
        getMsgClass(index, m, name, i) {
            let _class = []
            if (!m.status) _class.push({'not-sent': !m.status})
            if (this.isNewMessage &&
                index === Object.keys(this.chatMessages).length-1 &&
                i === this.chatMessages[name].length-1
                )  {
                    _class.push({'lastSds': true})
                }
            return _class
        },
        makeCall() {
            this[CALLS_CONTROLLER]({type: PHONE_TYPES.TETRA, data: { ssi: this.contactSSI }})
        },
         ...mapActions(TETRA, [
            ACT_TETRA_SEARCH_USERS,
            ACT_TETRA_GET_GROUP_USERS,
            ACT_TETRA_ADD_MESSAGE,
            ACT_TETRA_MESSAGE,
            ACT_TETRA_ADD_RECENT_USER,
        ]),
        ...mapActions(INFO, [ACT_INFO_PUSH]),
        ...mapActions(PHONE_CNTL, [CALLS_CONTROLLER]),
        ...mapMutations(TETRA, [MUT_TETRA_SET_OPENED_CHAT_SSI, MUT_TETRA_CLEAR_UNWATCHED_MESSAGES]),
    }
}
