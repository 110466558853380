var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.chat
    ? _c(
        "div",
        {
          staticClass: "main-header-info",
          attrs: { id: "group-main-header-info" }
        },
        [
          _c("div", { staticClass: "header-text" }, [
            _c(
              "div",
              {
                staticClass: "header-user-block",
                attrs: { title: _vm.getTitle },
                on: {
                  click: _vm.doToggleOpenInfo,
                  mouseover: function($event) {
                    return _vm.headerMouseOver($event)
                  }
                }
              },
              [
                _c("icon-image", {
                  attrs: { src: _vm.chat.photo, text: _vm.chat.name }
                }),
                _c(
                  "div",
                  { staticClass: "group-text-wrapper header-main" },
                  [
                    _c("div", { staticClass: "name" }, [
                      _c("span", { staticClass: "group-name" }, [
                        _vm._v(_vm._s(_vm.chat.name))
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.typing,
                            expression: "!typing"
                          }
                        ],
                        staticClass: "group-members sub-text"
                      },
                      [_vm._v(_vm._s(_vm.subText))]
                    ),
                    _c("typing-status", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.typing,
                          expression: "typing"
                        }
                      ],
                      attrs: { cid: _vm.cid, cidType: _vm.cidType }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "header-btns contact-buttons-block" }, [
            _c("div", {
              staticClass: "circle-animation header-chat-search btn-custom",
              attrs: { title: _vm.$t("search-in-chat") },
              on: { click: _vm.openSearchChat }
            }),
            _c(
              "div",
              {
                staticClass: "circle-animation btn-custom",
                attrs: { title: _vm.$t("info") },
                on: { click: _vm.doToggleOpenInfo }
              },
              [_c("i", { staticClass: "fal fa-window-maximize fa-rotate-90" })]
            ),
            _c(
              "div",
              {
                staticClass: "circle-animation btn-custom menu",
                attrs: { title: _vm.$t("mainPage.menu") },
                on: {
                  click: function($event) {
                    return _vm.doMenu($event, "bottom-left")
                  }
                }
              },
              [
                _c("svg", { attrs: { width: "10px", height: "24px" } }, [
                  _c("circle", { attrs: { cx: "5", cy: "5", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "12", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "19", r: "2" } })
                ])
              ]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }