'use strict';
import {MediaWorker, PeerConnection} from '../ext/webrtc';

import EventEmitter from "./common/EventEmitter"

var log = function(type, str) { //для webrtc.js
    console.log(str);
};

export const EVENTS = {
    CALL_ID_CHANGED: 'callIdChanged',
    STATE_CHANGED: 'stateChanged',
    RTC_CALL: 'rtcCall',
    RTC_CALL_ANSWER: 'rtcCallAnswer',
    RTC_CALL_RINGING: 'rtcCallRinging',
    RTC_CALL_TERMINATION: 'rtcCallTermination',
    RTC_CALL_OPTIONS_CHANGE: 'rtcCallOptionsChange',
    CALL_STATS: 'callStats',
}

const GATHER_STATS_INTERVAL = 5000

export const rtcCallStates = {
    rtcCallStateNull: 0,
    rtcCallStateInit: 1,
    rtcCallStateBusy: 2,
    rtcCallStateConnecting: 3,
    rtcCallStateIncoming: 4,
    rtcCallStateRinging: 5,
    rtcCallStateDialing: 6,
    rtcCallStateConnected: 7,
    rtcCallStateDisconnecting: 8,
    rtcCallStateRemoteDisconnected: 9,
    rtcCallStateDisconnected: 10,
    rtcCallStateHold: 11,
    rtcCallStateCencelHold: 12,
    rtcCallStateDisconnectedTimeOut: 13,
    rtcCallStateDisconnectedDnd: 14,
    rtcCallStateDisconnectedRemote: 15,
    rtcCallStateDisconnectedBadParams: 16,
    rtcCallStateDisconnectedNotFound: 17,
    rtcCallStateDisconnectedUnknown: 18,
    rtcCallStateDisconnectedFailed: 19,
    rtcCallStateDisconnectedLocal: 20,
    rtcCallStateConferenceRedirect: 21,
    rtcCallStateAccessDenied: 22,
};

export const rtcCallTypes = {
    rtcCallTypeAudio: 0,
    rtcCallTypeVideo: 1,
    rtcCallTypeSip:   2
};

//@todo убрать всю работу с store
export default class CallWorker extends EventEmitter {
    constructor(store, callId, plugText = '', iceServers) {
        super()
        let self = this,
            cid,
            number,
            tempId = callId,
            rtcCallId = tempId,
            rtcCallState,
            rtcCallType,
            rtcCallIncoming,
            rtcCallOptions,
            localCallData,
            remoteCallData,
            mediaWorker,
            peerConnection,
            videoPlugText = plugText,
            callAwaitedPayload
            // gatherStatsId

        // gatherStatsId = setInterval(() => {
        //     console.log('!! getting stats')
        //     getStats()
        // }, GATHER_STATS_INTERVAL)

        self.answer = function() {
            if(rtcCallType === rtcCallTypes.rtcCallTypeVideo) mediaWorker = new MediaWorker('#localVideo', '#remoteVideo', videoPlugText)
            else mediaWorker = new MediaWorker('#localAudio', '#remoteAudio')
            mediaWorker.startLocal(rtcCallOptions)

            setCallState(rtcCallStates.rtcCallStateConnecting)
            
            setTimeout(async () => {
                let offer = {type: 'offer', sdp: remoteCallData.sdp}
                let {sdp, candidates} = await createPC('answer', offer)
                localCallData = {sdp: sdp, candidates: candidates};
                for (let i = 0; i  < remoteCallData.candidates.length; i++) {
                    peerConnection.addCandidate(remoteCallData.candidates[i])
                }
                let data = {
                    callId: rtcCallId,
                    options: rtcCallOptions,
                    sdp: localCallData.sdp,
                    candidates: localCallData.candidates
                };
                self.emit(EVENTS.RTC_CALL_ANSWER, data)
            }, 1500)
        };

        self.call = async function(...payload) {
            if (callAwaitedPayload) payload = callAwaitedPayload
            let [cid, number, options] = payload
            let stream = null
            setCallState(rtcCallStates.rtcCallStateDialing)
            if (options.video) mediaWorker = new MediaWorker('#localVideo', '#remoteVideo', videoPlugText)
            else mediaWorker = new MediaWorker('#localAudio', '#remoteAudio')
            try {
                stream =  await mediaWorker.startLocal(options)
            } catch(e) {
                // console.error(e)
                throw new Error(e)
            }

            if(!cid) cid = 0;
            setTimeout(async () => {
                let { sdp, candidates } = await createPC('offer', null)
                let data = {
                    cid,
                    options,
                    number,
                    sdp,
                    candidates,
                    rtcCallId,
                }
                self.emit(EVENTS.RTC_CALL, data)
            }, 1500)
            return stream
        }

        self.onCallResult = (error, result = {}) => {
            if (error) {
                console.log('callWorker onCallResult error: ', error.error)
            } else {
                let { callId, options, cid, number, sdp, candidates } = result
                rtcCallIncoming = false
                setRtcCallId(callId, options, cid, number)
                localCallData = {sdp, candidates}
            }
        }

        self.called = function(cid, callId, options, number, sdp, candidates) {
            console.log('called: cid = ' + cid + ', number = ' + number + ', callId = ' + callId + ', options = ' + JSON.stringify(options))
            self.emit(EVENTS.RTC_CALL_RINGING, callId)
            remoteCallData = {sdp: sdp, candidates: candidates}
            rtcCallIncoming = true
            setRtcCallId(callId, options, cid, number)
            setCallState(rtcCallStates.rtcCallStateIncoming)
        }

        self.callTerminate = function() {
            if (rtcCallId){
                self.emit(EVENTS.RTC_CALL_TERMINATION, {callId: rtcCallId})
            }
            setCallState(rtcCallStates.rtcCallStateDisconnected);
            rtcCallTerminate();
        };

        self.callRinged = function(callId) {
            if (rtcCallId === callId) {
                setCallState(rtcCallStates.rtcCallStateRinging);
            }
        };

        self.callAnswered = function(callId, options, sdp, candidates) {
            setCallState(rtcCallStates.rtcCallStateConnecting);
            if (rtcCallId === callId) {
                remoteCallData = {sdp: sdp, candidates: candidates};
                var answer = {type: 'answer', sdp: sdp};
                peerConnection.setAnswer(answer);
                for (var j = 0; j  < remoteCallData.candidates.length; j++) {
                    peerConnection.addCandidate(remoteCallData.candidates[j]);
                }

            }
        };

        self.callTerminated = function(callId, reason) {
            if (rtcCallId == callId) {
                switch (reason) {
                    case 'timeout':
                        setCallState(rtcCallStates.rtcCallStateDisconnectedTimeOut)
                        break
                    case 'dnd':
                        setCallState(rtcCallStates.rtcCallStateDisconnectedDnd)
                        break
                    case 'remote':
                        setCallState(rtcCallStates.rtcCallStateDisconnectedRemote)
                        break
                    case 'bad-params':
                        setCallState(rtcCallStates.rtcCallStateDisconnectedBadParams)
                        break
                    case 'not-found':
                        setCallState(rtcCallStates.rtcCallStateDisconnectedNotFound)
                        break
                    case 'unknown':
                        setCallState(rtcCallStates.rtcCallStateDisconnectedUnknown)
                        break
                    case 'failed':
                        setCallState(rtcCallStates.rtcCallStateDisconnectedFailed)
                        break
                    case 'local':
                        setCallState(rtcCallStates.rtcCallStateDisconnectedLocal)
                        break
                    case 'conference-redirect':
                        setCallState(rtcCallStates.rtcCallStateConferenceRedirect)
                        break
                    case 'access-denied':
                        setCallState(rtcCallStates.rtcCallStateAccessDenied)
                        break
                    default:
                        setCallState(rtcCallStates.rtcCallStateRemoteDisconnected)
                        break                        
                }
                rtcCallTerminate();
            }
        };

        self.callHold = function(value, remote = true) {
            if (!mediaWorker) return
            let isVideo = (rtcCallType === rtcCallTypes.rtcCallTypeVideo);
            mediaWorker.hold(value, isVideo);
            if (remote) {
                let isHold = mediaWorker.getIsHold();
                if (isHold) setCallState(rtcCallStates.rtcCallStateHold);
                else setCallState(rtcCallStates.rtcCallStateCencelHold);
            }
        };

        self.getCallState = function() { return rtcCallState? rtcCallState : rtcCallStates.rtcCallStateNull }

        self.getCallType = function() { return rtcCallType; };

        self.getCallId = function() {
            return rtcCallId
        };

        self.getCid = function() {
            return cid
        };

        self.getNumber = function () {
            return number
        };

        self.setCallId = (callId) => {
            let oldId = rtcCallId
            if (rtcCallId) tempId = rtcCallId
            rtcCallId = callId
            self.emit(EVENTS.CALL_ID_CHANGED, {oldId, newId: rtcCallId})
        };

        self.mediaWorkerMute = function() {
            if (!mediaWorker) return false
            return mediaWorker.mute();
        };

        self.pause = function(value) {
            let options;
            if (value) options = {audio: true, video: false, hold: true};
            else options = { audio: true, video: false, hold: false};
            self.callHold(value, false)
            self.emit(EVENTS.RTC_CALL_OPTIONS_CHANGE, {callId: rtcCallId, options})
        };

        self.updateStreams = function() {
            if (!mediaWorker) return
            if(rtcCallType === rtcCallTypes.rtcCallTypeVideo) mediaWorker.updateStreams('#localVideo', '#remoteVideo');
            else mediaWorker.updateStreams('#localAudio', '#remoteAudio');
        };

        self.changeVolumeEvent = function(volume) {
            if (!mediaWorker) return
            mediaWorker.changeVolumeEvent(volume);
        };

        self.addRemoteIceCandidate = function(candidate) {
            peerConnection && peerConnection.addCandidate(candidate)
        };

        self.setCallFreeAwaiting = (...payload) => {
            callAwaitedPayload = payload
            setCallState(rtcCallStates.rtcCallStateBusy)
        }

        const getStats = () => { 
            // if (!mediaWorker) return
            // let stats = peerConnection && peerConnection.getCallStats && peerConnection.getCallStats()
            // if (!stats) return
            // stats.callId = rtcCallId
            // stats.peerMuted = mediaWorker.muted
            // stats.peerHold = mediaWorker.getIsHold()
            // stats.peerRemoteHold = rtcCallState === rtcCallStates.rtcCallStateHold
            // self.emit(EVENTS.CALL_STATS, stats)
        }

        function setRtcCallId(id, options, cidA, numberA) {
            cid = cidA;
            number = numberA;
            self.setCallId(id)
            //rtcCallId = id;
            rtcCallOptions = options;

            if (rtcCallId) {
                if(rtcCallOptions.video){
                    rtcCallType = rtcCallTypes.rtcCallTypeVideo;
                } else {
                    if(!number){
                        rtcCallType = rtcCallTypes.rtcCallTypeAudio;
                    } else {
                        rtcCallType = rtcCallTypes.rtcCallTypeSip;
                    }
                }
            } else {
                if (mediaWorker) {
                    mediaWorker.stop();
                    mediaWorker = null;
                }
                cid = null;
                number = null;
                rtcCallState = null;
                rtcCallType = null;
                localCallData = null;
                remoteCallData = null;
            }
        }

        function createPC(type, setOffer) {
            return new Promise((resolve) => {
                let candidates = []
                let sdp
                if (!mediaWorker) { 
                    resolve({sdp, candidates})
                }
                peerConnection = new PeerConnection(iceServers, mediaWorker.getLocalStream(),
                    (candidate) => {
                        if (candidate) candidates.push(candidate)
                    },
                    (data) => {
                        if (data.connectingState) setCallState(rtcCallStates.rtcCallStateConnected)
                        else if (data.stream) mediaWorker.setRemoteStream(data.stream)
                    })
                switch (type) {
                    case 'offer':
                        peerConnection.getOffer((offer) => { if (offer && offer.sdp) sdp = offer.sdp })
                        break;
                    case 'answer':
                        peerConnection.getAnswer(setOffer, (answer) => { if(answer && answer.sdp) sdp = answer.sdp })
                        break;

                }

                setTimeout(() => {
                    resolve({sdp, candidates})
                }, 500)
            })
        }

        function destroyPC() {
            if (peerConnection) {
                peerConnection.close();
                peerConnection = null;
            }
        }

        function rtcCallTerminate() {
            // clearInterval(gatherStatsId)
            destroyPC();
            setRtcCallId(null);
        }

        function setCallState(state) {
            rtcCallState = state;
            self.emit(EVENTS.STATE_CHANGED, {callId: rtcCallId || tempId, cid, number, state, rtcCallType})
            //store.dispatch(`${PHONE}/${ACT_PHONE_CHANGE_STATE}`, {rtcCallId: rtcCallId || tempId, cid, number, state, rtcCallType});
            //self.emit('change-call-state', {rtcCallId, cid, number, state});
        }  
    }
};

