//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex"
import { RADIO, USERDATA } from "../../store/modulesNames"
import { GET_RADIO_SETTINGS, GET_VOLUME_RADIO } from "../../store/gettersTypes"

import CustomAudio from "../custom/CustomAudio.vue"

export const SIGNALS = {
    NOT_GRANTED:        0,
    GRANTED:            1,
    REQUEST_QUEUED:     2,
    TO_ANOTHER_USER:    3,
    INTERRUPT:          4,
    CEASING:            5,
    RELEASE:            6,
}

export default {
    name: "RadioSignal",
    components: {CustomAudio},
    watch: {
        signal() {
            if (this.skipNext) this.skipNext = false
        },
        signalsSound(val) {
            if (val) this.skipNext = true
        },
    },
    data() {
      return {
          skipNext: false
      }
    },
    computed: {
        settings() {
            return this[GET_RADIO_SETTINGS] || {}
        },
        radioVolume() {
            return this.$store.getters[`${USERDATA}/${GET_VOLUME_RADIO}`] 
        },
        signalsSound() {
            return this.settings.signalsSound
        },
        signals: () => -1,
        audioRepeat:() => false,
        audioFile() {
            let audioFile
            if (!this.signalsSound || this.skipNext) return
            switch (this.signal) {
                case SIGNALS.RELEASE:
                    audioFile = 'radio_call_release.mp3'
                    break
                case SIGNALS.GRANTED:
                    audioFile = 'radio_grant_ptt.mp3'
                    break
                case SIGNALS.NOT_GRANTED:
                    audioFile = 'radio_grant_nptt.mp3'
                    break
                case SIGNALS.REQUEST_QUEUED:
                    audioFile = 'radio_grant_queue.mp3'
                    break
                case SIGNALS.TO_ANOTHER_USER:
                    audioFile = 'radio_grant_other.mp3'
                    break
                case SIGNALS.INTERRUPT:
                    audioFile = 'radio_grant_queue.mp3'
                    break
                case SIGNALS.CEASING:
                    audioFile = 'radio_grant_cease.mp3'
                    break
            }
            if (audioFile) audioFile = `audio/${audioFile}`
            this.$refs.audio && (this.$refs.audio.volume = this.radioVolume)
            return audioFile
        },
        ...mapGetters(RADIO, [GET_RADIO_SETTINGS])
    },
}
