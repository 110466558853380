//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import {
    GET_SELECTED_CHAT,
    GET_BOT_BY_ID,
    GET_CSS_PROPERTY_SUPPORTED,
    GET_SERVER_API,
    GET_MERGED_CONTACT_BY_ID,
    IS_CHAT_ADMIN,
    GET_SELECTED_MSGS,
    GET_UID,
    GET_MAIN_TYPE,
    GET_MESSAGE_BY_ID,
    GET_COMMENT_ID,
    GET_COMMENT_BY_ID,
    GET_INFO_OPEN,
} from '../../store/gettersTypes'
import {
    ACT_INFO_PUSH,
    ACT_CHAT_UPDATE_REPLY,
    ACT_CHAT_UPDATE_EDITED,
    CALLS_CONTROLLER,
    ACT_CHANGE_CHAT,
    ACT_CHAT_SET_MSG_ENTITIES,
    ACT_SCHEDULE_RESPONSE_TO_INVITATION,
    ACT_PUSH_MAIN_TYPE,
    ACT_SET_SELECTED_MSGS,
    ACT_CHAT_SET_MSG_REACTION,
    ACT_CHAT_GET_MESSAGES,
    ACT_SET_CURRENT_COMMENTS,
    ACT_SET_MSG_COMMENT_SUBSCRIBED,
} from '../../store/actionsTypes'
import {
    CHATS,
    INFO,
    BOTS,
    CLIENTDATA,
    CHAT,
    PHONE_CNTL,
    LOGIN,
    CONTACTS,
    INTEGRATIONS,
    CONTENT_MANAGER,
    USERDATA,
} from '../../store/modulesNames'
import SelectChatToForward from  '../modal/SelectChatToForward.vue'
import ChatAudioMessage from './ChatAudioMessage.vue'
import ChatChannelMessage from './ChatChannelMessage.vue'
import ChatContactMessage from './ChatContactMessage.vue'
import ChatGeoMessage from './ChatGeoMessage.vue'
import ChatGroupedMessage from './ChatGroupedMessage.vue'
import ChatImageMessage from './ChatImageMessage.vue'
import ChatRegularFileMessage from './ChatRegularFileMessage.vue'
import ChatReplyMessage from './ChatReplyMessage.vue'
import ChatVideoMessage from './ChatVideoMessage.vue'
import ChatPollMessage from '../chatPollMessage.vue'
import ChatPublicationMessage from './ChatPublicationMessage.vue'
import ReferenceMsg from './ReferenceMsg.vue'
import BotKeyboardMsg from '../bots/BotKeyboardMsg.vue'
import ChatCallAvailabilityMessage from './ChatCallAvailabilityMessage.vue'
import ChatSurveyMessage from '../chatSurveyMessage.vue'
import ChatScheduleMessage from '../chatScheduleMessage.vue'
import ChatMessageMixin from "./chat-message-mixin"
import EnterConf from '../videoConfNewManagment/enterConference.vue'
import DelChatMessage from "../modal/DelChatMessage.vue"
import CheckBox from "../custom/CustomCheckboxRound.vue"
import ForwardMultipleMessages from '../modal/SelectChatToForwardMessages.vue'
import DeleteMultipleMessages from '../modal/DeleteMultipleMessages.vue'
import Reactions from './reactions/Reactions.vue'
import ReactionsPicker from './reactions/ReactionsPicker.vue'
import ChatThreads from './ChatThreads.vue'

import { DLP_SOURCE_TYPES } from '../../store/modules/dlp'
import { MAIN_TYPES } from '../../store/modules/content-manager'
import { INFO_TYPES } from '../../store/modules/info'
import {CONTACT_FIELD_TYPES, ENTITIES} from '../../constants'

export default {
    name: 'chat-message',
    mixins: [ChatMessageMixin],
    components: {
        'chat-contact-message': ChatContactMessage,
        'chat-geo-message': ChatGeoMessage,
        ChatGroupedMessage,
        'chat-image-message': ChatImageMessage,
        'chat-video-message': ChatVideoMessage,
        'chat-audio-message': ChatAudioMessage,
        'chat-regular-file-message': ChatRegularFileMessage,
        'chat-channel-message': ChatChannelMessage,
        'chat-reply-message': ChatReplyMessage,
        'chat-poll-message': ChatPollMessage,
        'chat-publication-message': ChatPublicationMessage,
        'bot-keyboard-msg': BotKeyboardMsg,
        'chat-call-availability-message': ChatCallAvailabilityMessage,
        'reference-msg': ReferenceMsg,
        ChatSurveyMessage,
        ChatScheduleMessage,
        CheckBox,
        ForwardMultipleMessages,
        DeleteMultipleMessages,
        Reactions,
        ReactionsPicker,
        ChatThreads,
    },
    props: [
        'message', 'cid', 'type', 
        'withAnimation', 'maximagesize', 
        'isSelectMode', 'isThreads', 'isThreadsComment',
    ],
    data() {
        return {
            displayStyle: 'block',
            shortMsg: true,
            animation: this.withAnimation,
            maxHeight: 0,
            withText: false,
            maxCharsWithShortMessage: 1000,
            secondContextMenuHandlers: [],
            isSurveyOpened: false,
            isSelectModeOn: false,
            isSelected: false,
            selectedMessages: [],
            isOverMsgFooter: false,
            isLeavingMsgFooter: false,
            isOverMsgComments: false,
            isOverReactionPopup: false,
            reactionPickerStyle: {},
            currentFooterRect: {},
            timerOverFooter: null,
            timerLeaveFooter: null,
            urlPreview: {},
            isFirstComment: false,
        }
    },
    async beforeMount() {
        if (this.message.type !== 'system' && this.getUrlEntity(this.message.msg, this.message.entities)) {
            let result = await this.getUrlPreview(this.message.msg, this.message.entities)
            this.urlPreview = result                
        }
    },
    mounted () {
        if (!this.animation) return
        this.$nextTick(() => {
            this.maxHeight = this.$el.scrollHeight
            let nodeWithImg = this.$el.querySelector('.chat-image-message, .chat-video-message, .chat-channel-message, .chat-publication-message')
            if (nodeWithImg) nodeWithImg = nodeWithImg.querySelector('img')
            if (nodeWithImg && nodeWithImg.scrollHeight === 0) this.maxHeight += 250
            
            setTimeout(() => {
                this.animation = false
            }, 300)

            if (this.maxHeight) this.$nextTick(() => this.$el.classList.add('animation'))
        })
    },
    watch: {
        [GET_SELECTED_MSGS](val) {
            this.selectedMessages = val
        },
        comments(newVal, oldVal) {
            if (!oldVal && newVal === 1) this.isFirstComment = true
        },
    },
    computed: {
        ...mapGetters(LOGIN, [GET_SERVER_API]),
        ...mapGetters(CHAT, [GET_SELECTED_MSGS, GET_MESSAGE_BY_ID, GET_COMMENT_BY_ID, GET_COMMENT_ID]),
        ...mapGetters(CHATS, [GET_SELECTED_CHAT, IS_CHAT_ADMIN]),
        ...mapGetters(BOTS, [GET_BOT_BY_ID]),
        ...mapGetters(CLIENTDATA, [GET_CSS_PROPERTY_SUPPORTED]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
        ...mapGetters(CONTENT_MANAGER, [GET_MAIN_TYPE]),
        ...mapGetters(INFO, [GET_INFO_OPEN]),
        overflowAnchorSupported() { return this[GET_CSS_PROPERTY_SUPPORTED]('overflowAnchor')},
        is_member() {
            let member = false
            try {
                let cid = this.$store.state.chat.cid
                let chats = this.$store.state.chats.chats
                let uid = this.$store.getters['userdata/getUid'];
                let contacts

                if (this[GET_SELECTED_CHAT].cidType === 'group') {
                    for (let i in chats) {
                        if (chats[i].cid === cid && chats[i].cidType === 'group') {
                            contacts = chats[i].contacts
                            break
                        }
                    }
                    for (let i in contacts) {
                        if (contacts[i].cid === uid) {
                            member = true
                            break
                        }
                    }
                } else {
                    member = true
                }
            } catch (e) {
                console.log(e)
            }
            return member
        },
        bot() {
            return this.message.cidType === 'user' && this[GET_BOT_BY_ID](this.cid)
        },
        contact() {
            if (this.cid === 0) return this.$store.getters['chats/getChat']({ cid: this.cid, cidType: 'user' })
            else if (this.message.cidType === 'group') {
                return this[GET_MERGED_CONTACT_BY_ID](this.message.senderId)
            } else if (this.message.cidType === 'user') {
                if (!this.isThreads) return this[GET_MERGED_CONTACT_BY_ID](this.cid)
                else return this[GET_MERGED_CONTACT_BY_ID](this.message.senderId)
            }
            else return {}
        },
        name() {
            return (this.bot ? this.bot[CONTACT_FIELD_TYPES.BOTTITLE] : this.contact.fio) || ''
        },
        nameSignature() {
            if (this.message.cidType === 'group' && !this.message.ownMsg && (this.message.seriesMessagesStart || this.type === 'detail-msg'))
                return this.name
        },
        side() {
            return this.message.type === 'system' ? 'center' : ((this.message.ownMsg ? 'right' : 'left'))
        },
        photo() {
            if (this.message.seriesMessagesEnd && !this.message.ownMsg && this.message.type !== 'system' && this.type !== 'detail-msg') {
                return this.bot ? this.bot.photo : this.contact.photo
            }
        },
        messageMouthRight () {
            if(this.message.type === 'system') return false
            return (this.message.ownMsg && (!!this.message.msg.text || this.message.seriesMessagesEnd)) || (this.type === 'detail-msg' && this.side === 'right')
        },
        msgPosition() {
            if(this.type === 'detail-msg' && this.side === 'right') return {'msg-right': true};
            else if(this.type === 'detail-msg' && this.side === 'left') return {'msg-left': true};
        },
        marginLeftMsg() {
            if(this.type === 'detail-msg') return '0px';
            if(this.isThreads) return '8px';
            if(!this.message.ownMsg && this.message.type !== 'system' && !this.photo) return '44px'
            else return '12px';
        },
        isUrlPreview() {
            return Object.keys(this.urlPreview || {}).length && this.urlPreview.title && this.urlPreview.image
        },
        classes() {
            let classes = []
            if (this.message.type === 'system') classes.push('system-message')
            if (this.message.sub_type === 'time') classes.push('time')
            if (this.message.type === 'data') classes.push('data-message')
            if (this.message.type === 'time') classes.push('time-message')

            if (this.type === 'detail-msg') classes.push('detail-msg')
            if (this.message.type !== 'system') {
                if (this.message.seriesMessagesStart) classes.push('series-message-start')
                if (this.message.seriesMessagesEnd) classes.push('series-message-end')
                if (!this.message.seriesMessagesStart) classes.push('series-message')
                const indexSelectMode = classes.indexOf('select-mode')
                const indexSelected = classes.indexOf('selected-message')
                if (this.isSelectMode) {
                    if (indexSelectMode === -1) classes.push('select-mode')
                    if (this.isMessageSelected(this.message.id)) classes.push('selected-message')
                    else if (indexSelected > -1) classes.splice(indexSelected, 1)
                } else {
                    if (indexSelectMode > -1) classes.splice(indexSelectMode, 1)
                    if (indexSelected > -1) classes.splice(indexSelected, 1)
                }
            }
            classes.push(`side-${this.side}`)
            if (this.animation) {
                classes.push('last')
                if (this.overflowAnchorSupported)  classes.push('height-change')
            }
            if (this.message.edited) classes.push('edited')
            if (this.isOverMsgComments || (this.isOpenedComments && this.message.comments)) classes.push('active-comments')
            return classes
        },
        txtMsgClasses() {
            const classes = {}
            if (this.message.type === 'system') classes['system-text'] = true
            if (this.isUrlPreview) classes['with-url-preview'] = true
            return classes
        },
        msgFooterClasses() {
            const classes = {}
            if (this.message.sub_type === 'survey') classes['survey-time'] = true 
            else classes['with-indent'] = this.withText
            if (this.message.reactions && this.message.reactions.length) classes['with-reactions'] = true
            if (this.message.edited) classes['edited'] = true
            if (this.message.sub_type === 'location') classes['location'] = true
            if (this.message.ownMsg && !this.message.isNote && !this.isThreads) classes['with-status'] = true
            return classes
        },
        style() {
            const msg = this.message.msg
            const styles = (this.animation && this.maxHeight) ? {'--max-height': `${this.maxHeight}px`} : {}
            if (!['image','video'].includes(this.message.sub_type)) 
            Object.assign(styles, {'--margin-caption-top': '0.5rem'})
            Object.assign(styles, {'--margin-caption-bottom': '0.75rem'})

            if (!['image', 'video', 'file'].includes(this.message.sub_type)) {
                if (typeof msg === 'string') {
                    let _len = msg.length < 20 ? msg.length + 1 : msg.length - 6
                    if (_len < 10) _len = 10
                    Object.assign(styles, {'--msg-length': _len + 'em'})
                }
                return styles
            }
            const defaultSideSize = 300
            const availHeight = window.screen.availHeight * 0.33
            let maxWidth = this.maximagesize && Math.min(availHeight, this.maximagesize.width * Math.min(this.maximagesize.height / this.maximagesize.width, 0.6)) || defaultSideSize
            if (maxWidth < defaultSideSize) maxWidth = defaultSideSize
            Object.assign(styles, { '--text-max-width': maxWidth + 'px' })
            if (!msg.previewSize || !(msg.previewSize.w && msg.previewSize.h) && !this.isThreads) {
                Object.assign(styles, {
                    '--preview-block-max-width': `${defaultSideSize}px`,
                    '--preview-block-max-height': `${defaultSideSize}px`,
                    '--image-max-width': '100%',
                    '--image-max-height': '100%',
                })
            } else {
                let width
                let height
                const minSide = 100
                let maxHeight = this.maximagesize && availHeight || defaultSideSize
                if (maxHeight < defaultSideSize) maxHeight = defaultSideSize
                const previewWidth = msg.previewSize && msg.previewSize.w || defaultSideSize
                const previewHeight = msg.previewSize && msg.previewSize.h || defaultSideSize
                const widthProportion = previewWidth / maxWidth
                const heightProportion = previewHeight / maxHeight
                if (previewWidth > maxWidth || previewHeight > maxHeight) {
                    if (widthProportion > heightProportion) {
                        width = maxWidth
                        height = maxWidth * previewHeight / previewWidth
                    } else if (widthProportion < heightProportion) {
                        width = maxHeight * previewWidth / previewHeight
                        height = maxHeight
                    } else {
                        width = maxWidth
                        height = maxHeight
                    }
                } else if (previewWidth < minSide && previewHeight < minSide) {
                    if (previewWidth > previewHeight) {
                        width = minSide
                        height = minSide * previewHeight / previewWidth
                    } else if (previewHeight > previewWidth) {
                        width = minSide * previewWidth / previewHeight
                        height = minSide
                    } else {
                        width = minSide
                        height = minSide
                    }
                } else {
                    width = previewWidth
                    height = previewHeight
                }
                let groupWidth = width / 2 < 140 ? 140 : width / 2
                if (width < 220) Object.assign(styles, { '--min-msg-width': '220px' })
                Object.assign(styles, {
                    '--image-width': width + 'px',
                    '--image-height': height + 'px',
                })
                if (this.message && this.message.fileGroupId) {
                    Object.assign(styles, {
                        '--group-image-width': groupWidth + 'px',
                        '--text-max-width': (2*groupWidth - 8) + 'px'
                    })
                }
                if (this.isThreads) {
                    Object.assign(styles, {
                        '--image-max-width': '100%',
                        '--image-max-height': '100%',
                    })
                }
            }
            return styles
        },
        messageWrapperClasses() {
            return {
                'message-mouth-left-detail': this.type === 'detail-msg' && this.side === 'left',
                'message-mouth-right': this.messageMouthRight,
                ...this.msgPosition
            }
        },
        isSelectedMsgs() {
            return this.selectedMessages.length
        },
        isChecked() {
            return this.isMessageSelected(this.message.id)
        },
        textIndentClasses() {
            let indentClass = 'text-indent'
            if (this.message.edited) {
                indentClass += ` edited-${this.$i18n.locale}`
            }
            return indentClass
        },
        readOnly() {
            return this.message && this.message.msg && this.message.msg.readOnly
        },
        comments() {
            return this.message.hasOwnProperty('comments') ? this.message.comments : 0
        },
        isOpenedComments() {
            const infoParams = this[GET_INFO_OPEN] && this[GET_INFO_OPEN].params || {}
            const commentId = infoParams && infoParams.hasOwnProperty('commentId') && infoParams.commentId
            return this[GET_INFO_OPEN] && commentId && this.message.id === this[GET_COMMENT_ID]
        },            
    },
    methods: {
        textContinue() {
            return this.shortMsg && this.msgTextLength > this.maxCharsWithShortMessage;
        },
        msgTextHtml(text, entities) {
            if (!text) return ''
            text = text.replace(/</g,'\x7F').replace(/>/g,'\x8F')
            if (entities) {
                text = this.messageTextFormat(entities, text)
            } else {
                text = this.lookForLinks(text)
            }
            let imgEmojisArr = this.getEmojiNative(text)
            let emoCount = imgEmojisArr ? imgEmojisArr.length : 0

            let parser = new DOMParser()
            let dom = parser.parseFromString(text, 'text/html')
            let textLength = dom.body.textContent.length
            if (emoCount && textLength === 2*emoCount) {
                text = this.wrapEmoji(`<span>` + text + `<\span>`)
                text = this.setEmojisSpecialStyles(text)
            }
            else text = this.wrapEmoji(text)
            this.msgTextLength = textLength + emoCount
            if (this.textContinue()) {
                text = this.getSlicedText(dom) + '<span>...</span>'
            }

            if (!this.textContinue()) {
                const indentSpan = `<span ${this.$options._scopeId} class="${this.textIndentClasses}"></span>`
                text += indentSpan
            }
            this.withText = true
            text = text.replace(/\x7F/g,'&lt;').replace(/\x8F/g,'&gt;')
            return text
        },
        setEmojisSpecialStyles(text) {
            let dom = new DOMParser().parseFromString(text, 'text/html')
            let emos = dom.querySelectorAll('.emoji.emojibgrd')
            const emoCount = emos.length
            let span = dom.getElementsByTagName("span")[0]
            emos.forEach((emo, index) => {
                let emoStyle = emo.style
                emoStyle.verticalAlign = "middle"
                if (emoCount === 1) {
                    emoStyle.transform = "scale(2)"
                    span.style = `line-height: 2.6em;`
                    emoStyle.marginLeft = "0.6em"
                    emoStyle.marginRight = "0.4em"
                }
                if (emoCount === 2) {
                    emoStyle.transform = "scale(1.6)"
                    span.style = `line-height: 2.2em;`
                    emoStyle.marginLeft = "0.2em"
                    if (index > 0) {
                        emoStyle.marginLeft = "1em"
                        emoStyle.marginRight = "0.1em"
                    }
                }
                if (emoCount === 3) {
                    emoStyle.transform = "scale(1.3)"
                    span.style = `line-height: 1.8em;`
                    emoStyle.marginLeft = "0.2em"
                    if (index > 0) emoStyle.marginLeft = "0.6em" 
                }
            });
            return dom.body.innerHTML
        },
        checkReference (text, textEntities = []) {
            if (text && text.length) {
                const isOldRefFormat = text.search(/^@\[|\s@\[/) > -1
                let isNewRefFormat = false
                if (textEntities) {
                    isNewRefFormat = textEntities.some && textEntities.some(ent => ent.hasOwnProperty('contactId') || ent.hasOwnProperty('channelId') || ent.hasOwnProperty('botId')|| ent.hasOwnProperty('botCommand'))
                }

                if (isOldRefFormat || isNewRefFormat) {
                    this.withText = true
                    return true
                }
                return false
            }
        },
        captionHtml(message) {
            let msg
            if (message.fileGroupId && message.startFileGroup) {
                msg = message.groupMsgs[message.groupMsgs.length -1]
            } else if (!message.fileGroupId) msg = message.msg
            let text = msg && msg.text || ''
            let _entities = message.entities || []
            if (!text) return ''
            let _outText = ''
            if (_entities.length) {
                _outText = text
            } else {
                const { outText, entities} = this.extractInputTextFormat(text)
                _outText = outText
                _entities = entities
            }
            return this.wrapEmoji(this.messageTextFormat(_entities, _outText))
        },            
        getUrlEntity(text, textEntities = []) {
            if (text && text.length) {
                if (textEntities) {
                    const urlEnt = textEntities.find && textEntities.find(ent => ent.hasOwnProperty('type') && ent.type === ENTITIES.URL)
                    return urlEnt
                }
                return false
            } else return false
        },
        async getUrlPreview(text, entities) {
            if (!text) return
            const urlEnt = this.getUrlEntity(text, entities)
            if (!urlEnt) return
            let { offset, length } = urlEnt
            const url = text.substr(offset, length)
            let urlPreviewBlock = await this.getUrlPreviewBlock(url)
            return urlPreviewBlock
        },
        getSelected(id) {
            return Boolean(this.isMessageSelected(id))
        },
        onSelectMessage(message, _val, isCheck = false) {
            let val = _val
            const id = message.id, isGroupMsgs = message.hasOwnProperty('groupMsgs')
            if (isCheck) {
                _val.preventDefault()
                val = !this.getSelected(id)
            }
            const isExist = this.selectedMessages.includes(id)
            if (!isExist && val) {
                this.$nextTick(() => {
                    this.$emit('setselectmode', true)
                })
                if (isGroupMsgs) {
                    for (let index = 0; index < message.groupMsgs.length; index++) {
                        const msg = message.groupMsgs[index]
                        this.selectedMessages.push(msg.msgId) 
                    }
                } else this.selectedMessages.push(id)
                this.isSelected = true
            }
            if (isExist && !val) {
                if (isGroupMsgs) {
                    for (let index = 0; index < message.groupMsgs.length; index++) {
                        let index = this.selectedMessages.indexOf(id)
                        this.selectedMessages.splice(index, 1)                        
                    }
                } else {
                    let index = this.selectedMessages.indexOf(id)
                    this.selectedMessages.splice(index, 1)
                }
                this.isSelected = false
            }
            this[ACT_SET_SELECTED_MSGS](this.selectedMessages)
        },
        isMessageSelected(id) {
            return Boolean(this.selectedMessages.length && this.selectedMessages.indexOf(id) > -1)
        },
        fioById(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
        },
        getDateStamp(message) {
            if (message.dateTime) return message.dateTime;
        },
        async openLinkContextMenu(e) {
            if (navigator.platform.toUpperCase().indexOf('MAC')>=0) await (m => new Promise((resolve) => setTimeout(resolve, m)))(100)
            let { event, link } = e.detail
            let handlers = []
            let open = this.cmOpen
            handlers.push({ item_name: this.$t('open-link'), handler: this.openUrlLink, data: link })
            handlers.push({ item_name: this.$t('copy-link'), handler: this.copyToClipboard, data: link })
            open(event, handlers, 'right-bottom')
        },
        openUrlLink(link) {
            window.openLink(link)
        },
        openSurvey(isSurveyOpened) {
            this.isSurveyOpened = isSurveyOpened
        },
        async openEmailContextMenu(e) {
            if (navigator.platform.toUpperCase().indexOf('MAC')>=0) await (m => new Promise((resolve) => setTimeout(resolve, m)))(100)
            let { event, email } = e.detail
            let handlers = []
            let open = this.cmOpen
            handlers.push({ item_name: this.$t('mainPage.write-email'), handler: window.doEmail, data: email })
            handlers.push({ item_name: this.$t('mainPage.copy-email'), handler: this.copyToClipboard, data: email })
            open(event, handlers, 'right-bottom')
        },
        async openVCContextMenu(e) {
            if (navigator.platform.toUpperCase().indexOf('MAC')>=0) await (m => new Promise((resolve) => setTimeout(resolve, m)))(100)
            let { event, type, vcLink } = e.detail
            let handlers = []
            let open = this.cmOpen
            handlers.push({ item_name: this.$t('videomost.join'), handler: this.joinVideoConf, data: {type, vcLink} })
            handlers.push({ item_name: this.$t('copy-link'), handler: this.copyToClipboard, data: vcLink })
            open(event, handlers, 'right-bottom')
        },
        async openPhoneNumberContextMenu(e) {
            if (navigator.platform.toUpperCase().indexOf('MAC')>=0) await (m => new Promise((resolve) => setTimeout(resolve, m)))(100)
            let { event, number } = e.detail
            let handlers = []
            let open = this.cmOpen
            handlers.push({ item_name: this.$t('chat.call'), handler: this.makeCallByNumber, data: number })
            handlers.push({ item_name: this.$t('copy-number'), handler: this.copyToClipboard, data: number })
            open(event, handlers, 'right-bottom')

        },
        async callPhoneNumberByClick(e) {
            let { number } = e.detail
            if (number) this.makeCallByNumber(number)
        },
        async doOnClick(event, message) {
            if (message && message.type === 'data' && message.sub_type === 'call-availability') {
                let handlers = []
                handlers.push({ item_name: this.$t('chat.call'), handler: this.call, data: message.msg })
                if ('cid' in message.msg) handlers.push({ item_name: this.$t('chat.profile'), handler: this.openProfile, data: message.msg.cid })
                else handlers.push({ item_name: this.$t('chat.copy'), handler: this.copyToClipboard, data: message.msg.number })
                this.cmOpen(event, handlers, 'right-bottom', {offsetX: 5, offsetY: 5})
            }
        },
        getPureTextFromHtml(inHtml) {
            let parser = new DOMParser()
            let dom = parser.parseFromString(inHtml, 'text/html')
            return dom.body.textContent
        },
        async doContextMenu(event, message) {
            if (navigator.platform.toUpperCase().indexOf('MAC')>=0) await (m => new Promise((resolve) => setTimeout(resolve, m)))(100)
            let handlers = []
            if (event.target.className.indexOf('reaction-item') > -1  || 
                (event.target.className === 'emoji emojibgrd' && 
                event.target.parentNode.className === 'reaction-item')) {
                const myCid = this.$store.getters[`${USERDATA}/${GET_UID}`]
                const isMyReaction = message && message.reactions.some(r => r.reaction && r.cid === myCid)
                if (isMyReaction) {
                    handlers = this.reactionsContextMenu(message)
                    this.cmOpen(event, handlers, 'right-bottom')
                    return
                }
            }
            let voteResult = this.$store.getters['chat/getPollVoteById'](message.msg.pollId)
            let text = ''
            const entities = message.entities || []
            const isSurvey = message.type === 'data' && message.msg.type === 'survey'
            const isSchedule = message.type === 'data' && message.msg.type === 'schedule'
            const isSpecialMessage = isSurvey || isSchedule
            const isImage = message.type === 'data' && message.sub_type === 'image' && message.msg.file
            const isGroupMsgs = message.hasOwnProperty('groupMsgs')

            
            if (isSchedule) handlers = this.scheduleContextMenu(message.msg)
            if (!isSpecialMessage) handlers = handlers.concat(this.generalSelectModeContextMenu(message))
            if (isImage && !this.isSelectMode && !isGroupMsgs) handlers = handlers.concat(this.imageContextMenu(message))
            if(message.type === 'data' && (['location', 'contact', 'call-availability'].includes(message.sub_type))) {}
            else if (message.type === 'data' && message.msg.type !== 'poll' && message.sub_type !== 'text' && !isSpecialMessage && !this.isSelectMode) {
                const result = await this.checkSavedFileExists(message)
                if (result && result.checkResult) {
                    handlers = handlers.concat(this.showFileInFolderContextMenu(result.storedPath))
                }
                handlers = handlers.concat(this.saveFileContextMenu(isGroupMsgs))
            }
            else if (message.type === 'data' && message.msg.type === 'poll' && voteResult && !this.isSelectedMsgs) handlers.push({ item_name: this.$t('cancel-vote'), handler: this.cancelPoll })
            else if (!this.isSelectedMsgs) {
                if (event.target.className === 'e-mail') text = event.target.innerText
                else {
                    text = this.getPureTextFromHtml(message.msg)
                }
                handlers = handlers.concat(this.copyTextContextMenu(text, entities, isSpecialMessage))
            }

            if (this.is_member) {
                const isAdmin = this[IS_CHAT_ADMIN]({cid: message.cid, cidType: message.cidType})
                switch (this.side) {
                    case 'right':
                        await this[ACT_CHAT_SET_MSG_ENTITIES]({text, entities})
                        handlers = handlers.concat(this.rightSideContextMenu(message, isSpecialMessage, isAdmin))
                        break
                    case 'left':
                        handlers = handlers.concat(this.leftSideContextMenu(message, isSpecialMessage, isAdmin))
                        break
                    case 'center':
                        return
                }
            }
            this.cmOpen(event, handlers, 'right-bottom')
        },
        reactionsContextMenu(msg) {
            let handlers = []
            handlers.push({ item_name: this.$t('chat.cancel-reaction'), handler: () => {
                    this[ACT_CHAT_SET_MSG_REACTION]({ id: msg.id, reaction: '', senderId: msg.senderId })
                }
            })
            return handlers
        },
        generalSelectModeContextMenu(message) {
            const isGroupMsgs = message.hasOwnProperty('groupMsgs')
            const selectStr = isGroupMsgs ? this.$t('chat.group-messages.select-all') : this.$t('chat.select')
            const copyStr = isGroupMsgs ? this.$t('chat.group-messages.copy-all') : this.$t('chat.copy')
            let handlers = []
            if (!this.readOnly) {
                handlers.push({ item_name: this.isSelectMode && this.isChecked ? this.$t('chat.remove-select') : selectStr, handler: () => {
                    if (!this.isSelectModeOn) {
                        this.isSelectModeOn = !this.isSelectModeOn
                        this.onSelectMessage(message, true)
                    } else  {
                        if (this.isChecked) this.onSelectMessage(message, false)
                        else this.onSelectMessage(message, true)
                    }
                    }
                })
                if (!this.isThreads) handlers.push({ item_name: this.$t('chat.comment-verb'), handler: () => this.openChatComments(message) })
            }
            if (this.isSelectedMsgs) {
                    handlers.push({ item_name: copyStr, handler: this.copyMultiple })
            }
            return handlers
        },
        showFileInFolderContextMenu(fpath) {
            const nameStr = this.$t('show-in-folder')
            let handlers = []
            handlers.push({ item_name: nameStr, handler: this.showFileInFolder, data: fpath })
            return handlers
        },
        async openChatComments(_message) {
            let ref = `comments${_message.id}`
            let commentComponent = this.$refs[ref]
            if (commentComponent)  { 
                commentComponent.openThreads() 
            } else {
                this[ACT_SET_CURRENT_COMMENTS]({ commentId: _message.id})
                let text = typeof _message.msg === 'object' ? _message.msg.text || '' : _message.msg
                const message = this.getMsgNameByType(_message)
                const params = { cid: _message.cid, cidType: _message.cidType, commentId: _message.id, isCommentsSubscribed: false, message, text }
                this[ACT_INFO_PUSH]({type: INFO_TYPES.THREADS, params})
            }
        },
        saveFileContextMenu(isGroupMsgs) {
            if (isGroupMsgs) return []
            const saveStr = this.$t('save')
            let handlers = []
            if (!this.readOnly)
                handlers.push({ item_name: saveStr, handler: this.doSaveFile })
            return handlers
        },
        copyTextContextMenu(text, entities, isSpecialMessage) {
            let handlers = []
            const isNodeInSelection = this.nodeInSelection(this.$el)
            if (isNodeInSelection) {
                let selectedText = this.getPureTextFromHtml(this.getSelectedText())
                handlers.push({ item_name: this.$t('copy-selected-text'), handler: this.copyToClipboard, data: { text: selectedText, entities } })
            }
            if (!isSpecialMessage && !this.isSelectMode && !isNodeInSelection) {
                handlers.push({ item_name: this.$t('copy'), handler: this.copyToClipboard, data: { text, entities } })
            }
            return handlers
        },
        scheduleContextMenu(msg) {
            let handlers = []
            if (msg.eventType === 'invite'){
                const payload =  { type: "exchange", id: msg.id, response: "accept" }
                handlers.push({item_name: this.$t('integrations.invite-confirm'), handler: () => {
                        this[ACT_SCHEDULE_RESPONSE_TO_INVITATION](payload)
                    }
                })
                handlers.push({item_name: this.$t('integrations.invite-confirm-tentatively'), handler: () => {
                        payload.response = "accept-tentatively"
                        this[ACT_SCHEDULE_RESPONSE_TO_INVITATION](payload)
                    }
                })
                handlers.push({item_name: this.$t('integrations.invite-decline'), handler: () => {
                        payload.response = "decline"
                        this[ACT_SCHEDULE_RESPONSE_TO_INVITATION](payload)
                    }
                })
            }
            handlers.push({item_name: this.$t('integrations.open-calendar'), handler: () => {
                    this[ACT_PUSH_MAIN_TYPE]({type: MAIN_TYPES.MEETINGCALENDAR})
                }
            })
            return handlers
        },
        imageContextMenu(message) {
            let handlers = []
            if (message.hasOwnProperty('groupMsgs')) return handlers
            handlers.push({ item_name: this.$t('chat.copy-image'), handler: async () => {
                        let link = this.$store.getters['userdata/getHost'] + '/' + message.msg.file
                        const blobFile = await this.getBlobFromURL(link)
                        let changedBlob = new Blob([blobFile], {type: 'image/png'})
                        navigator.clipboard.write([new ClipboardItem({ 'image/png' : changedBlob })])
                    }
            })
            return handlers
        },
        rightSideContextMenu(message, isSpecialMessage, isAdmin) {
            const isGroupMsgs = message.hasOwnProperty('groupMsgs')
            const deleteStr = isGroupMsgs ? this.$t('chat.group-messages.delete-all') : this.$t('chat.delete')
            let handlers = []
            if (!this.isSelectMode) {
                handlers.push({ item_name: this.$t('info'), handler: this.showInfoMsg, data: message })
                handlers.push({ item_name: this.$t('chat.reply'), handler: this.reply, data: message.id })
            }
            if (!this.readOnly && !isSpecialMessage) {
                const fwdStr = isGroupMsgs ? this.$t('chat.group-messages.forward-all') : this.$t('chat.forward')
                if (this.isSelectedMsgs && !isGroupMsgs) handlers.push({ item_name: fwdStr, handler: this.forwardMultple })
                else if (!this.isSelectedMsgs && isGroupMsgs) handlers.push({ item_name: fwdStr, handler: this.forwardGroupMsgs, data: message.groupMsgs })
                else if (!this.isSelectMode) handlers.push({ item_name: fwdStr, handler: this.forward, data: message })
            }
            if (isAdmin && message.cidType === 'group' && !this.isSelectMode && !this.isThreads) handlers.push({ item_name: this.$t('chat.pin'), handler: this.attachMsg, data: message })
            if (!this.isSelectMode && this[GET_SERVER_API] >= declarations.serverAPILevels.LEVEL_8 &&
                (message.type === 'text' || message.type === 'data' && message.sub_type === 'text' && 
                (!message.author || message.author === message.senderId) && !this.isSelectMode) || 
                (!this.isSelectMode && ['image', 'video', 'file'].includes(message.sub_type))) {
                    if (this.isThreadsComment) message.isThreads = true 
                    handlers.push({ item_name: this.$t('chat.edit'), handler: this[ACT_CHAT_UPDATE_EDITED], data: message })
            }
            if (this.isSelectedMsgs) handlers.push({ item_name: deleteStr, handler: this.deleteMultiple })
            else if (!this.isSelectMode) handlers.push({ item_name: deleteStr, handler: this.removeMsg, data: message })
            return handlers
        },
        leftSideContextMenu(message, isSpecialMessage, isAdmin) {
            const isGroupMsgs = message.hasOwnProperty('groupMsgs')
            const deleteStr = isGroupMsgs ? this.$t('chat.group-messages.delete-all') : this.$t('chat.delete')
            let handlers = []
            if (!this.isSelectMode) {
                handlers.push({ item_name: this.$t('info'), handler: this.showInfoMsg, data: message })
                if (message.senderId !== 0 && message.cidType === 'group') {
                    let cid = message.senderId
                    handlers.push({ item_name: this.$t('information.message'), handler: this.openChat, data: cid })
                }
                if (this.secondContextMenuHandlers.length) {
                    this.secondContextMenuHandlers.forEach(h => {
                        handlers.push(h)
                    })
                    this.secondContextMenuHandlers = []
                }
                if (message.senderId !== 0) handlers.push({ item_name: this.$t('chat.reply'), handler: this.reply, data: message.id })
            }
            if (!this.readOnly && !isSpecialMessage) {
                if (this.isSelectedMsgs) handlers.push({ item_name:  this.$t('chat.forward'), handler: this.forwardMultple })
                else if (!this.isSelectMode) handlers.push({ item_name:  this.$t('chat.forward'), handler: this.forward, data: message })
            }
            if (isAdmin && message.cidType === 'group' && !this.isSelectMode && !this.isThreads) handlers.push({ item_name: this.$t('chat.pin'), handler: this.attachMsg, data: message })
            if (this.isSelectedMsgs) {
                handlers.push({ item_name: this.$t('chat.delete'), handler: this.deleteMultiple })
            } else if (!this.isSelectMode) handlers.push({ item_name: deleteStr, handler: this.removeMsg, data: message })
            return handlers
        },
        openChat(cid) {
            const payload = {cid, cidType: 'user', isBot: false}
            this.$store.dispatch('chats/open', payload)
        },
        doSecondContextMenu(handlers) {
            this.secondContextMenuHandlers = handlers
        },
        runBotCommand(e) {
            let { command } = e.detail
            if (command) this.$emit('send', command)
        },
        joinVideoConf({type, vcLink}) {
            this.modalOpen({ component: EnterConf, props: { type, vcLink: vcLink } })
        },
        makeCallByNumber(number) {
            this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'call', data: {number, video: false}})
        },
        showInfoMsg(message) {
            message.cid = this.cid
            message.notAction = true
            if (this.isThreads) message.isThreads = true
            this[ACT_INFO_PUSH]({ type: 'details-msg', params: message })
        },
        removeMsg: function (message) {
            this.modalOpen({
                component: DelChatMessage,
                props: {
                    msg: message,
                    btnOk: {
                        cb: (() =>  { this.$emit('clearselectmode') })
                    }
                }
            })
        },
        copyMultiple() {
            let copiedText = ''
            let msgs = this._getSelectedMessages().sort((a, b) => a.id - b.id)
            msgs.forEach((m, i) => {
                const typeName = this._getDataTypeName(m.data.type)
                const fio = this._getFio(m.senderId)
                const dateTimeText = this._getDateTime(m.timeDetails)
                const itemText = this._getItemText(m)
                let headerText = fio + ' ' + dateTimeText + ':'
                copiedText += headerText + '\n'
                if (typeName) copiedText += typeName + '\n'
                if (itemText && itemText.length) copiedText += itemText + '\n'
                if (copiedText.length && i < msgs.length - 1) copiedText += '\n'
            })
            if (copiedText) this.$copyText(copiedText)
            this.$emit('clearselectmode')
        },
        deleteMultiple() {
            const msgs = this._getSelectedMessages()
            this.modalOpen({
                component: DeleteMultipleMessages,
                props: { msgs, cid: this.cid, cidType: this.cidType,
                    btnOk: {
                        cb: (() =>  { this.$emit('clearselectmode') })
                    }
                }
            })
        },
        forwardMultple() {
            const msgs = this._getSelectedMessages()
            const sortedMsgs = msgs.sort((a, b) => a.id - b.id)
            this.modalOpen({
                component: ForwardMultipleMessages,
                props: { msgs: sortedMsgs,
                    btnOk: {
                        cb: (() =>  { this.$emit('clearselectmode') })
                    }
                }
            })
        },
        _getSelectedMessages() {
            let msgs = []
            console.log("🚀 ~ file: ChatMessage.vue:1045 ~ _getSelectedMessages ~ this.selectedMessages:", this.selectedMessages)
            this.selectedMessages.map(id => {
                let msg
                console.log("🚀 ~ file: ChatMessage.vue:1039 ~ _getSelectedMessages ~ this.isThreadsComment:", this.isThreadsComment)
                if (!this.isThreadsComment) msg = this[GET_MESSAGE_BY_ID](id)
                else msg = this[GET_COMMENT_BY_ID](id)
                console.log("🚀 ~ file: ChatMessage.vue:1040 ~ _getSelectedMessages ~ msg:", msg)
                msgs.push(msg)
            })
            return msgs
        },
        forwardGroupMsgs(groupMsgs) {
            const msgs = utils.cloneObject(this._getGroupMessages(groupMsgs))
            const newFileGroupId = Date.now().toString(36)
            for (let i = 0; i < msgs.length; i++) {
                const msg = msgs[i]
                msg.data.fileGroupId = newFileGroupId
            }
            const sortedMsgs = msgs.sort((a, b) => a.id - b.id)
            this.modalOpen({
                component: ForwardMultipleMessages,
                props: { msgs: sortedMsgs,
                    btnOk: {
                        cb: (() =>  {})
                    }
                }
            })
        },            
        _getGroupMessages(groupMsgs) {
            let msgs = []
            groupMsgs.map(m => {
                let msg = this.$store.getters['chat/getMessageById'](m.msgId)
                msgs.push(msg)
            })
            return msgs
        },
        forward(message) {
            message = JSON.parse(JSON.stringify(message))
            let data = message.msg
            let entities = message.entities
            let dataType, author = message.author ? message.author : message.senderId
            if (message.type === 'text') {
                dataType = declarations.msgDataTypes.MSG_DATA_TYPE_TEXT
            } else {
                if (message.sub_type === 'text') {
                    data = {
                        type: message.sub_type,
                        text: data,
                    }
                    if (!entities) entities = this.extractInputTextFormat(data.text).entities || []
                    data.entities = entities
                } else {
                    data.type = message.sub_type
                }
                if (data.file) {
                    data.file = data.file.split('/').pop();
                }
                if (message.sub_type === 'location') {
                    let { type, latitude, longitude } = data
                    data = { type, latitude, longitude }
                }
                if (message.sub_type === 'contact') {
                    data.fields = message.fields
                }

                data = JSON.stringify(data)
                dataType = declarations.msgDataTypes.MSG_DATA_TYPE_DATA
            }
            this.modalOpen({
                component: SelectChatToForward,
                props: {
                    msg: { dataType, data, author },
                    btnOk: {
                        cb: (() =>  { this.$emit('clearselectmode') })
                    }
                }
            })
        },
        copyToClipboard(data = '') {
            let { text, entities } = data
            let _text
            if (text) {
                if (entities) this[ACT_CHAT_SET_MSG_ENTITIES]({text, entities})
                _text = text
            }
            else {
                _text = data
            }
            this.$copyText(_text, undefined, {
                sourceType: DLP_SOURCE_TYPES.CHAT_MESSAGE,
                sourceData: {
                    id: this.message.id,
                    cid: this.message.cid,
                    cidType: this.message.cidType,
                }
            })
            // this.$emit('clipboardchanged', message);
        },
        reply(id) {
            let message = this.isThreadsComment ? this[GET_COMMENT_BY_ID](id) : this[GET_MESSAGE_BY_ID](id)
            let textInput = document.getElementById('message-input')
            if (this.isThreadsComment) {
                message.isThreads = true
                textInput = document.getElementById('comment-input')
            }
            this[ACT_CHAT_UPDATE_REPLY](message)
            this.$nextTick(() => {
                let el = textInput.querySelector('[id=custom-textarea1]')
                el && el.focus()
            })
        },
        doSaveFile(msgIn = null) {
            const msg = msgIn ? msgIn : this.message
            this.loadMessageFile({
                id: msg.id,
                file: msg.msg.file,
                size: msg.msg.size
            }, () => { this.downloadFile(msg, true) })
        },
        doLoad() {
            this.loadMessageFile({
                id: this.message.id,
                file: this.message.msg.file,
                size: this.message.msg.size,
                key: this.message.msg.key,
            }, () => {this.downloadFile(this.message)})
        },
        scrollingToElement: function (id) {
            if (id) {
                let element = document.querySelector('#msg' + id)
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })

                setTimeout(function () {
                    element.classList.add('scroll-msg')
                    element.style.opacity = 0.3
                }, 500)
                setTimeout(function () {
                    element.style.opacity = 1
                }, 800)
                setTimeout(function () {
                    element.style.opacity = 0.3
                }, 1100)
                setTimeout(function () {
                    element.style.opacity = 1
                }, 1400)
                setTimeout(function () {
                    element.classList.remove('scroll-msg')
                }, 1700)
            }
        },
        cancelPoll: function () {
            this.$refs.chatPollMessage.cancelPoll()
        },
        attachMsg: function (message) {
            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('chat.msg-pin-conf'),
                    btnOk: {
                        cb: () => {
                            let { cid, cidType } = this[GET_SELECTED_CHAT]
                            this.$store.dispatch(`chats/${ACT_CHANGE_CHAT}`, { cid, cidType, pinMessage: message.id })
                        }
                    }
                }
            })
        },
        checkBotMsg (text, textEntities = []) {
            if (text && text.length) {
                const isOldRefFormat = text.search(/(^|[^\S])(?<command>\/(?!\/)\S+)/) > -1
                let isNewRefFormat = false
                if (isOldRefFormat || isNewRefFormat) {
                    this.withText = true
                    return true
                }
                return false
            }
        },
        sendBotCommand (command) {
            this.$emit('send', command)
        },
        openSenderInfo() {
            const isGroup = this.message.cidType === 'group'
            this.openProfile(this.message.senderId, isGroup)
        },
        openProfile(cid, isGroup = false) {
            cid = +cid
            this[ACT_INFO_PUSH]({type: 'contact-info', params: { cid, isGroup }})
        },
        call({cid = false, number = false}) {
            if (cid || number) {
                let data = {cid, number, video: false}
                this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'call', data})
            }
        },
        mouseOverMsg(e) {
            e.preventDefault()
            if ((e.target.className && e.target.className.indexOf('reaction-item') > -1)  || 
                (e.target.className === 'emoji emojibgrd' && e.target.parentNode.className === 'reaction-item')) {
                this.isOverMsgFooter = false
                clearTimeout(this.timerOverFooter)
                return
            }

            let msgListItem 
            if (e.target.className === 'message-list-item') msgListItem = e.target
            else if (e.relatedTarget && e.relatedTarget.className.indexOf('thread-wrapper')>-1) msgListItem = e.relatedTarget.offsetParent
            else msgListItem = e.path && Array.from(e.path).find(el => el.className === 'message-list-item')
            if (!msgListItem) return
            let footerElement = msgListItem.querySelector('.message-footer')
            if (!footerElement || !footerElement.getBoundingClientRect) return
            const rectB = footerElement.getBoundingClientRect()
            this.currentFooterRect= rectB
            const { width } = rectB
            this.reactionPickerStyle['bottom'] = '2px'
            const isLeftSide = this.side === 'left'
            if (isLeftSide && this.isThreadsComment) {
                let deltaX = 0
                const liElem = msgListItem.parentElement.parentElement
                if (liElem && liElem.classList.value.indexOf('series-message-end')>-1) deltaX = 32
                this.reactionPickerStyle['left'] = Math.round(width) + deltaX + 14 + 'px'
            }
            else if (isLeftSide) this.reactionPickerStyle['left'] = Math.round(width) + 50 + 'px'
            else this.reactionPickerStyle['right'] = Math.round(width) + 12 + 'px'
            clearTimeout(this.timerOverFooter)
            this.timerOverFooter = setTimeout(() => {
                if (this.timerOverFooter && !this.isLeavingMsgFooter && !this.isOverMsgFooter) {
                    this.isOverMsgFooter = true
                }
            }, 500)
        },
        mouseLeaveMsg(e) {
            e.preventDefault()
            const footerRect = this.currentFooterRect
            this.isLeavingMsgFooter = true
            setTimeout(() => { this.isLeavingMsgFooter = false }, 1000)
            if (e.y < footerRect.y - 120 || e.y > footerRect.y + footerRect.height + 120) {
                clearTimeout(this.timerOverFooter)
                clearTimeout(this.timerLeaveFooter)
                this.isOverMsgFooter = false
                return
            }
            this.timerLeaveFooter = setTimeout(() => {
                if (!this.isOverReactionPopup && this.isOverMsgFooter) this.isOverMsgFooter = false
            }, 1500)
        },
        mouseOverMsgComments(e) {
            this.isOverMsgComments = true
        },
        mouseLeaveMsgComments(e) {
            this.isOverMsgComments = false
        },
        mouseOverReactonPicker(e) {
            this.isOverReactionPopup = e
            if (!e) this.isOverMsgFooter = false
        },
        ...mapActions(INFO, [ACT_INFO_PUSH]),
        ...mapActions(CHAT, [
            ACT_CHAT_UPDATE_REPLY, ACT_CHAT_UPDATE_EDITED, 
            ACT_CHAT_SET_MSG_ENTITIES, ACT_SET_SELECTED_MSGS,
            ACT_CHAT_SET_MSG_REACTION,
            ACT_CHAT_GET_MESSAGES,
            ACT_SET_CURRENT_COMMENTS,
            ACT_SET_MSG_COMMENT_SUBSCRIBED,
        ]),
        ...mapActions(INTEGRATIONS, [ACT_SCHEDULE_RESPONSE_TO_INVITATION]),
        ...mapActions(CONTENT_MANAGER, [ACT_PUSH_MAIN_TYPE]),
    },
}
