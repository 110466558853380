//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex"
import { CHAT, CONTENT_MANAGER } from "../../store/modulesNames"
import { ACT_SET_SELECTED_MSGS, ACT_SET_SELECT_MODE } from "../../store/actionsTypes"
import { 
    GET_MESSAGE_BY_ID, 
    GET_COMMENT_BY_ID, 
    GET_SELECTED_MSGS, 
} from "../../store/gettersTypes"
import DeleteMessages from '../modal/DeleteMultipleMessages.vue'
import SelectChatToForward from '../modal/SelectChatToForwardMessages.vue'
import ChatMessageMixin from "./chat-message-mixin"

export default {
    name: "ManageSelectedMessages",
    mixins: [ChatMessageMixin],
    components: { DeleteMessages, SelectChatToForward },
    props: {
        isSelectMode: {
            type: Boolean,
            required: true,
            default: false
        },
        cid: {
            type: Number,
            required: true
        },
        cidType: {
            type: String,
            required: true
        },
        isThreads: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            msgs: []
        }
    },
    computed: {
        selectedMsgsNumber() {
            return this[GET_SELECTED_MSGS] && this[GET_SELECTED_MSGS].length || 0
        },
        ...mapGetters(CHAT, [GET_MESSAGE_BY_ID, GET_COMMENT_BY_ID, GET_SELECTED_MSGS]),
    },
    mounted() {
        this.msgs = this[GET_SELECTED_MSGS] || []
    },
    watch: {
        [GET_SELECTED_MSGS](val) {
            this.msgs = val
        }
    },
    methods: {
        clearMessagesSelection() {
            this[ACT_SET_SELECTED_MSGS]([])
            this.msgs = []
            this.$emit('clearselectmode')
        },
        deleteMessages() {
            const msgs = this.formSelectedMessages()
            this.modalOpen({
                component: DeleteMessages,
                props: { msgs, cid: this.cid, cidType: this.cidType,
                    btnOk: {
                        cb: () => this.clearMessagesSelection()
                    }
                }
            })
        },
        formSelectedMessages() {
            let msgs = []
            this.msgs.map(id => {
                let msg
                if (!this.isThreads) msg = this[GET_MESSAGE_BY_ID](id)
                else msg = this[GET_COMMENT_BY_ID](id)
                msgs.push(msg)
            })
            return msgs
        },                       
        copyMessages() {
            let copiedText = ''
            let msgs = this.formSelectedMessages().sort((a, b) => a.id - b.id)
            msgs.forEach((m, i) => {
                const typeName = this._getDataTypeName(m.data.type)
                const fio = this._getFio(m.senderId)
                const dateTimeText = this._getDateTime(m.timeDetails)
                const itemText = this._getItemText(m)
                let headerText = fio + ' ' + dateTimeText + ':'
                copiedText += headerText + '\n'
                if (typeName) copiedText += typeName + '\n'
                if (itemText && itemText.length) copiedText += itemText + '\n'
                if (copiedText.length && i < msgs.length - 1) copiedText += '\n'
            })
            if (copiedText) this.$copyText(copiedText)
            this.clearMessagesSelection()
        },
        forwardMessages() {
            const msgs = this.formSelectedMessages()
            const sortedMsgs = msgs.sort((a, b) => a.id - b.id)
            this.modalOpen({
                component: SelectChatToForward,
                props: { msgs: sortedMsgs,
                    btnOk: {
                        cb: () => this.clearMessagesSelection()
                    }                    
                }
            })
        },                                          
        ...mapActions(CHAT, [ACT_SET_SELECT_MODE, ACT_SET_SELECTED_MSGS]),
    }
}
