import {
    GET_IS_PHONE_MINIMIZED,
    GET_MERGED_CONTACT_BY_ID,
    GET_MERGED_CONTACT_BY_PHONE, GET_PHONE_CALL_MOS,
    GET_PHONE_IN_FULLSCREEN,
    GET_PHONE_SHOW,
    GET_RING_SOUND,
    GET_HOLD_SOUND,
    GET_SECOND_CALL_SOUND,
} from '../../store/gettersTypes'
import {rtcCallStates, rtcCallTypes} from "../../callWorker"
import {CALL_CONNECTION_STATUSES} from "../../store/modules/phone"
import {mapGetters, mapActions} from "vuex"
import {CONTACTS, PHONE, PHONE_CNTL, USERDATA} from "../../store/modulesNames"
import {
    ACT_PHONE_COMMANDS,
    ACT_PHONE_RTC_CALL_DTMF,
    ACT_PHONE_RTC_CALL_TRANSIT,
    ACT_SET_CALL_STATUS
} from "../../store/actionsTypes"
import {PHONE_TYPES} from "../../constants"

import { SOUNDS, SOUND_TYPES } from '../../constants'

const mixin = {
    data() {
        return {
            phonePlug: null
        }
    },
    computed: {
        // *** Переопределение start *** //
        type() {
            return PHONE_TYPES.PHONE
        },
        id() {
            return this.callId
        },
        cid() {
            return this.phone.cid
        },
        number() {
            return this.phone.number
        },
        busy() {
            return this.state === rtcCallStates.rtcCallStateBusy
        },
        btnsDisabled() {
            return this.connectionStatus !== CALL_CONNECTION_STATUSES.CONNECTED
        },
        statusText() {
            if (this.connected && this.state !== rtcCallStates.rtcCallStateHold) return this.time
            else return this.stateText
        },
        // *** Переопределение stop  *** //

        connected() {
            return this.connectionStatus === CALL_CONNECTION_STATUSES.CONNECTED
        },
        phone() {
            // окно иногда закрывается позже чем удаляется вызов, возвращаем заглушку из предыдущего состояния
            let storePhone = this[GET_PHONE_SHOW]
            if (!storePhone.callId && this.phonePlug) return this.phonePlug
            else return storePhone
        },
        callId() {
            return this.phone.callId
        },
        connectionStatus() {
            return this.phone.connectionStatus
        },
        wasConnected() {
            return this.phone.wasConnected
        },
        disconnectedLocal() {
            return this.phone.disconnectedLocal
        },
        typeVideo() {
            return this.phone.type === rtcCallTypes.rtcCallTypeVideo
        },
        inCall() {
            return this.phone.inCall
        },
        mute() {
            return this.phone.mute
        },
        pause() {
            return this.phone.hold
        },
        state() {
            return this.phone.state
        },
        phoneTerminated() {
            return this.connectionStatus >= CALL_CONNECTION_STATUSES.DISCONNECTED
        },
        phoneTerminatedRemote() {
            return this.phoneTerminated && !this.disconnectedLocal
        },
        phoneTerminatedLocal() {
            return this.phoneTerminated && this.disconnectedLocal
        },
        stateText() {
            if (this.connectionStatus === CALL_CONNECTION_STATUSES.DISCONNECTED) return this.getTerminatedText()
            else if (this.connectionStatus === CALL_CONNECTION_STATUSES.ERROR) return this.getErrorText()
            else return this.getStateText(this.state)
        },
        mos() {
            return this[GET_PHONE_CALL_MOS](this.id) || 0
        },
        callQuality() {
            return true
        },
        callQualityText() {
            if (!this.time) return ''
            const callStartTime = this.answeredCallStartTime
            const unixTime = Math.floor(Date.now() / 1000)
            const secondsDelta = unixTime - callStartTime
            if (secondsDelta < 6) return ''
            const qualityFigure = this.mos
            let qualityText = ''
            if (qualityFigure > 4.34) qualityText = this.$t('phone.quality.best')
            else if (qualityFigure <=4.34 && qualityFigure > 4.03)  qualityText = this.$t('phone.quality.high')
            else if (qualityFigure <=4.03 && qualityFigure > 3.6)  qualityText = this.$t('phone.quality.medium')
            else if (qualityFigure <=3.6 && qualityFigure >= 3.1)  qualityText = this.$t('phone.quality.low')
            else qualityText = this.$t('phone.quality.poor')
            return qualityText
        },
        ...mapGetters(CONTACTS, [
            GET_MERGED_CONTACT_BY_ID,
            GET_MERGED_CONTACT_BY_PHONE,
        ]),
        ...mapGetters(PHONE, [
            GET_PHONE_SHOW,
            GET_PHONE_CALL_MOS,
        ]),
        ...mapGetters(PHONE_CNTL, {
            minimize: GET_IS_PHONE_MINIMIZED,
            fullScreen: GET_PHONE_IN_FULLSCREEN,
        }),
        ...mapGetters(USERDATA, [
            GET_RING_SOUND,
            GET_SECOND_CALL_SOUND,
            GET_HOLD_SOUND,
        ]),
    },
    watch: {
        phone(newVal, oldVal) {
            if (!newVal.callId && oldVal && oldVal.callId) this.phonePlug = oldVal
        },
        state(val) {
            let src = ''
            switch (val) {
                case rtcCallStates.rtcCallStateBusy: //2
                    src = this[GET_SECOND_CALL_SOUND] //'audio/call_ring_second_alert.mp3'
                    break
                case rtcCallStates.rtcCallStateRinging: //3
                    src = SOUNDS[SOUND_TYPES.CALL_RING] //'audio/call_ring_ringing.mp3'
                    break
                case rtcCallStates.rtcCallStateHold: //11
                    src = this[GET_HOLD_SOUND] //'audio/hold.mp3'
                    break
            }
            this.setAudioSrc(src)
        },
        phoneTerminatedRemote(val) {
            if (val) this.setAudioSrc(SOUNDS[SOUND_TYPES.CALL_RING_BUSY])
            // if (val) this.setAudioSrc('audio/call_ring_busy.mp3')
        },
        stateText(val) {
            this[ACT_SET_CALL_STATUS]({ type: PHONE_TYPES.PHONE, id: this.callId, status: val})
        },
    },
    methods: {
        // *** Переопределение start *** //
        onPageUnload() {
            if (!this.phoneTerminated) this.terminate() //@todo phone controller terminate all?
        },
        togglePause() {
            this.$store.dispatch(`${PHONE}/${ACT_PHONE_COMMANDS}`, {params: this.phone, command: 'mediaWorkerPause', pause: !this.pause})
        },
        toggleMicrophone() {
            this.$store.dispatch(`${PHONE}/${ACT_PHONE_COMMANDS}`, {params: this.phone, command: 'mediaWorkerMute'});
        },
        pressDialKey ({digits}) {
            this[ACT_PHONE_RTC_CALL_DTMF]({callId: this.callId, digits})
        },
        terminate() {
            if (this.phoneTerminatedLocal) return
            this.$store.dispatch(`${PHONE}/${ACT_PHONE_COMMANDS}`, {params: this.phone, command: 'terminate'})
        },
        // *** Переопределение stop *** //
        getStateText(state) {
            let text = ''

            switch (state) {
                case rtcCallStates.rtcCallStateNull: // 0
                case rtcCallStates.rtcCallStateInit: // 1
                    text = this.$t('phone.init')
                    break
                case rtcCallStates.rtcCallStateBusy: // 2
                    text = this.$t('phone.busy2')
                    break
                case rtcCallStates.rtcCallStateConnecting: // 3
                    text = this.$t('phone.connecting')
                    break
                case rtcCallStates.rtcCallStateIncoming: // 4
                    text = this.$t('phone.incoming-call')
                    break
                case rtcCallStates.rtcCallStateRinging: // 5
                    text = this.$t('phone.waiting-for-reply')
                    break
                case rtcCallStates.rtcCallStateDialing: // 6
                    text = this.$t('phone.dialing')
                    break
                case rtcCallStates.rtcCallStateDisconnecting: // 8
                    text = this.$t('phone.ending-call')
                    break
                case rtcCallStates.rtcCallStateHold: // 11
                    text = this.$t('phone.on-hold')
                    break
                case rtcCallStates.rtcCallStateRemoteDisconnected: // 9
                case rtcCallStates.rtcCallStateDisconnected: // 10
                    text = this.$t('phone.call-ended')
                    break
                case rtcCallStates.rtcCallStateDisconnectedRemote: // 15
                    text = this.$t('phone.busy')
                    break
                case rtcCallStates.rtcCallStateDisconnectedTimeOut: // 13
                    text = this.$t('phone.timeout')
                    break
                case rtcCallStates.rtcCallStateDisconnectedDnd: // 14
                    text = this.$t('phone.dnd')
                    break
                case rtcCallStates.rtcCallStateDisconnectedBadParams: // 16
                    text = this.$t('phone.badParams')
                    break
                case rtcCallStates.rtcCallStateDisconnectedNotFound: // 17
                    text = this.$t('phone.notFound')
                    break
                case rtcCallStates.rtcCallStateDisconnectedUnknown: // 18
                    text = this.$t('phone.unknown')
                    break
                case rtcCallStates.rtcCallStateDisconnectedFailed: // 19
                    text = this.$t('phone.failed')
                    break
            }
            return text
        },
        getTerminatedText() {
            let text = this.$t('phone.call-ended')
            if (!this.inCall && !this.wasConnected) {
                switch (this.state) {
                    case rtcCallStates.rtcCallStateDisconnectedRemote: // 15
                        text = this.$t('phone.busy')
                        break
                    case rtcCallStates.rtcCallStateDisconnectedTimeOut: // 13
                        text = this.$t('phone.timeout')
                        break
                    case rtcCallStates.rtcCallStateDisconnectedDnd: // 14
                        text = this.$t('phone.dnd')
                        break
                }
            }
            return text
        },
        getErrorText() {
            let text = this.$t('phone.unknown')

            switch (this.state) {
                case rtcCallStates.rtcCallStateDisconnectedDnd: // 14
                    text = this.$t('phone.dnd')
                    break
                case rtcCallStates.rtcCallStateDisconnectedBadParams: // 16
                    text = this.$t('phone.badParams')
                    break
                case rtcCallStates.rtcCallStateDisconnectedNotFound: // 17
                    text = this.$t('phone.notFound')
                    break
                case rtcCallStates.rtcCallStateAccessDenied: // 22
                    text = this.$t('phone.access-denied')
                    break
            }
            return text
        },
        ...mapActions(PHONE, [
            ACT_PHONE_COMMANDS,
            ACT_PHONE_RTC_CALL_DTMF,
            ACT_PHONE_RTC_CALL_TRANSIT,
        ])
    },
}

export default mixin