var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "select-contact-modal dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title
      }
    },
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("custom-search", {
            ref: "clearSearch",
            staticClass: "search-contact search-input-wrapper",
            attrs: { search: _vm.search, focus: true },
            on: {
              "update:search": function($event) {
                _vm.search = $event
              }
            }
          }),
          _c("div", { staticClass: "search-wrapper" }, [
            !_vm.contactsList.length
              ? _c(
                  "div",
                  { staticClass: "recent-contacts" },
                  [
                    _c("recycle-contacts-list", {
                      ref: "scroller",
                      attrs: { contactsList: _vm.recentUsers },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "div",
                                  { staticClass: "contacts-list-item-wrapper" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "contacts-list-item",
                                        on: {
                                          click: function($event) {
                                            return _vm.startChatWithContact(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "text-block" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "fio name" },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.fio))
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "sub-title" },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.cid))
                                                ])
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "right-block" },
                                          [
                                            item.missedMsgsCount
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "missed-messages"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "count" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.missedMsgsCount
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "right circle-animation",
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    return _vm.showContextMenu(
                                                      $event,
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      width: "10px",
                                                      height: "24px"
                                                    }
                                                  },
                                                  [
                                                    _c("circle", {
                                                      attrs: {
                                                        cx: "5",
                                                        cy: "5",
                                                        r: "2"
                                                      }
                                                    }),
                                                    _c("circle", {
                                                      attrs: {
                                                        cx: "5",
                                                        cy: "12",
                                                        r: "2"
                                                      }
                                                    }),
                                                    _c("circle", {
                                                      attrs: {
                                                        cx: "5",
                                                        cy: "19",
                                                        r: "2"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "line-separator" })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2103204130
                      )
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "select-contact" },
                  [
                    _c("recycle-contacts-list", {
                      ref: "scroller",
                      attrs: { contactsList: _vm.contactsList },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                { staticClass: "contacts-list-item-wrapper" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "contacts-list-item" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-block",
                                          on: {
                                            click: function($event) {
                                              return _vm.startChatWithContact(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "fio name" },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.fio))
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "sub-title" },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.cid))
                                              ])
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "right-block" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "right circle-animation",
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  $event.stopPropagation()
                                                  return _vm.showContextMenu(
                                                    $event,
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    width: "10px",
                                                    height: "24px"
                                                  }
                                                },
                                                [
                                                  _c("circle", {
                                                    attrs: {
                                                      cx: "5",
                                                      cy: "5",
                                                      r: "2"
                                                    }
                                                  }),
                                                  _c("circle", {
                                                    attrs: {
                                                      cx: "5",
                                                      cy: "12",
                                                      r: "2"
                                                    }
                                                  }),
                                                  _c("circle", {
                                                    attrs: {
                                                      cx: "5",
                                                      cy: "19",
                                                      r: "2"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "line-separator" })
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
          ])
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.cancelText },
            on: { click: _vm.modalClose }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }