import Vue from 'vue';
import proto from "../../protocol";
import {
    GET_MAIN_TYPE,
    GET_CHAT_SCROLL_UP,
    GET_THREADS_SCROLL_UP,
    GET_CHAT,
    IS_OWN_CHAT,
    IS_NOTE_CHAT,
    GET_CHAT_EDIT_MESSAGE_ID,
    GET_CHAT_REPLY_MESSAGE_ID,
    GET_CHAT_SELECTED_MSG_ENTITIES,
    GET_CHAT_SEARCH_STRING,
    GET_CHAT_MEDIA_SEARCH_STRING,
    GET_CHAT_ITEM_TEXT,
    GET_MERGED_CONTACT_BY_ID,
    GET_SELECT_MODE,
    GET_SELECTED_MSGS,
    GET_MESSAGE_BY_ID,
    GET_CHAT_MESSAGES,
    GET_REACTIONS_PANEL,
    GET_REACTIONS_PICKER,
    GET_CHAT_LAST_MESSAGE,
    GET_SERVER_API,
    IS_CHAT_HAVE_LAST_MESSAGE,
    IS_CHAT_NEW_API_SUPPORTED,
    GET_CURRENT_COMMENTS,
    GET_COMMENT_BY_ID,
    GET_COMMENT_ID,
} from '../gettersTypes'
import {
    MUT_SELECTED_MESSAGE,
    MUT_CHAT_SCROLL_UP,
    MUT_THREADS_SCROLL_UP,
    MUT_SET_MSG_ENTITIES,
    MUT_CHAT_SET_SEARCH,
    MUT_CHAT_SET_MEDIA_SEARCH,
    MUT_SET_SELECT_MODE,
    MUT_SET_SELECTED_MSGS,
    MUT_DELETE_SELECTED_MSGS,
    MUT_UPDATE_MESSAGE_REACTION,
    MUT_SHOW_REACTION_PANEL,
    MUT_SHOW_REACTION_PICKER,
    MUT_SET_CURRENT_COMMENTS,
    MUT_SET_COMMENT_ID,
    MUT_COMMENT_DELETE,
    MUT_UPDATE_COMMON_MSG_COMMENT_COUNT,
} from '../mutationsTypes';
import {
    ACT_OPEN_CHAT,
    ACT_CHAT_SCROLL_UP,
    ACT_THREADS_SCROLL_UP,
    ACT_CHAT_UPDATE_EDITED,
    ACT_CHAT_UPDATE_REPLY,
    ACT_CHAT_REMOVE_MSG,
    ACT_CHAT_REMOVE_MSGS,
    ACT_CHAT_CHANGE_MSG_TEXT,
    ACT_CHAT_GET_MESSAGE_BY_ID,
    ACT_SET_CHAT_DRAFT,
    ACT_CHAT_SET_MSG_ENTITIES,
    ACT_CHAT_SET_SEARCH,
    ACT_CHAT_SET_MEDIA_SEARCH,
    ACT_OPEN_CUSTOM_NOTIFICATION,
    ACT_SET_CHAT_MARKED,
    ACT_CHAT_ADD_MESSAGE,
    ACT_CHAT_UPDATE_MESSAGE,
    ACT_CHAT_SET_MSG_REACTION,
    ACT_CHAT_UPDATE_REACTION,
    ACT_CHAT_SHOW_REACTION_PANEL,
    ACT_CHAT_SHOW_REACTION_PICKER,
    ACT_UPDATE_SEARCHED_CONTACT,
    ACT_SET_SELECT_MODE,
    ACT_SET_SELECTED_MSGS,
    ACT_DELETE_SELECTED_MSGS,
    ACT_CHAT_GET_MESSAGES,
    ACT_CHAT_GET_MESSAGES_MEDIA,
    ACT_CHAT_GET_PUBLICATIONS_MEDIA,
    ACT_CHAT_GET_SCREEN_MESSAGES,
    ACT_CHAT_GET_PREV_MESSAGES,
    ACT_CHAT_GET_NEXT_MESSAGES,
    ACT_CHAT_LOAD_PREV_MESSAGES,
    ACT_CHAT_LOAD_NEXT_MESSAGES,
    ACT_CHAT_UPDATE_MESSAGES,
    ACT_SET_MSG_COMMENT_SUBSCRIBED,
    ACT_COMMENT_SUBSCRIBED,
    ACT_COMMENT_SENT_RECEIVED,
    ACT_COMMENT_REACTION,
    ACT_COMMENT_UPDATE,
    ACT_COMMENT_CHANGE,
    ACT_SET_CURRENT_COMMENTS,
    ACT_INFO_CHANGE_PARAMS,
    ACT_ADD_NOTIFICATION,
} from '../actionsTypes'
import {
    CONTENT_MANAGER,
    CHATS,
    NOTIFICATIONS,
    CONTACTS,
    LOGIN,
    INFO,
} from '../modulesNames'

import {CONTACT_FIELD_TYPES, SELECTED_MSG_TYPES, CHAT_TYPES} from '../../constants'
import moment from 'moment';
import {i18n} from '../../../ext/i18n'
import { CHAT_MAIN_TYPES } from './content-manager'
import { getIsFullCompactMode, setFullCompactVisibility } from '../../../ext/trackResizes';

const locale = i18n.messages[i18n.locale]

function isSameChat (obj, obj2) {
    return obj.cid === obj2.cid && obj.cidType === obj2.cidType
}

function getMessageById(messages, message_id){
    return messages.find(message => {
        return message.id === message_id
    })
}

function convertTimeMsg(msg) {
    let time = {}
    time.moment = moment()
    if('time' in msg && typeof msg.time === 'number') {
        time.sentDate = moment().subtract(msg.time, 'seconds').format('DD.MM.YYYY')
        time.sentTime = moment().subtract(msg.time, 'seconds').format('HH:mm')
        time.fullTime = moment().subtract(msg.time, 'seconds').format('YYYY-MM-DD HH:mm:ss')
    }
    if('receivedTime' in msg && typeof msg.receivedTime === 'number') {
        time.receivedDate = moment().subtract(msg.receivedTime, 'seconds').format('DD.MM.YYYY')
        time.receivedTime = moment().subtract(msg.receivedTime, 'seconds').format('HH:mm')
    }
    if('watchedTime' in msg && typeof msg.watchedTime === 'number') {
        time.watchedDate = moment().subtract(msg.watchedTime, 'seconds').format('DD.MM.YYYY')
        time.watchedTime = moment().subtract(msg.watchedTime, 'seconds').format('HH:mm')
    }
    if('editedTime' in msg && typeof msg.editedTime === 'number') {
        time.editedDate = moment().subtract(msg.editedTime, 'seconds').format('DD.MM.YYYY')
        time.editedTime = moment().subtract(msg.editedTime, 'seconds').format('HH:mm')
    }
    return time
}

const state = {
    cid: null,
    cidType: null,
    messages: [],
    selectedMsg: null,
    selectedMsgEntities: {},
    poll: {},
    pollVotes: [],
    isUpdating: false,
    scroll_up: false,
    threads_scroll_up: false,
    chatSearchString: null,
    mediaSearchString: null,
    isSelectMode: false,
    selectedMessages: [],
    reactionsPanel: {
        isPanelShow: false,
        reactions: {},
        panelStyle: {}
    },
    reactionsPicker: {
        isPickerShow: false,
        msgId: null,
        pickerStyle: {}
    },
    msgsLoadStepSize: 50,
    maxMsgs: 100,
    prevFirstMsg: 0,
    prevLastMsg: 0,
    comments: [],
    commentId: 0,
}

const getters = {
    isUpdating(state) {
        return state.isUpdating
    },
    [GET_CHAT_MESSAGES](state) {
        if (state.messages.length > state.maxMsgs) {
            return state.messages.slice(0, state.maxMsgs)
        } else {
            return state.messages
        }
    },
    [GET_MESSAGE_BY_ID](state) {
        return function (message_id) {
            return getMessageById(state.messages, message_id)
        }
    },
    [GET_CHAT_REPLY_MESSAGE_ID]: (state) => (isComments = false) => {
        const selectedMsg = state.selectedMsg && state.selectedMsg.type === SELECTED_MSG_TYPES.REPLY && state.selectedMsg
        return selectedMsg && selectedMsg.isThreads === isComments && selectedMsg.id
    },
    [GET_CHAT_EDIT_MESSAGE_ID]: (state) => (isComments = false) => {
        const selectedMsg = state.selectedMsg && state.selectedMsg.type === SELECTED_MSG_TYPES.EDITED && state.selectedMsg
        return selectedMsg && selectedMsg.isThreads === isComments && selectedMsg.id
    },
    getPollVoteById(state) {
        return function (id) {
            let result = false;
            state.pollVotes.forEach(function(item) {
                if(item === id) result = true;
            });
            return result;
        };
    },
    [GET_CHAT_SCROLL_UP](state) {
        return state.scroll_up
    },
    [GET_THREADS_SCROLL_UP](state) {
        return state.threads_scroll_up
    },
    [IS_CHAT_HAVE_LAST_MESSAGE](state, getters, rootState, rootGetters) {
        const chatMessages = getters[GET_CHAT_MESSAGES]
        const chatLastMessage = chatMessages.slice(-1).pop()
        if (!chatLastMessage) return false
        const lastMessageInChats = rootGetters[`${CHATS}/${GET_CHAT_LAST_MESSAGE}`]({
            cid: state.cid,
            cidType: state.cidType,
        })
        if (!lastMessageInChats) return false
        return chatLastMessage.id === lastMessageInChats.id
    },
    [IS_CHAT_NEW_API_SUPPORTED](state, getters, rootState, rootGetters) {
        return rootGetters[`${LOGIN}/${GET_SERVER_API}`] >= declarations.serverAPILevels.LEVEL_16
    },
    [GET_CHAT_SELECTED_MSG_ENTITIES](state) {
        return state.selectedMsgEntities
    },
    [GET_CHAT_SEARCH_STRING](state) {
        return state.chatSearchString
    },
    [GET_CHAT_MEDIA_SEARCH_STRING](state) {
        return state.mediaSearchString
    },
    [GET_CHAT_ITEM_TEXT]: (state, getters, rootState, rootGetters) => ({message, list = false}) => {
        let text
        switch (message.dataType) {
            case declarations.msgDataTypes.MSG_DATA_TYPE_TEXT:
                text = message.data
                break
            case declarations.msgDataTypes.MSG_DATA_TYPE_DATA:
                if (message.data.pollId) message.data.type = 'poll'
                text = list ? getListDataText(message.data) : getDataText({state, getters, rootState, rootGetters}, message.data)
                break
            case declarations.msgDataTypes.MSG_DATA_TYPE_UNSTORED:
                text = message.data
                break
            case declarations.msgDataTypes.MSG_DATA_TYPE_SYSTEM:
                text = getSystemText({state, getters, rootState, rootGetters}, message.data, )
                break
            default:
                text = locale['unknown-data-type']
        }
        return text
    },
    [GET_SELECT_MODE]: (state) => state.isSelectMode,   
    [GET_SELECTED_MSGS]: (state) => state.selectedMessages,
    [GET_REACTIONS_PANEL]: (state) => state.reactionsPanel,
    [GET_REACTIONS_PICKER]: (state) => state.reactionsPicker,
    [GET_CURRENT_COMMENTS](state) {
        if (state.comments.length > state.maxMsgs) {
            return state.comments.slice(0, state.maxMsgs)
        } else {
            return state.comments
        }
    },
    [GET_COMMENT_ID]: (state) => state.commentId,
    [GET_COMMENT_BY_ID]: (state) => (id) => {
        return getMessageById(state.comments, id)
    },
}

const actions = {
    async update ({state, dispatch, commit, rootGetters}, params) {
        let { cid = state.cid, cidType = state.cidType, non_clear } = params
        if (!params.cid) params.cid = cid
        if (!params.cidType) params.cidType = cidType
        let noLoad = rootGetters[`${CHATS}/${IS_OWN_CHAT}`](params) && !rootGetters[`${CHATS}/${IS_NOTE_CHAT}`](params)
        let messages = noLoad ? [] : await proto.getMessages(params)
        if (!non_clear) commit('clearMessages')
        messages.forEach((message) => dispatch(ACT_CHAT_UPDATE_MESSAGE, {
            cid,
            cidType,
            message,
        }))
        commit('sortMessages')
        return state.messages
    },
    async [ACT_CHAT_GET_SCREEN_MESSAGES]({state, dispatch, commit, getters, rootGetters}, params = {}) {
        let { id, commentId = 0 } = params
        let state_msgs = commentId ? state.comments : state.messages
        let messages = []
        const chat = {cid: state.cid, cidType: state.cidType}
        if (commentId) chat.commentId = commentId
        const load = !rootGetters[`${CHATS}/${IS_OWN_CHAT}`](chat) || rootGetters[`${CHATS}/${IS_NOTE_CHAT}`](chat)
        if (load) {
            if (id) {
                if (getters[IS_CHAT_NEW_API_SUPPORTED]) {
                    messages = messages.concat(await dispatch(ACT_CHAT_LOAD_PREV_MESSAGES, {id: id + 1, commentId, count: 20}))
                    messages = messages.concat(await dispatch(ACT_CHAT_LOAD_NEXT_MESSAGES, {id, commentId, count: state.msgsLoadStepSize}))
                } else {
                    messages = await dispatch(ACT_CHAT_GET_MESSAGES, { // грузим с id
                        ...chat,
                        minId: id,
                        commentId
                    })
                    let prevMessages = await dispatch(ACT_CHAT_LOAD_PREV_MESSAGES, { // догружаем предшествующие сообщения
                        id: Math.max(id - 1, 1),
                        count: 20,
                        commentId
                    })
                    messages = prevMessages.concat(messages)
                }
            } else {
                messages.push(...await dispatch(ACT_CHAT_GET_MESSAGES, {...chat, count: state.msgsLoadStepSize, commentId}))
            }
        }
        const isComments = !!commentId
        commit('clearMessages', {isComments})
        await dispatch(ACT_CHAT_UPDATE_MESSAGES, {messages, commentId})
        return state_msgs
    },
    async [ACT_CHAT_GET_PREV_MESSAGES]({state, dispatch}, params = {}) {
        let { commentId } = params
        let state_msgs = commentId ? state.comments : state.messages
        let firstMessage = state_msgs.slice(0, 1).pop()
        let firstId = firstMessage && firstMessage.id || 0
        if (!firstId || state.prevFirstMsg === firstId) {
            return
        }
        state.prevFirstMsg = firstId
        state.prevLastMsg = 0
        let messages = await dispatch(ACT_CHAT_LOAD_PREV_MESSAGES, { id: firstId, commentId, count: state.msgsLoadStepSize })
        dispatch(ACT_CHAT_UPDATE_MESSAGES, {prev: true, messages, commentId})
    },
    async [ACT_CHAT_GET_NEXT_MESSAGES]({state, dispatch, getters}, params = {}) {
        if (!Object.keys(params)) return
        let { commentId } = params
        let state_msgs = commentId ? state.comments : state.messages

        if (getters[IS_CHAT_NEW_API_SUPPORTED]) {
            let lastMessage = state_msgs.slice(-1).pop()
            let lastId = lastMessage && lastMessage.id || 0
            if (!lastId || state.prevLastMsg === lastId) {
                return
            }
            state.prevLastMsg = lastId
            state.prevFirstMsg = 0
            let messages = await dispatch(ACT_CHAT_LOAD_NEXT_MESSAGES, {id: lastId, commentId, count: state.msgsLoadStepSize})
            if(messages && messages.length) dispatch(ACT_CHAT_UPDATE_MESSAGES, {next: true, messages, commentId})
        } else {
            if (state_msgs.length > state.maxMsgs) {
                state_msgs.splice(0, state.msgsLoadStepSize)
            }
        }
    },
    async [ACT_CHAT_LOAD_PREV_MESSAGES]({dispatch}, {id, count, commentId}) {
        let params = {
            cid: state.cid,
            cidType: state.cidType,
            startId: --id,
            count
        }
        if (commentId) params.commentId = commentId
        return dispatch(ACT_CHAT_GET_MESSAGES, params)
    },
    async [ACT_CHAT_LOAD_NEXT_MESSAGES]({dispatch, rootGetters}, {id, count, commentId}) {
        let params = {
            cid: state.cid,
            cidType: state.cidType,
            startId: ++id,
            scrollDown: true,
            count,
        }
        if (commentId) params.commentId = commentId
        return dispatch(ACT_CHAT_GET_MESSAGES, params)
    },
    async [ACT_CHAT_UPDATE_MESSAGES]({state, dispatch, commit, getters}, { prev = false, next = false, messages, commentId }) {
        let state_msgs = commentId ? state.comments : state.messages
        const chat = { cid: state.cid, cidType: state.cidType }
        if (commentId) chat.commentId = commentId
        const newMsgsCount = messages.length
        if (getters[IS_CHAT_NEW_API_SUPPORTED]) {
            if (prev) {
                while (state_msgs.length + newMsgsCount > state.maxMsgs) {
                    state_msgs.pop()
                }
            }
            if (next) {
                while (state_msgs.length + newMsgsCount > state.maxMsgs) {
                    state_msgs.shift()
                }
            }
        }
        messages.forEach((message) => dispatch(ACT_CHAT_UPDATE_MESSAGE, {
            ...chat,
            message,
        }))
        commit('sortMessages', {commentId})
    },
    async [ACT_CHAT_GET_MESSAGES]({}, params) {
        return proto.getMessages(params)
    },
    async [ACT_CHAT_GET_MESSAGES_MEDIA]({}, params) {
        return proto.getMessagesMedia(params)
    }, 
    async [ACT_CHAT_GET_PUBLICATIONS_MEDIA]({}, params) {
        return proto.getPublicationsMedia(params)
    },    
    setUpdating({commit}, isUpdating) {
        commit('setUpdating', isUpdating)
    },
    //@todo разобраться и удалить, открывать через chats
    [ACT_OPEN_CHAT]({state, rootGetters, dispatch, commit}, { cid, cidType = declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER }) {
        const mainType = rootGetters[`${CONTENT_MANAGER}/${GET_MAIN_TYPE}`]
        const marked = rootGetters['chats/isMarked']({cid, cidType})
        
        if (CHAT_MAIN_TYPES.includes(mainType) && cidType === state.cidType && cid === state.cid) {
            if (getIsFullCompactMode()) setFullCompactVisibility()
            return
        }
        
        commit('acs/setCid', cid, {root: true})
        dispatch('chats/open', {cid, cidType}, {root: true})
        if (marked) dispatch(`${CHATS}/${ACT_SET_CHAT_MARKED}`, {
            cidType,
            cid,
            marked: false,
        }, {root: true})
    },
    [ACT_CHAT_SCROLL_UP]({commit}, params) {
        commit(MUT_CHAT_SCROLL_UP, params)
    },
    [ACT_THREADS_SCROLL_UP]({commit}, params) {
        commit(MUT_THREADS_SCROLL_UP, params)
    },
    [ACT_CHAT_SET_MSG_ENTITIES]({commit}, msgEntities) {
        commit(MUT_SET_MSG_ENTITIES, msgEntities)
    },
    [ACT_CHAT_UPDATE_EDITED]({commit}, message) {
        if (!message) message = {}
        const groupMsgs = message.groupMsgs
        let { id = null, isThreads = false } = message
        if (groupMsgs && groupMsgs.length > 0) {
            const lastMessage = utils.cloneObject(groupMsgs[groupMsgs.length-1])
            id = lastMessage.msgId
        }
        commit(MUT_SELECTED_MESSAGE, {type: SELECTED_MSG_TYPES.EDITED, id, isThreads})
    },
    [ACT_CHAT_UPDATE_REPLY]({commit}, message) {
        if (!message) message = {}
        let { id = null, isThreads = false } = message
        commit(MUT_SELECTED_MESSAGE, {type: SELECTED_MSG_TYPES.REPLY, id, isThreads})
    },
    async [ACT_CHAT_REMOVE_MSG]({state, commit}, {msg, everyone}) {
        await proto.setMessageDeleted(msg.id, everyone)
        if (isSameChat(state, msg)) {
            commit('removeMessage', msg.id)
        }
    },
    async [ACT_CHAT_SET_MSG_REACTION]({}, {id, reaction, senderId}) {
        await proto.setMessageReaction(id, reaction)
    },
    async [ACT_CHAT_REMOVE_MSGS]({ commit }, {msgs, everyone}) {
        for (let index = 0; index < msgs.length; index++) {
            const msg = msgs[index]
            await proto.setMessageDeleted(msg.msgId, everyone)
            commit('removeMessage', msg.id)
        }
    },
    async [ACT_CHAT_CHANGE_MSG_TEXT]({dispatch, getters}, {id, commentId, text, newEntities = []}) {
        let message = !commentId ? getters[GET_MESSAGE_BY_ID](id) : getters[GET_COMMENT_BY_ID](id)
        if (!message) return
        message = JSON.parse(JSON.stringify(message))
        let { dataType, data } = message
        if (message.type === 'text' || dataType === 'text') data = text
        else if (data) {
            data.text = text
            let { entities } = data
            newEntities.length ? data.entities = newEntities : data.entities = entities
            if (commentId) data.commentId = commentId
            data = JSON.stringify(data)
        } else {
            return
        }
        try {
            await proto.editMessage({id, dataType, data})
            dispatch(ACT_CHAT_UPDATE_EDITED)
        } catch (e) {
            if (e && e.message && e.message === 'dlp-forbidden') {
                dispatch(`${NOTIFICATIONS}/${ACT_OPEN_CUSTOM_NOTIFICATION}`, {
                    type: 'alert',
                    title: locale['dlp-alert-data-forbidden-title'],
                    subtitle: locale['dlp-alert-data-forbidden-message'],
                }, {root: true})
            }
        }
    },
    async [ACT_CHAT_GET_MESSAGE_BY_ID]({state, getters}, {id, commentId, _cid, _cidType}) {
        let msg = !commentId ? getters[GET_MESSAGE_BY_ID](id) : getters[GET_COMMENT_BY_ID](id)
        let cid, cidType
        if (_cid) cid = _cid
        if (_cidType) cidType = _cidType
        if (!_cid && state.cid) cid = state.cid 
        if (!_cidType && state.cidType) cidType = state.cidType
        if (!msg) {
            try {
                let params = {cid, cidType, id}
                if (commentId) params.commentId = commentId
                msg = await proto.getMessage(params)
            } catch (e) {}
        }
        return msg
    },
    [ACT_SET_CHAT_DRAFT]({state, dispatch, getters}, text = '', commentId = 0) {
        let { cid, cidType } = state
        let replyId = getters[GET_CHAT_REPLY_MESSAGE_ID](!!commentId)
        let editId = getters[GET_CHAT_EDIT_MESSAGE_ID](!!commentId)
        let draft = {}
        if (text) draft.text = text
        if (replyId) draft.replyId = replyId
        if (editId) draft.editId = editId
        if (commentId && text && (replyId || editId)) draft.commentId = commentId
        if (!Object.keys(draft).length) draft = null
        dispatch(`${CHATS}/${ACT_SET_CHAT_DRAFT}`, { cid, cidType, draft }, {root: true})
    },
    [ACT_CHAT_SET_SEARCH]({commit}, str) {
        commit(MUT_CHAT_SET_SEARCH, str)
    },
    [ACT_CHAT_SET_MEDIA_SEARCH]({commit}, str) {
        commit(MUT_CHAT_SET_MEDIA_SEARCH, str)
    },
    [ACT_CHAT_ADD_MESSAGE]({commit, dispatch, rootGetters}, payload) {
        const message = payload.message
        commit('addMessage', message)
        if (message.author) {
            let contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](message.author)
            if (!contact || !contact.cid) dispatch(`${CONTACTS}/${ACT_UPDATE_SEARCHED_CONTACT}`, {cid: message.author},  {root: true }).catch()
        }
    },
    [ACT_CHAT_UPDATE_MESSAGE]({commit, dispatch, getters, rootGetters}, payload) {
        const { message, eventMessage, toEnd = true, commentId = 0 } = payload
        let state_msgs = commentId ? state.comments : state.messages
        if (getters[IS_CHAT_NEW_API_SUPPORTED]) {
            if (eventMessage && !getters[IS_CHAT_HAVE_LAST_MESSAGE]) {
                if (message.type === 'out') {
                    dispatch(ACT_CHAT_GET_SCREEN_MESSAGES, payload)
                }
                return
            }
            if (toEnd) {
                while (state_msgs.length + 1 > state.maxMsgs) {
                    state_msgs.shift()
                }
            } else {
                while (state_msgs.length + 1 > state.maxMsgs) {
                    state_msgs.pop()
                }
            }
        }
        commit('updateMessage', payload)
        if (message.author) {
            let contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](message.author)
            if (!contact || !contact.cid) dispatch(`${CONTACTS}/${ACT_UPDATE_SEARCHED_CONTACT}`, {cid: message.author},  {root: true }).catch()
        }

    },
    [ACT_CHAT_UPDATE_REACTION]({commit, rootGetters}, payload) {
        const chat = rootGetters[`${CHATS}/${GET_CHAT}`]({cid: payload.cid, cidType: payload.cidType})
        payload.chat = chat
        commit(MUT_UPDATE_MESSAGE_REACTION, payload)
    },
    [ACT_CHAT_SHOW_REACTION_PANEL]({commit}, payload) {
        commit(MUT_SHOW_REACTION_PANEL, payload)
    },
    [ACT_CHAT_SHOW_REACTION_PICKER]({commit}, payload) {
        commit(MUT_SHOW_REACTION_PICKER, payload)
    },
    [ACT_SET_SELECT_MODE]({commit}, msgIds = []) {
        commit(MUT_SET_SELECT_MODE, msgIds)
    },    
    [ACT_SET_SELECTED_MSGS]({commit}, msgIds = []) {
        commit(MUT_SET_SELECTED_MSGS, msgIds)
    },
    [ACT_DELETE_SELECTED_MSGS]({commit}, msgIds = []) {
        commit(MUT_DELETE_SELECTED_MSGS, msgIds)
    },
    async [ACT_SET_CURRENT_COMMENTS]({commit, getters}, {commentId}) {
        if (commentId !== getters[GET_COMMENT_ID]) {
            commit(MUT_SET_CURRENT_COMMENTS, [])
        }
        commit(MUT_SET_COMMENT_ID, commentId)
    },
    async [ACT_SET_MSG_COMMENT_SUBSCRIBED]({}, payload) {
        let {id, status = false} = payload
        await proto.setMessageCommentSubscribed({id, status})
    },
    [ACT_COMMENT_SUBSCRIBED]({ dispatch, commit }, payload) {
        const { id, status } = payload
        let message = { id, commentsSubscribed: status}
        commit('updateMessageProps', message)
        dispatch(`${INFO}/${ACT_INFO_CHANGE_PARAMS}`, {
            commentId: id,
            isCommentsSubscribed: status
        }, {root: true})
        dispatch(`${NOTIFICATIONS}/${ACT_OPEN_CUSTOM_NOTIFICATION}`, {
            type: 'message',
            title: i18n.t('chat.comments'),
            subtitle: status ? i18n.t('chat.subscribed-on') :  i18n.t('chat.unsubscribed-from'),
            commentId: id,
        }, {root: true})
    },
    async [ACT_COMMENT_SENT_RECEIVED]({getters, dispatch, commit}, payload) {
        payload.isIncrease = true
        const isCommentsSubscribed = payload && payload.commentsSubscribed
        const { commentId, cid, cidType } = payload
        let message = await dispatch(ACT_CHAT_GET_MESSAGE_BY_ID, {id: commentId, _cid: cid, _cidType: cidType })
        const isMsgCommentsSubscribed = message && message.commentsSubscribed
        if (isCommentsSubscribed && !isMsgCommentsSubscribed) {
            const params = { id: commentId, status: true }
            dispatch(ACT_SET_MSG_COMMENT_SUBSCRIBED, params)
            dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, { type: 'threads', payload }, {root: true})
        }
        else if (isCommentsSubscribed) {
            dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, { type: 'threads', payload }, {root: true})
        }
        commit(MUT_UPDATE_COMMON_MSG_COMMENT_COUNT, payload)
        if (commentId === getters[GET_COMMENT_ID]) commit('updateMessage', { commentId: payload.commentId, message: payload })
    },
    [ACT_COMMENT_REACTION]({commit}, payload) {
        commit(MUT_UPDATE_MESSAGE_REACTION, payload)
    },
    [ACT_COMMENT_UPDATE]({commit}, payload) {
        commit('updateMessage', { commentId: payload.commentId, message: payload })
    },
    [ACT_COMMENT_CHANGE]({state, getters, commit}, payload) {
        let { id } = payload
        if (!id) {
            if (payload && payload.settings && payload.settings.draft)
                id = payload.settings.draft.editId
            if (!id) return
        }
        commit('updateMessageProps', payload)
        const isDelete = payload.hasOwnProperty('deletedTime')
        if (isDelete) {
            commit(MUT_COMMENT_DELETE, payload)
            payload.isIncrease = false
            commit(MUT_UPDATE_COMMON_MSG_COMMENT_COUNT, payload)
        }
    },
}

const mutations = {
    setUpdating(state, isUpdating) {
        state.isUpdating = isUpdating
    },
    setCid(state, cid) {
        state.cid = cid;
    },
    updateCurrentChat(state, params) {
        if (isSameChat(state, params)) return;
        if ("cid" in params) state.cid = params.cid;
        if ("cidType" in params) state.cidType = params.cidType;
    },
    sortMessages(state, {commentId = 0}) {
        let state_msgs = commentId ? state.comments : state.messages
        state_msgs = state_msgs.sort((a, b) => {
            if (isNaN(a.id) || isNaN(b.id) || a.id === b.id) return 0
            if (a.id < b.id) return -1
            if (a.id > b.id) return 1
        })
    },
    updateMessage(state, params) {
        let commentId = params.commentId
        let message = params.message
        let message_id = message.id
        let state_msgs = commentId ? state.comments : state.messages
        let old_message = getMessageById(state_msgs, message_id)
        let index = old_message && state_msgs.indexOf(old_message)

        if (!old_message) {
            if (commentId && !message.time) message.time = 0
            message.timeDetails = convertTimeMsg(message)
            if (isSameChat({cid: state.cid, cidType: state.cidType}, message)) {
                state_msgs.push(message);
            }
        } else {
            Vue.set(state_msgs, index, Object.assign(old_message, message))
        }
    },
    
    [MUT_UPDATE_MESSAGE_REACTION](state, params) {
        const msgId = params.id, commentId = params.commentId, reactionStr = params.reaction || ''
        let state_msgs = commentId ? state.comments : state.messages
        let msg = getMessageById(state_msgs, msgId)
        if (!msg) return
        const cid = params.cid || msg.cid
        const senderId = params.senderId || cid
        let index = state_msgs.findIndex(m => m.id === msgId)
        const reaction = { cid: senderId, reaction: reactionStr, time: 0}
        const reactions = JSON.parse(JSON.stringify(msg.reactions || []))
        const reactionIndex = reactions.findIndex(r => r.cid === senderId)
        if (reactionIndex === -1 && senderId) {
            reactions.unshift(reaction)
        } else if (reactionIndex > -1) {
            reactions.splice(reactionIndex, 1)
            if (reactionStr) {
                reactions.unshift(reaction)
            }
        } else reactions.push(reaction)
        msg.reactions = reactions
        Vue.set(state_msgs, index, Object.assign(msg, msg))
    },
    [MUT_SHOW_REACTION_PANEL](state, params) {
        const { isShow, reactions, aggReactions, style } = params
        state.reactionsPanel.isPanelShow = isShow
        state.reactionsPanel.reactions.reactions = reactions
        state.reactionsPanel.reactions.aggReactions = aggReactions
        if (style) state.reactionsPanel.panelStyle = style
    },
    [MUT_SHOW_REACTION_PICKER](state, params) {
        const { isShow, msgId, style } = params
        state.reactionsPicker.isPickerShow = isShow
        state.reactionsPicker.msgId = msgId
        if (style) state.reactionsPicker.pickerStyle = style
    },
    updateMessageProps(state, message) {
        let message_id = message.id
        let { commentId } = message
        let state_msgs = commentId ? state.comments : state.messages
        let time = convertTimeMsg(message)
        let old_message = getMessageById(state_msgs, message_id)
        let index = old_message && state_msgs.indexOf(old_message)
        if (old_message) {
            if (!old_message.hasOwnProperty('timeDetails')) old_message.timeDetails = {}
            Object.assign(old_message.timeDetails, time)
        }
        const isDelete = message.hasOwnProperty('deletedTime')
        if (old_message && isDelete) {
            state_msgs.splice(index, 1)
        } else if (old_message) {
            Vue.set(state_msgs, index, Object.assign(old_message, message))
        }
    },
    [MUT_UPDATE_COMMON_MSG_COMMENT_COUNT](state, params) {
        const { commentId, isIncrease } = params
        const state_msgs = state.messages
        let common_message = getMessageById(state_msgs, commentId)
        if (!common_message) return
        let old_comments_count = +common_message.comments || 0
        let newCommentsCount = isIncrease ?  old_comments_count + 1 : old_comments_count - 1
        Vue.set(common_message, 'comments', newCommentsCount)
    },
    addMessage(state, params) {
        state.messages.push(params);
    },
    removeMessage(state, message_id) {
        var message = getMessageById(state.messages, message_id),
            index = state.messages.indexOf(message);
        if (index !== -1) state.messages.splice(index, 1);
    },
    clearMessages(state, {isComments = false}) {
        const state_msgs = isComments ? state.comments : state.messages
        state_msgs.splice(0, state_msgs.length);
    },
    [MUT_SELECTED_MESSAGE](state, {type, id = null, isThreads = false}) {
        let selected = id ? { type, id, isThreads } : null
        Vue.set(state, 'selectedMsg', selected)
    },
    [MUT_SET_MSG_ENTITIES](state, msgEntities = {}) {
        Vue.set(state, 'selectedMsgEntities', msgEntities)
    },
    addPoll(state, obj) {
        state.poll.id = obj.id;
        state.poll.question = obj.question;
        state.poll.answer = obj.answer;
    },
    updatePollVote(state, pollId) {
        state.pollVotes.push(pollId);
    },
    deletePollVote(state, pollId) {
        state.pollVotes.forEach(function(item, i) {
            if(item === pollId) delete state.pollVotes[i];
        });
    },
    [MUT_CHAT_SCROLL_UP](state, params) {
        state.scroll_up = params
    },
    [MUT_THREADS_SCROLL_UP](state, params) {
        state.threads_scroll_up = params
    },
    [MUT_CHAT_SET_SEARCH](state, str) {
        state.chatSearchString = str
    },
    [MUT_CHAT_SET_MEDIA_SEARCH](state, str) {
        state.mediaSearchString = str
    },
    [MUT_SET_SELECT_MODE](state, mode) {
        state.isSelectMode = mode
    },
    [MUT_SET_SELECTED_MSGS](state, msgIds) {
        state.selectedMessages = msgIds
    },
    [MUT_DELETE_SELECTED_MSGS](state, msgIds) {
        msgIds.forEach(m => {
            const index = state.selectedMessages.indexOf(m)
            if (index > -1) state.selectedMessages.splice(index, 1)
        })
    },
    [MUT_SET_CURRENT_COMMENTS](state, comments) {
        state.comments = comments //state.comments.concat(comments)
    },
    [MUT_SET_COMMENT_ID](state, commentId) {
        state.commentId = commentId
    },
    [MUT_COMMENT_DELETE](state, comment) {
        const index = state.comments.indexOf(comment)
        state.comments.splice(index, 1)
    },
}

function getSystemText ({state, getters, rootState, rootGetters}, data) {
    let editor
    let target
    let text
    switch (data.type) {
        case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_CREATED:
            editor = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
            text = editor.fio + ' ' + locale['created-chat']
            break
        case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_ADDED:
            editor = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.editorId)
            target = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
            text = editor.fio + locale.added + target.fio
            break
        case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_CHANGED:
            editor = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.editorId)
            target = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
            text = editor.fio + i18n.t('chat.group-chat.user-rights-changed') + target.fio
            break
        case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_DELETED:
            editor = data.editorId && rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.editorId)
            target = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
            text = (editor ? editor.fio + locale.removed + target.fio : target.fio + locale.left)
            break
        case declarations.msgSystemTypes.MSG_SYSTEM_TYPE_CHAT_UPDATED:
            editor = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
            if ('title' in data && data.title) {
                text = editor.fio + i18n.t('chat.group-chat.changed-group-name')
            }
            if ('icon' in data && typeof data.icon === 'string') {
                text = editor.fio + i18n.t('chat.group-chat.update-group-photo')
            }
            if ('pinMessage' in data && data.pinMessage > 0) {
                text = editor.fio + locale['s1-pinned-msg']
            }
            if ('pinMessage' in data && data.pinMessage === 0) {
                text = editor.fio + locale['unpinned-msg']
            }
            if ('readOnly' in data) {
                text = i18n.t(data.readOnly ? 'read-only-on-msg' : 'read-only-off-msg', {name: editor.fio})
            }
            if (!text) text = i18n.t('default-change-msg', {name: editor.fio})
            break
        default:
            text = locale['unknown-sys-msg']
    }
    return text
}

function getDataText ({state, getters, rootState, rootGetters}, data) {
    let text
    switch (data.type) {
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
            if (data.cid) {
                let contact = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](data.cid)
                if (contact) {
                    text = {
                        cid: data.cid,
                        fio: contact.fio,
                        photo: contact.photo,
                    }
                    if (data.file) text = {...text, file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file}
                }
            } else {
                const fields = Array.isArray(data.fields) && data.fields
                const phones = fields && fields.filter(f => f.type === CONTACT_FIELD_TYPES.PHONE || f.type === CONTACT_FIELD_TYPES.WORKPHONE)
                const phone = phones && phones.length && phones[0].value
                const mails = fields && fields.filter(f => f.type === CONTACT_FIELD_TYPES.MAIL)
                const mail = mails && mails.length && mails[0].value
                const fio = (fields && fields.fio) || phone || mail
                text = {
                    fio,
                    file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                }
            }

            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
            text = data
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
            text = {
                chId: data.chId,
                pubId: data.pubId,
                text: data.text,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
            text = {
                location_link_url: app.getLocationLinkUrl(data),
                location_image_url: app.getLocationImageUrl(data),
                longitude: data.longitude,
                latitude: data.latitude,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
            text = {
                size: data.size,
                extension: data.extension,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                preview: data.preview,
                name: data.name,
                text: data.text,
                previewFile: data.previewFile,
                previewSize: data.previewSize,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
            text = {
                size: data.size,
                extension: data.extension,
                duration: data.duration,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                preview: data.preview,
                name: data.name,
                text: data.text,
                previewFile: data.previewFile,
                previewSize: data.previewSize,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
            text = {
                size: data.size,
                extension: data.extension,
                duration: data.duration,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                name: data.name,
                text: data.text,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
            text = {
                size: data.size,
                extension: data.extension,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                name: data.name,
                text: data.text,
                readOnly: data.readOnly,
                key: data.key,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SURVEY:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SCHEDULE:
            text = data
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
            text = data.text
            break
        default:
            text = locale['unknown-msg-type']
    }
    return text
}

function getListDataText(data) {
    let text
    switch (data.type) {
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
            text = locale.modal['sent-contact']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
            text = locale.modal['sent-channel']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
            text = locale.modal['sent-post']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
            text = locale.modal['sent-geo']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
            text = locale.modal['sent-img']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
            text = locale.modal['sent-video']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
            text = locale.modal['sent-audio']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
            text = locale.modal['sent-file']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
            text = locale.modal['sent-poll']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
            text = locale.modal['sent-call-availability']
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SURVEY:
            text = locale.chat['survey']
            break  
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_SCHEDULE:
            text = locale.chat['schedule']
            if (data.eventType === 'invite') text += ': ' + locale.chat['schedule-names'].invite
            else text += ': ' + locale.chat['schedule-names']['appointment-start']
            break                    
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
            text = data.text
            break
        default:
            text = locale['unknown-msg-type']
    }
    return text
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
