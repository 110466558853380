//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {GET_SELECTED_CHANNEL, GET_INFO_OPEN} from "../../../store/gettersTypes";
import {ACT_INFO_PUSH, ACT_INFO_TOGGLE, ACT_INFO_CLEAR} from "../../../store/actionsTypes";
import {CHANNELS, INFO} from "../../../store/modulesNames";
import IconImage from '../../IconImage.vue';
import SelectChatToShare from "../../modal/SelectChatToShare.vue"
import ChannelPublicationEditor from "../../channels/ChannelPublicationEditor.vue"
import { i18n } from '../../../../ext/i18n'

export default {
    name: "MainHeaderChannel",
    components: {IconImage},
    data() {
        return {
            showTitle: false,
        }
    },
    computed: {
        channel: function () {
            return app.store.getters['channels/getChannel']({chId: this.ch_id})
        },
        photo: function () {
            return this.channel && this.channel.icon && app.getChannelPhotoUrl(this.channel.icon) || ''
        },
        getTitle() {
            return this.showTitle ? this.channel.name : ''
        },
        ...mapGetters(CHANNELS, {ch_id: GET_SELECTED_CHANNEL}),
        ...mapGetters(INFO, [GET_INFO_OPEN])
    },
    methods: {
        headerMouseOver(e) {
            if (e.target.className.indexOf('check-trim') > -1) {
                const oW = e.target.offsetWidth, sW = e.target.scrollWidth, pW = e.target.parentElement.offsetWidth
                oW < sW || pW < oW ? this.showTitle = true : this.showTitle = false 
            }
        },
        doMute: function () {
            this.modalOpen({
                name: 'channel-mute',
                props: {
                    chId: this.ch_id
                }
            })
        },
        doUnMute: function () {
            this.$store.dispatch("channels/delChannelSettingsParams", {chId: this.ch_id, settings: ['mute']});
        },
        doMenu: function (event, position) {
            let handlers = [],
                open = this.cmOpen;
            if (!this[GET_INFO_OPEN]) handlers.push({item_name: i18n.messages[i18n.locale].mainPage['channel-info'], handler: this.doToggleOpenInfo});
            handlers.push({
                item_name: i18n.messages[i18n.locale].mainPage.share, handler: () => {
                    this.channel && this.modalOpen({
                        component: SelectChatToShare,
                        props: {
                            type: declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL,
                            params: { ...this.channel }
                        }
                    })
                }
            });
            if (app.store.getters['channels/isMuted']({chId: this.ch_id})) {
                handlers.push({item_name: i18n.messages[i18n.locale].mainPage.unmute, handler: this.doUnMute});
            } else {
                handlers.push({item_name: i18n.messages[i18n.locale].mainPage.mute, handler: this.doMute});
            }
            if(this.channel && Channel.prototype.hasAdminRights(this.channel.privilege)) {
                handlers.push({
                    item_name: i18n.messages[i18n.locale].mainPage['new-post'], handler: () => { this.newPubEditor() }
                });
            }
            if (Channel.prototype.isOwner(this.channel.privilege)) {
                handlers.push({
                    item_name: i18n.messages[i18n.locale].mainPage['delete-channel'], handler: () => {
                        this.modalOpen({
                            name: 'delete-channel',
                            props: {chId: this.ch_id}
                        })
                    }
                });
            } else if (!Channel.prototype.canJoin(this.channel.status)) {
                handlers.push({
                    item_name: i18n.messages[i18n.locale].mainPage['leave-channel'], handler: () => {
                        this.modalOpen({
                            name: 'leave-channel',
                            props: {chId: this.ch_id}
                        })
                    }
                });
            }
            open(event, handlers, position);
        },
        newPubEditor() {
            this.channel && this.modalOpen({
                component: ChannelPublicationEditor,
                props: { chId: this.ch_id }
            })
        },
        doToggleOpenInfo() {
            const openedInfo = this[GET_INFO_OPEN]
            if (openedInfo && openedInfo.type === 'search-in-channel') this[ACT_INFO_CLEAR]()
            this[ACT_INFO_TOGGLE]({type: 'channel-info', params: {chId: this.ch_id}})
        },
        openSearch() {
            this[ACT_INFO_CLEAR]()
            this[ACT_INFO_PUSH]({type: 'search-in-channel', params: {chId: this.ch_id}})
        },
        _setSideVisible() {
            setSideVisible()
        },              
        ...mapActions(INFO, [ACT_INFO_PUSH, ACT_INFO_TOGGLE, ACT_INFO_CLEAR]),
    },
}
