var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "thread-wrapper",
      class: { leftSide: _vm.isLeftSide, isActive: _vm.isActive },
      on: { click: _vm.toggleThreads }
    },
    [
      _c("div", { staticClass: "thread-content" }, [
        _c("div", { staticClass: "thread-section-left" }, [
          _vm._m(0),
          _c("div", { staticClass: "thread-info" }, [
            _c("div", { staticClass: "thread-count" }, [
              _vm._v(_vm._s(_vm.count))
            ]),
            _c("div", { staticClass: "thread-str" }, [
              _vm._v(_vm._s(_vm.commentsText))
            ])
          ]),
          _c("div", { staticClass: "thread-read" }, [
            _vm.isUnread
              ? _c("i", { staticClass: "fas fa-circle" })
              : _c("i", { staticClass: "fal fa-circle" })
          ])
        ]),
        _vm._m(1)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "thread-icon-left" }, [
      _c("i", { staticClass: "fal fa-comment-dots" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "thread-section-right" }, [
      _c("div", { staticClass: "thread-icon-right" }, [
        _c("i", { staticClass: "far fa-greater-than" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }