import { render, staticRenderFns } from "./MainHeaderGroup.vue?vue&type=template&id=627f9e70&scoped=true&lang=pug&"
import script from "./MainHeaderGroup.vue?vue&type=script&lang=js&"
export * from "./MainHeaderGroup.vue?vue&type=script&lang=js&"
import style0 from "./MainHeaderGroup.vue?vue&type=style&index=0&id=627f9e70&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "627f9e70",
  null
  
)

export default component.exports