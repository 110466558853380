//
//
//
//
//
//
//
//
//
//
//
//
//
//

import event_bus from "../../../eventBus"
import MainContentContainer from "./MainContentContainer.vue"
import ListItem from "../../custom/CustomListItem.vue"
import HotKeys from '../../modal/HotKeys.vue'
import { HOTKEYS_USER_SETTINGS_DEFAULT } from "../../../../app/constants.js"
// #if process.env.WEBPACK_BUILD_TARGET === 'electron'
// import ipc from '../../../../electron/ipc'
// #endif

export default {
    name: "MainContentHotKeys",
    components: {ListItem, MainContentContainer, HotKeys},
    mounted() {
        if (!!localStorage.getItem('hotkeys')) {
            let storedHotKeys = JSON.parse(localStorage.getItem('hotkeys'))
            this.hotKeysUserSettings = storedHotKeys
        } else this.hotKeysUserSettings = this.hotKeysUserSettingsDefault

        event_bus.$off('hotkeys-changed')
        event_bus.$on('hotkeys-changed', data => { this.storeHotKeys(data)} )
    },
    data() {
        return {
            hotKeysUserSettings: [],
            hotKeysUserSettingsDefault: HOTKEYS_USER_SETTINGS_DEFAULT,
            oldShortcut: ''
        }
    },
    computed: {
        globalItems() {
            return this.hotKeysUserSettings.filter(i => i.global)
        },
        localItems() {
            return this.hotKeysUserSettings.filter(i => i.local)
        }
    },
    methods: {
        showModal(item) {
            this.oldShortcut = item.electronStr
            this.modalOpen({
                component: HotKeys,
                props: { hotKeysItem: item }
            })
        },
        storeHotKeys(newHotKeys) {
            if (!newHotKeys) return
            let hotKeysToChange = {}
            if (newHotKeys.global) {
                hotKeysToChange = this.hotKeysUserSettings.find(k => k.global && k.id === newHotKeys.id)
                // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//                 ipc.send('hotkeys-unregister-event', { shortcut: this.oldShortcut })
//                 ipc.send('hotkeys-change-global', { newHotKeys })
                // #endif
            }
            if (newHotKeys.local) { 
                hotKeysToChange = this.hotKeysUserSettings.find(k => k.local && k.id === newHotKeys.id)
                event_bus.$emit('assign-local-hotkeys')
            }
            hotKeysToChange.hotKeysStr = newHotKeys.hotKeysStr
            hotKeysToChange.electronStr = newHotKeys.electronStr
            localStorage.setItem('hotkeys', JSON.stringify(this.hotKeysUserSettings))
        },
        getTitle(hotkeysAction, isGlobal) {
            return this.getHotKeysTitleByAction(hotkeysAction, isGlobal)
        }
    }
}
