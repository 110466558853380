import { lamejs } from './lame.min.js'

let audioContext

export async function trimAudio(audioFile, startTime = 0, endTime = 3) {
    if (audioFile) {
        try {
            audioContext = new (window.AudioContext || window.webkitAudioContext)()
            const fileBuffer = await audioFile.arrayBuffer()
            const audioBuffer = await audioContext.decodeAudioData(fileBuffer)
            return trimAudioBuffer(audioBuffer, startTime, endTime)
        } catch (error) {
            console.error('Error trimming audio:', error)
        }
    } else {
        console.error('Please select an audio file.')
    }
}

function trimAudioBuffer(buffer, startTime, endTime) {
    const sampleRate = buffer.sampleRate
    const startFrame = startTime * sampleRate
    const endFrame = endTime * sampleRate
    // const duration = endTime - startTime
    const channels = buffer.numberOfChannels
    const trimmedBuffer = audioContext.createBuffer(channels, (endFrame - startFrame), sampleRate)
    for (let channel = 0; channel < channels; channel++) {
        const sourceData = buffer.getChannelData(channel).subarray(startFrame, endFrame)
        trimmedBuffer.getChannelData(channel).set(sourceData)
    }
    return trimmedBuffer
}

export function audioBufferToWav(buffer, opt) {
    opt = opt || {}

    let numChannels = buffer.numberOfChannels
    let sampleRate = buffer.sampleRate
    let format = opt.float32 ? 3 : 1
    let bitDepth = format === 3 ? 32 : 16

    let result
    if (numChannels === 2) {
        result = interleave(buffer.getChannelData(0), buffer.getChannelData(1))
    } else {
        result = buffer.getChannelData(0)
    }
    return encodeWAV(result, format, sampleRate, numChannels, bitDepth)
}

function encodeWAV(samples, format, sampleRate, numChannels, bitDepth) {
    let bytesPerSample = bitDepth / 8
    let blockAlign = numChannels * bytesPerSample

    let buffer = new ArrayBuffer(44 + samples.length * bytesPerSample)
    let view = new DataView(buffer)

    /* RIFF identifier */
    writeString(view, 0, 'RIFF')
    /* RIFF chunk length */
    view.setUint32(4, 36 + samples.length * bytesPerSample, true)
    /* RIFF type */
    writeString(view, 8, 'WAVE')
    /* format chunk identifier */
    writeString(view, 12, 'fmt ')
    /* format chunk length */
    view.setUint32(16, 16, true)
    /* sample format (raw) */
    view.setUint16(20, format, true)
    /* channel count */
    view.setUint16(22, numChannels, true)
    /* sample rate */
    view.setUint32(24, sampleRate, true)
    /* byte rate (sample rate * block align) */
    view.setUint32(28, sampleRate * blockAlign, true)
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, blockAlign, true)
    /* bits per sample */
    view.setUint16(34, bitDepth, true)
    /* data chunk identifier */
    writeString(view, 36, 'data')
    /* data chunk length */
    view.setUint32(40, samples.length * bytesPerSample, true)
    if (format === 1) { // Raw PCM
        floatTo16BitPCM(view, 44, samples)
    } else {
        writeFloat32(view, 44, samples)
    }

    return buffer
}

function interleave(inputL, inputR) {
    let length = inputL.length + inputR.length
    let result = new Float32Array(length)

    let index = 0
    let inputIndex = 0

    while (index < length) {
        result[index++] = inputL[inputIndex]
        result[index++] = inputR[inputIndex]
        inputIndex++
    }
    return result
}

function writeFloat32(output, offset, input) {
    for (let i = 0; i < input.length; i++, offset += 4) {
        output.setFloat32(offset, input[i], true)
    }
}

function floatTo16BitPCM(output, offset, input) {
    for (let i = 0; i < input.length; i++, offset += 2) {
        let s = Math.max(-1, Math.min(1, input[i]))
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true)
    }
}

function writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i))
    }
}

export function wavToMp3Blob(wav) {
    const wavHdr = lamejs.WavHeader.readHeader(new DataView(wav))
    // console.log("🚀 ~ file: sound-utils.js:129 ~ wavToMp3Blob ~ wavHdr:", wavHdr)
    const wavSamples = new Int16Array(wav, wavHdr.dataOffset, wavHdr.dataLen / 2)
    let retBlob
    if (wavHdr.channels === 2) {
        const leftData = [], rightData = []
        for (let i = 0; i < wavSamples.length; i += 2) {
          leftData.push(wavSamples[i])
          rightData.push(wavSamples[i + 1])
        }
        const left = new Int16Array(leftData), right = new Int16Array(rightData)
        retBlob = wavToMp3(wavHdr.channels, wavHdr.sampleRate, left, right)
    } else retBlob = wavToMp3(wavHdr.channels, wavHdr.sampleRate, wavSamples)
    return retBlob
}

function wavToMp3(channels, sampleRate,  left, right = null) {
    let buffer = []
    let mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128)
    let remaining = left.length
    let samplesPerFrame = 1152
    for (let i = 0; remaining >= samplesPerFrame; i += samplesPerFrame) {
        let mp3buf
        if (!right) {
          let mono = left.subarray(i, i + samplesPerFrame)
          mp3buf = mp3enc.encodeBuffer(mono)
        } else {
          let leftChunk = left.subarray(i, i + samplesPerFrame)
          let rightChunk = right.subarray(i, i + samplesPerFrame)
          mp3buf = mp3enc.encodeBuffer(leftChunk, rightChunk)
        }
        if (mp3buf.length > 0) {
          buffer.push(mp3buf) //new Int8Array(mp3buf));
        }
        remaining -= samplesPerFrame
      }
      let d = mp3enc.flush()
      if (d.length > 0) {
        buffer.push(new Int8Array(d))
      }

    let mp3Blob = new Blob(buffer, {type: 'audio/mp3'})
    return mp3Blob
}