/** Blob **/
export const GET_FILE_BLOB = 'getFileBlob';

/** bots **/
export const GET_BOT_BY_ID = 'bot_by_id';
export const GET_BOTS = 'get_bots';
export const GET_KEYBOARD_CHATS = 'get_keyboard_chats';

/** Login **/
export const GET_LOGIN_STATUS = 'getLoginStatus';
export const GET_IS_LOGINED = 'is_logined';
export const GET_IS_LOADED = 'is_loaded';
export const GET_LOGIN_DATA = 'login_data';
export const GET_LOGIN_ERROR_TEXT = 'login_error';
export const GET_DAYS_IN_OPERATION = 'getDaysInOperation';
export const GET_SERVER_API = 'getServerAPI';
export const GET_OAUTH2_ENABLED_TYPES = 'getOauth2EnabledTypes';
export const GET_OAUTH2_PARAMS_BY_TYPE = 'getOauth2ParamsByType';
export const IS_TOKENS_SET = 'isTokensSet';
export const GET_CONTACT_DISPLAY_NAME = 'getContactDisplayName';

/** Socket **/
export const GET_CONNECTION_STATUS = 'getConnectionStatus';
export const SOCKET_IS_CONNECTED = 'socketIsConnected';

/** ContentManager **/
export const GET_OPENED_MAIN_TYPE = 'getOpenedMainType';
export const GET_MAIN_TYPE_CAN_BACK = 'getMainTypeCanBack';
export const GET_SIDE_TYPE = 'getSideType';
export const GET_MAIN_TYPE = 'getMineType';
export const GET_ACTIVITY_TYPE = 'getActivityType';
export const GET_DOCUMENT_HIDDEN = 'getDocumentHidden';
export const GET_DOCUMENT_FOCUS = 'getDocumentFocus';
export const GET_SEARCH_OPENED = 'getSearchOpened';
export const GET_INFO_NOTIFICATION = 'getInfoNotification';
export const GET_SIDE_BAR_NOTIFICATION = 'getSideBarNotification'
export const GET_IS_MAIN_TYPE_DELAYED = 'getIsMainTypeDelayed'

/** Contacts **/
export const GET_ROLE_REVISION = 'getRoleRevision'
export const GET_CONTACTS_GLOBAL = 'contacts';
export const GET_CONTACTS_MERGED = 'getContactsMerged';
export const GET_CONTACTS_LOCAL = 'getLocalContacts';
export const GET_SELECTED_CONTACT = 'selected';
export const GET_FAVOURITES = 'favourites';
export const GET_FAVOURITES_CIDS = 'getFavouritesCids';
export const FAVOURITES_IN_CHANGE_MODE = 'favouritesInChangeMode';
export const GET_LIST_OF_BIRTHDAYS = 'getListOfBirthdays';
export const GET_SUPPORT = 'getSupport';
export const FIND_CONTACT_BY_ID = 'findContactById';
export const GET_CONTACT_BY_ID = 'getContactById';
export const GET_LOCAL_CONTACT_BY_ID = 'getLocalContactById';
export const IS_CONTACT_HAVE_LOCAL_CHANGES = 'isContactHaveLocalChanges';
export const IS_CONTACT_LOCAL_ONLY = 'isContactLocalOnly';
export const GET_MERGED_CONTACT_BY_ID = 'getMergedContactById';
export const GET_MERGED_CONTACT_BY_PHONE = 'getMergedContactByPhone';
export const GET_MERGED_CONTACTS_BY_IDS = 'getMergedContactsByIds';
export const GET_CONTACT_STATUS_TEXT = 'getContactStatusText';
export const GET_MERGED_CONTACT_BY_EMAIL = 'getMergedContactByEmail';
export const GET_FILTER_VALUES_LIST_BY_TYPE = 'getFilterValuesListByType';
export const GET_MY_CONTACT = 'getMyContact';
export const GET_MY_FULL_NAME = 'getMyFullName';
export const GET_PHONE_LIST_BY_ID = 'getPhoneListById';
export const GET_MAILS_LIST_BY_ID = 'getMailsListById';
export const GET_CONTACT_BY_PHONE = 'getContactByPhone';
export const GET_IS_ROLES_SUPPORTED = 'getIsRolesSupported'
export const GET_IS_SEARCH_FILTER_SUPPORTED = 'getIsSearchFilterSupported'
export const GET_CONTACTS_FILTER_BY_NAME = 'getSettingsFilter'
export const GET_ROLES_REVISION = 'getRolesRevision'
export const GET_LOCAL_CHANGED_FIELDS = 'getLocalChangedFields'

/** Channels **/
export const GET_CHANNELS = 'getChannels'
export const GET_SELECTED_CHANNEL = 'selected'
export const GET_IS_CHANNEL_OPENED = 'isChannelSelected'
export const GET_CHANNEL = 'getChannel'
export const GET_HAVE_ADMIN_RIGHTS = 'getHaveAdminRights'
export const GET_CHANNEL_MUTE_TIME = 'getChannelMuteTime'
export const IS_CHANNEL_PINNED = 'isChannelPinned'
export const GET_CHANNELS_TOTAL_UNWATCHED = 'getTotalUnwatched'

/** Channel **/
export const GET_SCROLL_TO_PUBLICATION = 'selected';
export const GET_CHANNEL_SEARCH_STRING = 'getChannelSearchString'

/** Chats **/
export const GET_SELECTED_CHAT = 'selected';
export const GET_CHATS = 'getChats';
export const GET_CHAT = 'getChat';
export const GET_CHAT_MEMBERS = 'getChatMembers';
export const GET_IS_CHAT_MEMBER = 'getIsChatMember';
export const IS_CHAT_ADMIN = 'isChatAdmin';
export const GET_IS_CHAT_OPENED = 'isChatSelected';
export const GET_CHAT_MUTE_TIME = 'getChatMuteTime';
export const IS_CHAT_READ_ONLY = 'isChatReadOnly';
export const CAN_WRITE_TO_CHAT = 'canWriteToChat';
export const GET_CHAT_TYPINGS = 'getChatTypings';
export const GET_CHAT_TYPING_CONTACTS = 'getChatTypingContacts';
export const GET_CHAT_MEMBER_INDEX = 'getChatMemberIndex';
export const GET_CHAT_SAVED_DATA = 'getChatSavedData';
export const GET_CHAT_DRAFT = 'getChatDraft';
export const GET_CHAT_POSITION = 'getChatPosition';
export const GET_CHAT_COMMENT_POSITION = 'getChatCommentPosition';
export const IS_OWN_CHAT = 'isOwnChat';
export const IS_NOTE_CHAT = 'isNoteChat';
export const GET_CHAT_LAST_MESSAGE = 'getChatLastMessage';
export const GET_CHAT_BY_LAST_MESSAGE = 'getChatByLastMessage';
export const GET_CHAT_ATTACHED_MESSAGE_ID = 'getChatAttachedMessageId';
export const GET_CHAT_SELECTED_MSG_ENTITIES = 'getChatSelectedMessageEntities';
export const IS_CHAT_PINNED = 'isChatPinned'
export const GET_CHATS_TOTAL_UNWATCHED_MESSAGES = 'getTotalUnwatchedMessages'

/** Chat **/
export const GET_MESSAGE_BY_ID = 'getMessageById';
export const GET_CHAT_MESSAGES = 'getChatMessages';
export const GET_CHAT_SCROLL_UP = 'getChatScrollUp';
export const GET_THREADS_SCROLL_UP = 'getThreadsScrollUp';
export const IS_CHAT_HAVE_LAST_MESSAGE = 'isChatHaveLastMessage';
export const IS_CHAT_NEW_API_SUPPORTED = 'isChatNewApiSupported';
export const GET_CHAT_EDIT_MESSAGE_ID = 'getChatEditMessageId';
export const GET_CHAT_REPLY_MESSAGE_ID = 'getChatReplyMessageId';
export const GET_CHAT_IS_SYSTEM_MESSAGE = 'getChatIsSystemDataType'
export const GET_CHAT_SEARCH_STRING = 'getChatSearchString'
export const GET_CHAT_MEDIA_SEARCH_STRING = 'getMediaSearchString'
export const GET_CHAT_ITEM_TEXT = 'getChatItemText'
export const GET_SELECT_MODE = 'getSelectMode'
export const GET_SELECTED_MSGS = 'getSelectedMessages'
export const GET_REACTIONS_PANEL = 'getReactionsPanel'
export const GET_REACTIONS_PICKER = 'getReactionsPicker'
export const GET_CURRENT_COMMENTS = 'getCurrentComments'
export const GET_COMMENT_BY_ID = 'getCommentById'
export const GET_COMMENT_ID = 'getCommentId'

/** Calls **/
export const GET_CALLS_HISTORY_LIST = 'getCallsHistoryList'
export const GET_CALLS_UNWATCHED_CALLS = 'getUnwatchedCalls'
export const GET_CALLS_UNWATCHED_CALLS_COUNT = 'getUnwatchedCallsCount'
export const GET_CALLS_WATCHED_CALLS_STATUS = 'getWatchedCallsStatus'

/** UserData **/
export const GET_HOST = 'getHost';
export const GET_ROSCHAT_SERVER = 'getRoschatServer';
export const GET_ROSCHAT_LAST_SERVER = 'getRoschatLastServer';
export const GET_ROSCHAT_REDIRECTED_SERVER = 'getRoschatRedirectedServer';
export const GET_UPDATE_SERVER_URL = 'getUpdateServerUrl'
export const GET_USER_PARAMS = 'getUserParams';
export const GET_UID = 'getUid';
export const GET_LOGIN = 'getLogin';
export const GET_WS_PORT = 'getWsPort';
export const GET_OAUTH2_SETTINGS = 'getOauth2Settings';
export const IS_SELF_REGISTRATION_ENABLED = 'isSelfRegistrationEnabled';
export const GET_LAST_TIME_ACTIVE = 'getLastTimeActive'
export const GET_MINUTES_RELATIVE_TO_TIME = 'getMinutesRelativeToTime'
export const GET_INTEGRATION_SETTINGS = 'getIntegrationSettings'
export const GET_CURRENT_SERVER_ID = 'getCurrentServerId'
export const GET_MAX_UPLOAD_FILESIZE = 'getMaxUploadFileSize'
export const GET_SERVER_DISPLAY_NAME = 'getServerDisplayName'
export const GET_SERVER_REMOTE_HOST = 'getServerRemoteHost'
export const GET_PHOTO_URL = 'getPhotoUrl';
export const GET_PHOTO_URL_BY_CID = 'getPhotoUrlbyCid';
export const GET_REMOTE_PHOTO_URL_BY_CID = 'getRemotePhotoUrlbyCid'
export const GET_LOCAL_PHOTO_URL = 'getLocalPhotoUrl';
export const GET_CURRENT_PANE = 'currentPane';
export const GET_SEND_BY_ENTER = 'getSendByEnter';
export const GET_BIRTHDAY_NOTIFY = 'getBirthdayNotify';
export const GET_SEARCHED_CONTACTS = 'getSearchedContacts';
export const GET_RING_SOUND = 'getRingSound'
export const GET_VOLUME_RING = 'getVolumeRing'
export const GET_HOLD_SOUND = 'getHoldSound'
export const GET_SECOND_CALL_SOUND = 'getSecondCallSound'
export const GET_NOTIFICATION_SOUND = 'getNotificationSound'
export const GET_VOLUME_NOTIFICATION = 'getVolumeNotification'
export const GET_RADIO_SOUND = 'getRadioSound'
export const GET_VOLUME_RADIO = 'getVolumeRadio'
export const GET_VOLUME_AUDIO = 'getVolumeSound';
export const GET_VOLUME_MICROPHONE = 'getVolumeMicrophone';
export const GET_WEBCAMERA_ENABLE = 'getWebcameraEnable';
export const GET_MICROPHONE_ENABLE = 'getMicrophoneEnable';
export const GET_ACTIVE_SPEAKERS = 'getActiveSpeakers';
export const GET_ACTIVE_MICROPHONE = 'getActiveMicrophone';
export const IS_MICROPHONE_ENABLED = 'isMicrophoneEnabled';
export const GET_ACTIVE_WEBCAMERA = 'getActiveWebcamera';
export const GET_MEDIA_DEVICES = 'getMediaDevices';
export const GET_MEDIA_DEVICES_BY_KIND = 'getMediaDevicesByKind';
export const GET_ACTIVE_MEDIA_DEVICES = 'getActiveMediaDevices';
export const GET_MEDIA_DEVICE_LAST_OK = 'getMediaDeviceLastOk';
export const GET_ACS_NAME = 'getAcsName';

/** Acs **/
export const GET_ACS_CID = 'getCid';
export const GET_ACS_CONTACTS = 'getContacts';
export const GET_ACS_ROLE = 'getAcsRole';
export const GET_ACS_TYPE = 'getType';
export const GET_ACS_IS_ADMIN = 'getIsAdmin';
export const GET_ACS_OM_LOGIN = 'getOmLogin';
export const GET_ACS_OM_PASSWORD = 'getOmPassword';
export const GET_ACS_OM_IS_ADMIN = 'getOmIsAdmin';
export const GET_IN_ACS = 'inAcs';
export const GET_ACS_SELF_EDIT_RIGHTS = 'hasSelfEditRights';
export const GET_ACS_EDIT_RIGHTS = 'hasEditRights';
export const GET_ACS_INFO = 'getAcsInfo';
export const GET_ACS_CURRENT_JOURNAL_ENTRY = 'getAcsCurrentJournalEntry';

/** Alarm **/
export const GET_ALARM_PROPS = 'getAlarmProps';

/** Phone **/
export const GET_PHONE_SHOW = 'getPhoneShow';
export const GET_PHONE_ACTIVE_CALL = 'getPhoneActiveCall';
export const GET_PHONE_CALL = 'getPhoneCall';
export const GET_PHONE_CALL_SERVER_ID = 'getPhoneCallServerId';
export const GET_PHONE_CALL_WORKER = 'getPhoneCallWorker';
export const GET_PHONE_RTC_OPTIONS = 'getPhoneRtcOptions';
export const GET_PHONE_SIP_PAIR = 'getPhoneSipPair';
export const GET_PHONE_CONF_CALL = 'getPhoneConfCall';
export const GET_PHONE_CALL_MOS = 'getPhoneCallMos'

/** Locations **/
export const GET_LOCATIONS_CONFIG = 'getLocationsConfig';
export const GET_LOCATIONS_REGION = 'getLocationsRegion';
export const GET_LOCATION = 'getLocation';
export const GET_LOCATION_IN_LOADING = 'getLocationInLoading';

/** ClientData **/
export const GET_SID = 'getSocketId';
export const GET_IS_ELECTRON = 'getIsElectron';
export const GET_CLIENT_VERSION = 'getClientVersion';
export const GET_NEW_CLIENT_INFO = 'getNewClientInfo';
export const GET_CLIENT_CHANGES = 'getClientChanges'
export const SHOW_NEW_VERSION_NOTIFY = 'showNewVersionNotify';
export const GET_CLIENT_UPDATE_STATUS = 'getClientUpdateStatus';
export const GET_CLIENT_DOWNLOAD_SIZE = 'getClientDownloadSize';
export const GET_CLIENT_DOWNLOAD_CURRENT_SIZE = 'getClientDownloadCurrentSize';
export const GET_CSS_PROPERTY_SUPPORTED = 'getCssPropertySupported';
export const GET_LOCALE = 'getLocale';
export const GET_CURRENT_TIME = 'getCurrentTime';
export const GET_CURRENT_DAY = 'getCurrentDay'
export const GET_FONTS_SETTINGS = 'getFontsSettings'

/** Info **/
export const GET_INFO_OPEN = 'getInfoOpen';
export const GET_INFO_CAN_BACK = 'getInfoCanBack';
export const GET_INFO_HISTORY_LENGTH = 'getInfoHistoryLength';
export const GET_INFO_LAST_ACTION = 'getInfoLastAction';
export const GET_INFO_PAYLOAD_BY_MAIN_TYPE = 'getCurrentMainTypeInfo';
export const GET_IS_FIRST_TIME_OPENED = 'getIsFirstTimeOpened'
export const GET_IS_COMPACT_MODE = 'getIsCompactMode'
export const GET_INFO_MUST_BE_OPENED = 'getInfoMustBeOpened'
export const GET_INFO_IS_THREADS_OPENED = 'getInfoIsThreadsOpened'
/** Modal **/
export const GET_MODALS = 'getModals';

/** Notification **/
export const GET_NOTIFICATION = 'getNotification';
export const GET_CUSTOM_NOTIFICATION_DATA = 'getCustomNotificationData';
export const GET_NOTIFICATION_ELECTRON_STATUS = 'getNotificationElectronStatus';
export const GET_NOTIFICATION_DEFAULT_STATUS = 'getNotificationDefaultStatus';

/** Radio **/
export const GET_RADIO_PARAMS = 'getRadioParams';
export const GET_CURRENT_RADIO = 'getCurrentRadio';
export const RADIO_IS_TURN_ON = 'radioIsTurnOn';
export const GET_CURRENT_RADIO_CON_PARAMS = 'getCurrentRadioConParams';
export const GET_CURRENT_RADIO_TYPE = 'getCurrentRadioType';
export const GET_SPEECH_PACK_RATE = 'getRadioIncSpeechRate';
export const GET_SPEECH_BUFFER_EMPTY = 'getSpeechBufferEmpty';
export const GET_DUPLEX_SPEECH_BUFFER_EMPTY = 'getDuplexSpeechBufferEmpty';
export const GET_PTT_POP_AWAITING = 'getPttPopAwaiting';
export const GET_OUT_SPEECH_RATE = 'getRadioOutSpeechRate';
export const GET_RADIO_SETTINGS = 'getRadioSettings';
export const GET_RADIO_TOKEN = 'getRadioToken'

/** RcRadio **/
export const RC_RADIO_IS_TURN_ON = 'rcRadioIsTurnOn';
export const GET_RC_RADIO_CONNECTION_STATUS = 'getRcRadioConnectionStatus';
export const GET_RC_RADIO_GROUPS = 'getTetraGroups';
export const GET_RC_RADIO_ACTIVE_GROUP = 'getTetraActiveGroup';
export const GET_RC_RADIO_PTT_PUSHED = 'getRcRadioIsTurnOn';
export const GET_RC_RADIO_CONTACTS = 'getRcRadioContacts';
export const GET_RC_RADIO_CHANNEL_STATUS = 'getRcRadioChannelStatus';
export const GET_RC_RADIO_GRANT_CID = 'getRcRadioGrantCid';
export const GET_RC_RADIO_GRANT_STATUS = 'getRcRadioGrantStatus';
export const GET_RC_RADIO_ECHO = 'getRcRadioEcho';
export const GET_RC_RADIO_TOKEN = 'getRcRadioToken'

/** TetraRadio **/
export const GET_TETRA_PARAMS = 'getTetraParams';
export const GET_TETRA_CONNECTION_PARAMS = 'getTetraConnectionParams';
export const TETRA_IS_TURN_ON = 'tetraIsTurnOn';
export const GET_TETRA_CONNECT_STATUS = 'getTetraConnectStatus';
export const GET_TETRA_CELL = 'getTetraCell';
export const GET_TETRA_CONNECT_ERROR = 'getTetraConnectError';
export const GET_TETRA_LINK_STATUS = 'getTetraLinkStatus';
export const GET_TETRA_LINK_ERROR = 'getTetraLinkError';
export const GET_TETRA_GROUPS = 'getTetraGroups';
export const GET_TETRA_ACTIVE_GROUP = 'getTetraActiveGroup';
export const GET_TETRA_PTT_PUSHED = 'getTetraPttPushed';
export const GET_TETRA_CALL = 'getTetraCall';
export const GET_TETRA_ECHO = 'getTetraEcho';
export const GET_TETRA_TOKEN = 'getTetraToken'
export const GET_TETRA_SEARCHED_USERS = 'getTetraSearchedUsers'
export const GET_TETRA_USERS_LIST = 'getTetraUsersList'
export const GET_TETRA_GROUP_USERS = 'getTetraGroupUsers'
export const GET_TETRA_USER_INFO = 'getTetraUserInfo'
export const GET_TETRA_USER_STATUS = 'getTetraUserStatus'
export const GET_TETRA_MESSAGES = 'getTetraMessages'
export const GET_TETRA_MESSAGE_STATUS = 'getTetraMessageStatus'
export const GET_TETRA_USER_UNWATCHED_MESSAGES_COUNT = 'getTetraUserUnwatchedMessagesCount'
export const GET_TETRA_UNWATCHED_MESSAGES_COUNT = 'getTetraUnwatchedMessagesCount'
export const GET_TETRA_IS_CONNECTED = 'getTetraIsConnected'
export const GET_TETRA_OPENED_CHAT_SSI = 'getTetraOpenedChatSsi'
export const GET_TETRA_DUPLEX_CALLS = 'getTetraDuplexCalls'
export const GET_TETRA_ACTIVE_DUPLEX_CALL = 'getTetraActiveDuplexCall'
export const GET_TETRA_DUPLEX_CALL_BY_SSI = 'getTetraDuplexCallBySsi'

/** VideoConf **/
export const GET_ROOM_ID = 'getRoomId';
export const GET_PROCESSINGS = 'getProcessing';
export const GET_CONTACTS = 'getContactsVideoConf';
export const GET_OPTIONS = 'getOptionssVideoConf';
export const GET_ANSWER_STATUS = 'getAnswerStatus';
export const GET_CREATOR_CONF = 'getCreatorConf';
export const GET_TURN_SERVER = 'getTurnServer';
export const GET_HOLD_CONF = 'getHoldConf'
export const GET_COMPONENT_DATA = 'getComponentData'
export const GET_LOCALSTREAM = 'getLocalStream'
export const GET_PEER_CONNECTION = 'getPeerConnection'
export const IS_ADD_DIALOG_OPENED = 'isAddDialogOpened'
export const IS_CONF_ROOM_FULL = 'isConfRoomFull'

/** Asistant **/
export const GET_CALLS = 'getCalls';
export const GET_ASSISTANT_CALL_BY_ID = 'getAssistantCallById';
export const GET_STATUS = 'getStatus';
export const GET_MICROPHONE = 'getMicrophone';
export const GET_SPEAKER = 'getSpeaker';
export const GET_ASISTANT_DND = 'getDnd';
export const GET_TYPE_ASISTANT = 'getTypeAssistant'
export const GET_ASSISTANTS = 'getAssistants'
export const GET_ACTIVE_CALL = 'getActiveCall'
export const GET_NUMBER_ASSISTANT = 'getNumberAssistant'
export const GET_ACTIVE_NUMBER = 'getActiveNumber'
export const GET_ASSISTANT_CALL_PARTICIPANTS = 'getAssistantCallParticipants'

/** PHONE-CONTROLLER **/
export const GET_OPEN_DIALER = 'getOpenDialer';
export const GET_OPEN_INCOMMING_CALL = 'getOpenIncommingCall';
export const GET_DIALER_NUMBER = 'getDialerNumber';
export const GET_ANSWERED_CALLS = 'getAnsweredCalls'
export const GET_ANSWERED_CALLS_BY_TYPE = 'getAnsweredCallsByType'
export const GET_IS_PHONE_MINIMIZED = 'getIsPhoneMinimized'
export const GET_PHONE_IN_FULLSCREEN = 'getPhoneInFullscreen'
export const GET_ACTIVE_ANSWERED_CALL = 'getActiveAnsweredCall'
export const GET_ANSWERED_CALL = 'getAnsweredCall'
export const GET_CALL_PARTICIPANTS = 'getCallParticipants'
export const GET_CALL_TALK_TITLE = 'getCallTalkTitle'
export const GET_CALL_TALK_TIME = 'getCallTalkTime'
export const GET_INCOMING_CALL = 'getIncomingCall'
export const GET_INCOMING_CALLS = 'getIncomingCalls'
export const GET_CALL_STATUS = 'getCallStatus'
export const GET_INCOMING_CALLS_PRIORITY = 'getIncomingCallsPriority'

/** AJAX **/
export const GET_LAST = 'getLast'
export const AJAX_CALL_IS_EXIST = 'pending'
export const GET_AJAX_CALL_PROGRESS = 'progress'
export const GET_AJAXX_CALL_IS_EXIST_BY_FILE = 'getAjaxCallIsExistByFile'
export const GET_AJAXX_CALL_PROGRESS_BY_FILE = 'getAjaxCallProgressByFile'

/** User Profile **/
export const GET_DND = 'getDnd';
export const GET_EXP_TIME = 'getExpTime';
export const GET_CONFSCALENDAR = 'getConfsCalendar'

/** VideoConfNew **/
export const GENERATE_VC_CONF_PROPS = 'generateVcConfProps'
export const GET_VC_CONF = 'getVcConf'
export const GET_VC_CONFS = 'getVcConfs'
export const IS_VC_MINIMIZED = 'isVcMinimized'
export const IS_VC_IN_FULLSCREEN = 'isVcInFullscreen'
export const GET_VC_ACTIVE_CONF = 'getVcActiveConf'
export const GET_VC_CONF_TALK_TIME = 'getVcConfTalkTime'
export const GET_VC_CONF_STATUS = 'getVcConfStatus'
export const GET_VC_CONF_ERROR_TEXT = 'getVcConfErrorText'
export const GET_VC_CONF_MIC_STATUS = 'getVcConfMicStatus'
export const GET_VC_CONF_CAM_STATUS = 'getVcConfCamStatus'
export const GET_VC_CONF_SPEAKER_STATUS = 'getVcConfSpeakerStatus'
export const GET_VC_CONF_INFO = 'getVcConfInfo'
export const GET_VC_CONF_OWN_MEMBER = 'getVcOwnMember'
export const GET_VC_CONF_MEMBERS = 'getVcConfMembers'
export const IS_VC_CONF_MODERATOR = 'isVcConfModerator'
export const IS_VC_CONF_OWNER = 'isVcConfOwner'
export const GET_VC_CONF_SHARE = 'getVcConfShare'
export const IS_VC_OWN_SHARE = 'isVcOwnShare'
export const GET_VC_CONF_HANDUP = 'getVcConfHandup'
export const IS_VC_BANNED = 'isVcBanned'
export const GET_VC_CONF_MEDIA_DEVICE_BY_KIND = 'getVcConfMediaDeviceByKind'
export const GET_VC_CONF_INVITE_TEXT = 'getVcConfInviteText'
export const GET_VC_CONF_DIALOG_OPENED = 'getVcConfDialogOpened'
export const GET_VC_CONF_LINK_BY_PARAMS = 'getVcConfLinkByParams'

/** ConfAbstract **/
export const GENERATE_CONF_PROPS = 'generateConfProps'
export const GET_CONF = 'getConf'
export const GET_CONFS = 'getConfs'
export const GET_CONF_STATUS = 'getConfStatus'
export const GET_CONF_MEMBERS = 'getConfMembers'
export const GET_CONF_OWN_MEMBER = 'getOwnMember'
export const GET_CONF_MIC_STATUS = 'getVmConfMicStatus'
export const GET_CONF_CAM_STATUS = 'getVmConfCamStatus'
export const GET_CONF_SPEAKER_STATUS = 'getConfSpeakerStatus'
export const GET_CONF_INFO = 'getVmConfInfo'
export const GET_CONF_LINK = 'getVmConfLink'
export const GET_CONF_MEMBERS_COUNT = 'getVmConfMembersCount'
export const IS_CONF_MODERATOR = 'isConfModerator'
export const IS_CONF_OWNER = 'isConfOwner'
export const GET_CONF_SHARE = 'getConfShare'
export const GET_CONF_HANDUP = 'getConfHandup'
export const IS_OWN_SHARE = 'isOwnShare'
export const IS_BANNED = 'isBanned'
export const GET_CONF_MEDIA_DEVICE_BY_KIND = 'getConfMediaDeviceByKind'
export const GET_CONF_INVITE_TEXT = 'getConfInviteText'
export const GET_CONF_DIALOG_OPENED = 'getConfDialogOpened'
export const GENERATE_CONF_LINK = 'generateConfLink'
export const GET_CONF_ERROR_TEXT = 'getConfErrorText'


/** ConfVideomost **/
export const GET_VM_CONF_CONNECTION_PARAMS = 'getVmConfConnectionParams'
export const GET_VM_SERVER_MANAGEMENT_HOST = 'getVmServerManagementHost'

/** ConfRoschat **/
export const IS_ROSCHAT_CONF_ENABLED = 'isRoschatConfEnabled'

/** Electron **/
export const GET_ELECTRON_MODULE_STATE = 'getElectronModuleState'

/** Intergrations **/
export const GET_IS_MSEXCHANGE_AUTH = 'getIsMsExchangeAuth'